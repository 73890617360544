export const alio_proposal = (params: {
  receiver: string;
  title: string;
  name: string;
}) => {
  return {
    tpl_code: "TV_6508",
    sender: "01094978563",
    receiver_1: params.receiver,
    subject_1: "고객 제안서 도착 안내",
    message_1: `제안명 : ${params.title}\n\n${params.name}님, 보장성 보험을 분석한 컨설팅 제안 결과가 메일로 도착했습니다.\n\n해당 보험 제안 도착 메시지는 고객님의 알림 신청(블루버튼)에 의해 발송됩니다.`,
  };
};
