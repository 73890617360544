import { useQueryClient } from "@tanstack/react-query";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { FlexBox } from "theme/globalStyle";
import { convertUnixDate, getAfterYearDate, getDate } from "utils/func";
import { SallerList } from "./SallerList";
import { useLocationStore } from "store/useLocationStore";
import { GetPaymentListResponseData } from "api/api.payment";
import { GetSallerListResponse } from "api/api.salesmanager";

interface SallerLocationListProps {
  selCustomerIndex: number;
  sallerData?: GetSallerListResponse;
}

export const SallerLocationList: React.FC<SallerLocationListProps> = ({
  selCustomerIndex,
  sallerData,
}) => {
  //지역 리스트 데이터(블루버튼)
  const { locationList } = useLocationStore();

  const [paymentList, setPaymentList] = React.useState<
    GetPaymentListResponseData[]
  >([]);

  const [selPaymentIdx, setSelPaymentIdx] = React.useState(0);

  React.useEffect(() => {
    if (sallerData) {
      if (sallerData.status === 200) {
        const selPaymentInfo: GetPaymentListResponseData[] =
          sallerData.data.filter(
            (el) => el.salesmanager_id === selCustomerIndex
          )[0].payment;

        setPaymentList([...selPaymentInfo]);

        if (selPaymentInfo.length > 0) {
          setSelPaymentIdx(selPaymentInfo[0].payment_id || 0);
        }
      }
    }
  }, [sallerData, selCustomerIndex]);

  return (
    <FlexBox $isFullWidth $flexDirection="row" $ai={"stretch"} height={"100%"}>
      <FlexBox
        height={"100%"}
        $maxWidth={30}
        $minWidth={20}
        $isFullWidth
        $brw={1}
        $brc={colors.DEFAULT_BORDER_COLOR}
      >
        {locationList.length > 0 &&
          paymentList.map((item, index) => {
            const regionInfo = locationList.filter(
              (el) => el.regionId === item.sub_product_information.local_id
            )[0];

            const regionName = regionInfo.regionName;

            return (
              <FlexBox
                $isFullWidth
                $flexDirection="row"
                $ai={"center"}
                $jc={"space-between"}
                $py={1.2}
                $px={2}
                key={"selPaymentList" + index}
                $isPointer
                onClick={() => {
                  setSelPaymentIdx(item.payment_id || 0);
                }}
                $bgcolor={
                  item.payment_id === selPaymentIdx
                    ? colors.ADMIN_CUSTOMER_SEL_BG
                    : ""
                }
                $bw={1}
                $bc={
                  item.payment_id === selPaymentIdx
                    ? colors.ADMIN_CUSTOMER_SEL_BORDER
                    : colors.ADMIN_SEARCH_BG
                }
              >
                <FlexBox>
                  <Typograpy
                    fontStyle={fontStyle.label1_normal}
                    fontWeight="Bold"
                  >
                    {regionName}
                  </Typograpy>
                  {item.payment_information && (
                    <Typograpy fontStyle={fontStyle.caption1}>
                      기간 :{" "}
                      {
                        getDate(
                          new Date(item.payment_information.requestedAt || "")
                        ).comText
                      }{" "}
                      ~{" "}
                      {
                        getDate(
                          getAfterYearDate(
                            new Date(item.payment_information.requestedAt || "")
                          )
                        ).comText
                      }
                    </Typograpy>
                  )}
                </FlexBox>
                <FlexBox
                  $ai={"center"}
                  $jc={"center"}
                  $py={0.4}
                  width={5.5}
                  $bgcolor={
                    item.sub_product_information.product_type === "insurances"
                      ? colors.SUBSCRIBE_INSURANCE
                      : item.sub_product_information.product_type === "finance"
                      ? colors.SUBSCRIBE_FINACE
                      : colors.SUBSCRIBE_PREMIUM
                  }
                  $br={100}
                >
                  <Typograpy
                    fontStyle={fontStyle.caption2}
                    color={colors.WHITE}
                  >
                    {item.sub_product_information.product_type === "insurances"
                      ? "보험"
                      : item.sub_product_information.product_type === "finance"
                      ? "금융"
                      : "프리미엄"}
                  </Typograpy>
                </FlexBox>
              </FlexBox>
            );
          })}
      </FlexBox>
      {selPaymentIdx && (
        <SallerList
          regionId={
            !selPaymentIdx
              ? 0
              : paymentList.filter((el) => el.payment_id === selPaymentIdx)[0]
                  .sub_product_information.local_id
          }
        />
      )}
    </FlexBox>
  );
};
