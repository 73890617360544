import { PrimaryTab } from "components/molecules/tab/PrimaryTab";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
// import { LocationSetting } from "./LocationSetting";
import { SubscribeSetting } from "./SubscribeSetting";

const AdminEnvTemplate = () => {
  //Tab 번호
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <FlexBox $flex={1} height={"100%"} $isFullWidth>
      <Box $px={2} $pt={2}>
        <Typograpy fontStyle={fontStyle.headline1} fontWeight="Bold">
          BlueSales의 데이터리스트들을 관리할 수 있습니다.
        </Typograpy>
      </Box>
      <FlexBox $isFullWidth>
        <Box
          $isFullWidth
          $pt={2}
          $px={2}
          $bbw={1}
          $bbc={colors.DEFAULT_BORDER_COLOR}
        >
          <PrimaryTab
            activeTab={tabIndex}
            items={["구독상품"]}
            onTabChange={setTabIndex}
          />
        </Box>
        <SubscribeSetting />
        {/* {tabIndex === 0 ? <LocationSetting /> : <SubscribeSetting />}/ */}
      </FlexBox>
    </FlexBox>
  );
};

export default AdminEnvTemplate;
