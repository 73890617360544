import { IcError } from "components/atoms/icon/icon";
import {
  Input,
  InputWrapper,
  TextLeftIcon,
  TextRightIcon,
} from "components/atoms/input/input";
import React, { ReactNode } from "react";
import { Box, FlexBox } from "theme/globalStyle";
import Typograpy from "../Typograpy";
import { CSSProperties } from "styled-components";
import { Label } from "../label/Label";
import { fontStyle } from "theme/fontStyle";
import { colors } from "theme/colors";

interface PrimaryInputPropsType {
  value: string;
  onChangeValue: (value: string, key?: string) => void;
  onEnterEvent?: () => void;
  stateKey?: string;
  $isError?: boolean;
  errorMsg?: string;
  placeholder: string;
  readonly?: boolean;

  isRound?: boolean;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  iconAction?: () => void;

  label?: string;
  subLabel?: string;
  labelColor?: string;
  labelStyle?: string;
  isRequire?: boolean;
  inputType?: React.HTMLInputTypeAttribute;
  inputStyle?: CSSProperties;

  rightButton?: ReactNode;
  wrapperStyle?: CSSProperties;

  isCenterInput?: boolean;
  maxInput?: number;
  max?: string | number;
  min?: string | number;
  capsLockCheck?: (capslock: boolean) => void;
}

export const PrimaryInput: React.FC<PrimaryInputPropsType> = ({
  value,
  onChangeValue,
  onEnterEvent,
  stateKey,
  $isError,
  errorMsg,
  placeholder,
  readonly,
  isRound,
  leftIcon,
  rightIcon,
  iconAction,
  label,
  subLabel,
  labelColor,
  labelStyle,
  isRequire,
  inputType,
  inputStyle,

  wrapperStyle,
  rightButton,
  isCenterInput,
  maxInput,
  max,
  min,
  capsLockCheck,
}) => {
  return (
    <FlexBox $isFullWidth>
      {label && (
        <Label
          label={label}
          isRequire={isRequire}
          labelColor={labelColor}
          labelStyle={labelStyle}
          subLabel={subLabel}
        />
      )}
      <FlexBox $flexDirection="row" $ai="stretch" $gap={1.2} $isFullWidth>
        <FlexBox $isFullWidth $flexDirection="row" $ai={"center"} $gap={1.2}>
          <InputWrapper style={{ width: "100%", flex: 8, ...wrapperStyle }}>
            {leftIcon && <TextLeftIcon>{leftIcon}</TextLeftIcon>}
            <Input
              value={value}
              onChange={({ target }) => {
                if (stateKey) {
                  onChangeValue(target.value, stateKey);
                } else {
                  onChangeValue(target.value);
                }
              }}
              max={max}
              min={min}
              $isRound={isRound}
              $isError={$isError}
              placeholder={placeholder}
              readOnly={readonly}
              $isLeftIcon={leftIcon}
              $isRightIcon={rightIcon}
              type={inputType || "text"}
              maxLength={maxInput}
              style={{
                textAlign: isCenterInput ? "center" : "left",
                ...inputStyle,
              }}
              onKeyPress={(e) => {
                if (capsLockCheck) {
                  if (e.getModifierState("CapsLock")) {
                    capsLockCheck(true);
                  } else {
                    capsLockCheck(false);
                  }
                }
                if (e.key === "Enter") {
                  if (onEnterEvent) onEnterEvent();
                }
              }}
            />
            <TextRightIcon>
              {$isError && (
                <Box $mr={0.4}>
                  <IcError />
                </Box>
              )}
              <FlexBox
                $isPointer={iconAction ? true : false}
                onClick={() => {
                  iconAction && iconAction();
                }}
                $ai="center"
              >
                {rightIcon}
              </FlexBox>
            </TextRightIcon>
            {/* {rightIcon && <TextRightIcon>{rightIcon}</TextRightIcon>} */}
          </InputWrapper>
        </FlexBox>
        {rightButton && <FlexBox $flex={2.5}>{rightButton}</FlexBox>}
      </FlexBox>
      {$isError && (
        <Box $mt={0.4}>
          <Typograpy fontStyle={fontStyle.caption1} color={colors.RED}>
            {errorMsg}
          </Typograpy>
        </Box>
      )}
    </FlexBox>
  );
};
