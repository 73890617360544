import { GetPaymentListResponseData } from "api/api.payment";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { useLocationStore } from "store/useLocationStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import {
  dateConverter,
  getAfterYearDate,
  getDate,
  NumberComma,
} from "utils/func";

type TotalInfoType = {
  price: number;
  discount: number;
  totalPrice: number;
};

interface PaymentResultTableProps {
  newPaymentInfo: GetPaymentListResponseData[];
}

export const PaymentResultTable: React.FC<PaymentResultTableProps> = ({
  newPaymentInfo,
}) => {
  const { locationList } = useLocationStore((state) => state);

  const [totalInfo, setTotalInfo] = React.useState<TotalInfoType>({
    price: 0,
    discount: 0,
    totalPrice: 0,
  });

  React.useEffect(() => {
    if (newPaymentInfo.length > 0) {
      let tempTotalInfo: TotalInfoType = {
        price: 0,
        discount: 0,
        totalPrice: 0,
      };

      newPaymentInfo.forEach((item, index) => {
        if (item.sub_product_information.sub_product_id) {
          const productInformation = item.sub_product_information; //선택한 상품정보
          const couponInformation = item.coupon_information; //선택한 쿠폰정보

          const discountPrice =
            productInformation.sub_product_price *
            (couponInformation.discount_rate / 100); //할인금액
          tempTotalInfo = {
            price: tempTotalInfo.price + productInformation.sub_product_price,
            discount: tempTotalInfo.discount + discountPrice,
            totalPrice:
              tempTotalInfo.totalPrice +
              (productInformation.sub_product_price - discountPrice),
          };
        }
        setTotalInfo({ ...tempTotalInfo });
      });
    }
  }, [newPaymentInfo]);

  return (
    <FlexBox $isFullWidth $mt={2}>
      <FlexBox
        $isFullWidth
        $btw={2}
        $btc={colors.BLACK}
        $bbw={2}
        $bbc={colors.MAIN}
      >
        <FlexBox
          $bbw={2}
          $bbc={colors.BLACK}
          $flexDirection="row"
          $jc={"space-between"}
          $py={1.6}
          $isFullWidth
        >
          <FlexBox $flex={1} $ai={"center"}>
            <Typograpy fontStyle={fontStyle.body1_normal} fontWeight="Bold">
              구독기간
            </Typograpy>
          </FlexBox>
          <FlexBox $flex={3} $ai={"center"}>
            <Typograpy fontStyle={fontStyle.body1_normal} fontWeight="Bold">
              {getDate().hiponText} ~ {getDate(getAfterYearDate()).hiponText}
            </Typograpy>
          </FlexBox>
        </FlexBox>
        <FlexBox
          $bbw={1}
          $bbc={colors.DEFAULT_BORDER_COLOR}
          $flexDirection="row"
          $jc={"space-between"}
          $py={1.6}
          $isFullWidth
        >
          <FlexBox $flex={1} $ai={"center"}>
            <Typograpy fontStyle={fontStyle.body1_normal} fontWeight="Bold">
              선택상품
            </Typograpy>
          </FlexBox>
          <FlexBox $flex={1} $ai={"center"}>
            <Typograpy fontStyle={fontStyle.body1_normal} fontWeight="Bold">
              가격
            </Typograpy>
          </FlexBox>
          <FlexBox $flex={1} $ai={"center"}>
            <Typograpy fontStyle={fontStyle.body1_normal} fontWeight="Bold">
              할인금액
            </Typograpy>
          </FlexBox>
          <FlexBox $flex={1} $ai={"center"}>
            <Typograpy fontStyle={fontStyle.body1_normal} fontWeight="Bold">
              총 결제금액
            </Typograpy>
          </FlexBox>
        </FlexBox>

        {newPaymentInfo.map((paymentItem, index) => {
          if (paymentItem.sub_product_information.sub_product_id !== 0) {
            const productInformation = paymentItem.sub_product_information; //선택한 상품정보
            const couponInformation = paymentItem.coupon_information; //선택한 쿠폰정보

            const regionInfo = locationList.find(
              (el) => el.regionId === productInformation.local_id
            ); //선택한 지역정보

            const discountPrice =
              productInformation.sub_product_price *
              (couponInformation.discount_rate / 100); //할인금액

            return (
              <FlexBox
                $bbw={1}
                $bbc={colors.DEFAULT_BORDER_COLOR}
                $flexDirection="row"
                $jc={"space-between"}
                $py={1.6}
                $isFullWidth
                key={"paymentInfo" + index}
              >
                <FlexBox $flex={1} $ai={"center"}>
                  <Typograpy fontStyle={fontStyle.body1_normal}>
                    {regionInfo?.regionName}
                    {"-"}
                    {productInformation.sub_product_name}
                  </Typograpy>
                </FlexBox>
                <FlexBox $flex={1} $ai={"center"}>
                  <Typograpy fontStyle={fontStyle.body1_normal}>
                    {NumberComma(productInformation.sub_product_price) + "원"}
                  </Typograpy>
                </FlexBox>
                <FlexBox $flex={1} $ai={"center"}>
                  {couponInformation.discount_rate ? (
                    <Typograpy fontStyle={fontStyle.body1_normal}>
                      {`${couponInformation.discount_rate}%`}{" "}
                      {`(${NumberComma(discountPrice)}원)`}
                    </Typograpy>
                  ) : (
                    <></>
                  )}
                </FlexBox>
                <FlexBox $flex={1} $ai={"center"}>
                  <Typograpy fontStyle={fontStyle.body1_normal}>
                    {`${NumberComma(paymentItem.final_price)}원`}
                  </Typograpy>
                </FlexBox>
              </FlexBox>
            );
          }
        })}
      </FlexBox>
      <FlexBox
        $flexDirection="row"
        $jc={"space-between"}
        $py={1.6}
        $isFullWidth
      >
        <FlexBox $flex={1} $ai={"center"} />
        <FlexBox $flex={1} $ai={"center"}>
          <Typograpy fontStyle={fontStyle.body1_normal} fontWeight="Bold">
            {NumberComma(totalInfo.price) + "원"}
          </Typograpy>
        </FlexBox>
        <FlexBox $flex={1} $ai={"center"}>
          <Typograpy fontStyle={fontStyle.body1_normal} fontWeight="Bold">
            {NumberComma(totalInfo.discount) + "원"}
          </Typograpy>
        </FlexBox>
        <FlexBox $flex={1} $ai={"center"}>
          <Typograpy fontStyle={fontStyle.body1_normal} fontWeight="Bold">
            {`${NumberComma(totalInfo.totalPrice)}원`}
          </Typograpy>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
