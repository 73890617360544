import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { baseAxios } from "./baseAxios";

export interface FindSalesmanagerIdParams {
  salesmanager_name: string;
  email_address: string;
}

interface VerifyPasswordParams {
  salesmanager_id: number;
  salesmanager_password: string;
}

export interface FindSalesmanagerIdData {
  salesmanager_id: number;
  salesmanager_account: string;
}

export interface FindSalesmanagerIdResponse {
  status: number;
  message: string;
  data: FindSalesmanagerIdData;
}

//영업사원 생성
export const useFindSalesId = () =>
  useMutation<FindSalesmanagerIdResponse, AxiosError, FindSalesmanagerIdParams>(
    {
      mutationKey: ["findByNameAndEmail"],
      mutationFn: (params) =>
        baseAxios
          .post("/salesmanager/findByNameAndEmail", params)
          .then((res) => res.data)
          .catch((err) => console.log(err)),
    }
  );

export const verifyPassword = ({
  salesmanager_id,
  salesmanager_password,
}: VerifyPasswordParams) => {
  return baseAxios
    .post("/salesmanager/verifyPassword", {
      salesmanager_id,
      salesmanager_password,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
