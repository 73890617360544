import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { PageType } from "api/commonType";
import axios, { AxiosError, AxiosResponse } from "axios";
import { base64Encode } from "utils/func";

export interface GetProductTypeParams {
  id: number; //선택한 회원 id
}

export interface GetInsurancesResponseType {
  insuranceName: string; //보험 이름
  companyName: string; //보험회사 이름
  paymentAmount: number; //총납입금액
}

export interface GetUsersResponse {
  status: number; //응답
  message: string; //메세지
  data: {
    success: boolean;
    response: GetInsurancesResponseType[];
    error: null | string;
    page: PageType;
  };
}

// 영업자 - 고객정보 - 상품정보 (보험,금융)
export const useGetInsurancesInfo = (params: GetProductTypeParams) =>
  useQuery<GetUsersResponse, AxiosError>({
    queryKey: ["getInsuranceInfo"],
    queryFn: () => {
      return baseAxios
        .get(
          `/blue-button-api/fetch/year-end-settlement/protection-insurances?filter=${JSON.stringify(
            params
          )}`
        )
        .then((res) => res.data)
        .catch((err) => console.log(err));
    },
  } as UseQueryOptions<GetUsersResponse, AxiosError>);
