import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { fontStyle } from "theme/fontStyle";
import { FlexBox } from "theme/globalStyle";

export const ServiceAgree = () => {
  return (
    <FlexBox $gap={1.2} $mb={4}>
      <Typograpy fontStyle={fontStyle.title3} fontWeight="Bold">
        서비스 이용약관
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body1_reading} fontWeight="Bold">
        제1장 총칙
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제1조 (목적)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`본 약관은 주식회사 블루라이언스 (이하 “회사”라 합니다)가 제공하는 블루세일 서비스(이하 “서비스”라 합니다)의 이용과 관련하여 회사와 회원 간의 권리와 의무 및 책임사항, 서비스 이용조건 및 절차 등 기본적 사항을 규정함을 목적으로 합니다. 회사는 서비스에 대한 제반 사항 및 운영에 대한 모든 권한을 가지고 운영합니다. 
 `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제2조 (용어의 정의)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`본 약관에서 사용하는 정의는 다음과 같습니다.
(1) “회원”이라 함은 본 약관에 따라 가입 신청하여 회사의 승낙을 받아 본 약관에 따른 서비스를 이용하는 자를 말합니다.
(2) “비실명 고객 정보 서비스”라 함은 블루버튼 앱, 통해 가입한 가입자의 정보를 통치하며, 블루버튼 서비스를 가입한 회원의 동의하에 수집하거나 직접 입력한 정보를 확인할 수 있게 하는 서비스, 회원의 보험정보와 보험상품, 보험납입금액, 금융상품 및 금융상품별 사용금액, 혹은 납입금액등의 정보를 분석하여 컨설팅을 제공하는 서비스, 맞춤형 보험상품 및 금융상품을 추천하는 서비스, 금융상품 및 보험관련 각종 컨텐츠 제공 서비스 등으로 구성됩니다.
(3) “서비스”라 함은 회사의 웹, 앱 서비스 통해 가입한 회원에게 제공하는 서비스를 통칭하며, 서비스를 가입한 회원의 동의 하에 수집하거나 직접 입력한 정보를 확인할 수 있게 하는 서비스, 회원의 보험정보와 보험상품, 보험납입금액, 금융상품 및 금융상품별 사용금액, 혹은 납입금액등의 정보를 분석하여 컨설팅을 제공하는 서비스, 맞춤형 보험상품 및 금융상품을 추천하는 서비스, 금융상품 및 보험관련 각종 컨텐츠 제공 서비스 등으로 구성됩니다.
(4) “개인정보”라 함은 살아 있는 개인에 관한 정보로서 개인을 알아볼 수 있는 정보(해당 정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 것을 포함합니다)를 말합니다. 
(5) “비실명 고객의 개인정보”라 함은 살아 있는 개인에 관한 정보로서 개인을 알아볼 수 없는 정보를 말합니다. 
(6) “신용정보”라 함은 신용정보의 이용 및 보호에 관한 법률 제2에서 정의하는 “회원”의 신용에 관한 정보 일체를 의미하며, 경우에 따라 개별 보험회사 등이 보유하고 있는 “회원”의 “보험정보” 등을 포함하며, 개별 금융회사 등이 보유하고 있는 “회원”의 “금융상품정보”, “금융상품 이용금액 혹은 납부 금액” 등을 포함합니다. 
(7) “보험회사”라 함은 보험업법에 따른 허가를 받아 보험업을 경영하는 법인을 말합니다.
(8) “보험상품”이라 함 보험업법 제2조에 정의된 의미를 가집니다. 
(9) “보험정보”라 함은 블루버튼 회원이 가입한 “보험상품”에 관한 상세 내역(보험회사, 보험료, 보장 내역, 보험 기간 등 “보험상품”에 관한 상세 정보 일체)을 의미합니다.
(10) “제휴서비스”라 함은 회사가 제공하는 서비스에서 회사와 제휴 관계에 있는 보험회사 등이 운영하는 서비스를 의미합니다. 

이 약관에서 사용하는 용어의 정의는 제1항 각호에서 정하는 것을 제외하고는 관련법령 및 기타 일반적인 상관례에 의합니다.
`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제3조 (이용약관의 효력 및 약관변경 승인)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사는 회원이 서비스를 이용하기 전에 이 약관을 게시하고, 회원이 이 약관의 중요한 내용을 확인할 수 있도록 합니다.
(2) 회사는 회원의 요청이 있는 경우 전자문서 전송(전자우편을 이용한 전송을 포함합니다), 모사전송, 우편 또는 직접교부의 방식으로 본 약관의 사본을 회원에게 교부합니다.
(3) 회사는 회원이 약관의 내용에 대한 설명을 요청하는 경우 다음 각 호의 어느 하나의 방법으로 회원에게 약관의 중요내용을 설명합니다. 
- 약관의 중요내용을 회원에게 직접 설명
- 약관의 중요내용에 대한 설명을 모바일 디바이스를 통하여 회원이 알기 쉽게 표시하고 회원으로부터 해당 내용을 충분히 인지하였다는 의사표시를 모바일 디바이스를 통하여 수령
(4) 회사는 약관을 변경하는 때에는 그 시행일 1월 전에 변경되는 약관을 회사의 웹사이트 또는 블루세일 서비스 약관 페이지에 게시하고 회원에게 통지합니다. 다만, 법령의 개정으로 인하여 긴급하게 약관을 변경한 때에는 변경된 약관을 회사의 웹사이트 또는 블루세일 서비스 약관 페이지에 최소 1월 이상 게시하고 회원에게 통지합니다.
(5) 회사는 제4항의 고지나 통지를 할 경우 "회원이 변경에 동의하지 아니한 경우 고지나 통지를 받은 날로부터 30일 이내에 계약을 해지할 수 있으며, 계약해지의 의사표시를 하지 아니한 경우에는 변경에 동의한 것으로 본다."라는 취지의 내용을 고지하거나 통지합니다.
(6) 회원은 약관의 변경내용이 게시되거나 통지된 후부터 변경되는 약관의 시행일 전의 영업일까지 회사와 서비스 계약을 해지할 수 있습니다. 이 기간 안에 회원이 약관의 변경내용에 대하여 이의를 제기하지 아니하는 경우에는 약관의 변경을 승인한 것으로 봅니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body1_reading} fontWeight="Bold">
        제2장 서비스 이용계약의 체결
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제4조 (이용신청 및 방법)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사가 제공하는 서비스에 관한 이용계약(이하 “이용계약”이라고 합니다)은 해당 서비스를 이용하고자 하는 자가 블루세일 웹사이트를 통해 회사가 정한 양식에 따라 회원가입신청을 하고, 본 “약관”과 “개인정보처리방침”, “개인정보 수집∙이용 동의서”의 내용에 동의를 하면 이에 대하여 회사가 승낙함으로써 성립합니다.
(2) 본 “약관”, “개인정보 처리방침”, “개인 정보 수집∙이용 동의서”에 대한 동의의 의사표시는 "가입신청자"가 회원가입 신청 당시 “개인 정보 수집ᆞ이용 동의서”, "서비스 이용약관" 및 “개인정보 처리방침”에 동의 버튼을 누르는 것으로 이루어집니다. 
(3) 회사는 가입 신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 단, 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 승낙 이후라도 이용의 제한 또는 이용계약의 해지 등의 조치를 취할 수 있습니다.
① 회사가 정한 서비스 제공환경이 아니거나 기술상 서비스 제공이 불가능한 경우
② 가입 신청자가 신청시 제공한 정보에 허위, 누락이나 오류가 있거나, 회사가 요구하는 기준을 충족하지 못하는 경우
③ 신청 명의가 가입 신청자 본인의 명의가 아닌 경우
④ 이미 가입된 회원이 중복하여 가입신청을 하는 경우
⑤ 만 14세 미만의 자가 이용신청 하는 경우
⑥ 법인 명의로 가입하는 경우
⑦ 기타 가입 신청자의 귀책사유로 인하여 승낙이 불가능하거나 해당 가입신청이 법령, 본 약관 및 기타 회사가 정한 제반 사항에 배치되는 경우
⑧ 회사가 정한 회원의 자격유무(보험설계사의 경우 해당 자격증 소유자 및 재무설계사의 경우 해당자격증 소지자)를 검증하고 검증된 회원만 가입을 진행할 수 있으며, 자격이 없는 경우 회원의 이용이 거절됩니다.
(4) 회원가입은 만 14세 이상의 개인이 할 수 있습니다. 만 14세 미만의 개인이 서비스를 이용하는 경우 회사는 이용계약을 취소하거나 해제할 수 있습니다.
(5) 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
(6) 회사가 제3항, 제4항 및 제5항에 따라 회원가입 신청을 승낙하지 아니하거나 유보한 경우, 회사는 원칙적으로 그 사실을 가입 신청자에게 알리도록 합니다.
(7) 서비스에서 제공하는 개별 서비스는 개인정보 제3자 제공동의 후 제휴 보험회사 등 제공자의 이용약관 및 운영정책에 동의하여야만 이용할 수 있습니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제5조 (서비스의 이용개시, 이용시간 및 수수료)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 제4조에 따라 회사가 신청인의 이용신청을 승낙함과 동시에 신청인은 회사가 제공하는 서비스의 회원이 되며, 이때부터 회사는 당해 회원에게 당해 약관이 정하는 서비스를 제공합니다.
(2) 회사는 본 약관이 정한 서비스 외에 추가적인 서비스를 제공하기 위해 회원에게 별도의 추가적인 약관 동의, 정보 수집 및 이용 동의 등 절차의 이행을 요청할 수 있으며, 이러한 절차가 완료되지 않는 경우 해당 회원이 추가적인 서비스의 전부 또는 일부를 이용하지 못할 수 있습니다. 
(3) 서비스의 이용은 회사의 업무상 또는 기술상의 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다. 다만, 서비스 운영상의 필요성으로 회사가 정한 기간 동안 본 서비스가 일시 중지될 수 있으며, 회사와 연계하여 서비스를 제공하는 금융기관 등의 사유로 서비스 중 일부 또는 전부가 중지될 수 있습니다. 서비스 중지가 발생하는 경우 그 내용을 홈페이지를 통하여 공지합니다.
(4) 회사는 회원의 서비스 이용에 따른 수수료를 부과할 수 있으며, 수수료는 홈페이지를 통하여 고지합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제6조 (이용계약의 종료)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 본 “약관”과 “개인정보처리방침”, “개인정보 수집∙이용 동의서”의 동의를 철회함에 따라 이용계약을 해지할 수 있습니다. 회사는 관련법령 등에서 정하는 바에 따라 이를 즉시 처리하여야 합니다.
(2) 회사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 임의로 이용계약을 해지하거나 해당 회원의 서비스의 이용을 제한할 수 있습니다.
① 회원이 제4조 제3항에서 정하고 있는 사항에 해당됨을 확인한 경우
② 서비스 운영을 고의로 방해한 경우
③ 공공질서 및 미풍양속에 저해되는 내용을 유포시킨 경우
④ 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
⑤ 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 등 운영자의 합리적이고 객관적인 판단에 의하여 회원의 자격이 없다고 인정되는 경우
⑥ 컴퓨터 바이러스 프로그램 등을 유포하는 경우
⑦ 회사의 지적재산권을 침해하는 행위
⑧ 기타 본 약관이나 관련법규에 위배된다고 판단되는 경우
- 이용계약을 해지할 경우, 관련법령 및 회사의 개인정보보호방침에서 정한 바에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 후 즉시 회원의 모든 데이터는 지체없이 파기합니다. 다만, 회원이 임의 해지, 재가입 등을 반복적으로 행함으로써 회사가 제공하는 혜택이나 경제적 이익을 비통상적인 방법으로 악용할 우려가 있다고 회사가 판단하는 경우, 회사는 재가입을 제한할 수 있습니다. 이러한 심사 목적 등을 위하여 회사는 회원 탈퇴 시 관계법령이 정한 기간동안 동안 해당 회원의 성명, 탈퇴일자, 탈퇴사유 등 필요한 정보를 보관할 수 있습니다
- 회원은 회사의 이용제한 조치 등에 대하여 이의신청을 할 수 있습니다. 회원의 이의 사유가 정당하다고 인정되는 경우 회사는 즉시 회원의 서비스 이용을 재개하여야 합니다.
- 이 약관에 따라 이용계약이 해지된 경우 회사는 회원에게 부가적으로 제공한 각종 혜택을 무효화하거나 회수할 수 있습니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body1_reading} fontWeight="Bold">
        제4장 블루세일 서비스
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제7조 (서비스의 제공)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사는 다음과 같은 서비스를 제공합니다.
① “고객정보서비스”의 회원에 대한 금융상품, 보험상품에 대한 상담, 제안, 상품 안내 서비스
② 회원의 가입 구분 및 지역에 따라 정해진 상품에 대한 서비스만 제공
(2) 회사는 업무 수행상 필요하다고 판단되는 경우 전항의 서비스 내용을 추가하거나 변경할 수 있으며, 이 경우 추가 또는 변경한 서비스의 내용을 미리 웹 사이트에 게시하거나 회원에게 개별 통지합니다.
(3) 회사는 제공되는 서비스의 일부 또는 전부를 회사의 정책 기획이나 운영상 또는 회사의 긴박한 상황 등 필요에 의해 수정, 중단, 변경할 수 있으며, 이에 대하여 관련 법령상 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
(4) 회사는 유료서비스의 변경내용이 중대하거나 회원에게 불리한 경우 해당 유료서비스를 제공받는 회원에게 제3조에서 정한 방법으로 회원에게 통지합니다. 이 경우, 동의를 거절한 회원에 대해서는 변경 전 서비스를 제공합니다. 다만, 변경 전 서비스의 제공이 불가능한 경우 회사는 해당 서비스의 제공을 중지하거나 중단할 수 있으며, 관련 규정에 따라 환불을 진행합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제8조 (서비스의 이용 및 민·형사상의 책임, 손해배상 책임)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회원은 자신이 이용하고자 하는 서비스를 자율적으로 선택하여 이용합니다. 
(2) 회원과 가입시 정한 1개의 ID를 사용합니다.
(3) 회원은 서비스에서 제공되는 블루버튼 회원의 “비 실명 고객정보서비스”를 타인 또는 단체등과 공유는 금지 합니다. 만약, “비 실명 고객정보서비스”의 공유금지 원칙을 어길시 회원은 민·형사상의 책임 및 손해배상의 책임을 갖게 됩니다. 
(4) 회원이 고객과 체결한 보험 서비스에 대하여 회사는 책임을 부담하지 아니합니다. 
(5) 서비스의 해지 및 환불 등은 관련 규정에 의해 처리됩니다.
(6) 회사는 서비스의 변경 등이 발생한 경우 그 변경내용 및 적용시점을 제14조에 따라 통지합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body1_reading} fontWeight="Bold">
        제5장 회사와 회원의 권리 의무
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제9조 (회사의 권리 의무)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사는 이 약관이 정하는 바에 따라 서비스를 제공하기 위해 최선을 다합니다. 
회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 서비스 화면에 게시하거나 SMS, 이메일 등을 통하여 동 회원에게 통지합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제10조 (회원의 권리 의무)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회원은 이 약관 및 개별약관, 특별약관 등이 정하는 바에 따라 서비스를 이용하며, 유료 서비스 이용 시 부과된 요금을 납부할 의무가 있습니다. 
(2) 회원은 서비스를 이용함에 있어 다음 각 호의 행위를 하여서는 안됩니다. 
① 본 약관 및 서비스 이용에 관련된 관계법령을 위반한 행위
② 서비스를 제공받기 위해 이용 신청 또는 변경 신청 시 허위 사실을 기재하거나 타인의 정보를 도용하는 등 정상적인 서비스 운영을 고의로 방해하는 일체의 행위
③ 여하한 방법으로 회사가 정상적으로 제공하는 방법이 아닌 다른 방법으로 회사가 보유하고 있는 정보를 탈취, 저장, 공개 또는 부정한 목적으로 사용하는 행위
④ 회사의 지식재산권, 제3자의 지식재산권 등 기타 권리를 침해하거나, 회사의 동의 없이 회원 또는 제3자의 상업적인 목적을 위하여 본 서비스 구성요소의 전부 또는 일부의 내용에 관하여 이를 촬영, 복사, 복제, 판매, 재판매 또는 양수도하는 행위
⑤ 기타 범죄 또는 법령이 금지하는 행위와 관련되었다고 의심받을 수 있는 일체의 행위
⑥ 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
⑦ 회사의 서비스를 해킹하거나 해킹에 이용하는 일체의 행위
⑧ 기타 불법적이거나 부당한 행위
- 회원은 관련 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.
- 회원은 반드시 본인이 직접 서비스를 이용해야 하며, 본인이 이용하는 서비스 이용 아이디나 모바일 디바이스, 컴퓨터등을 타인에게 양도 또는 대여하는 등의 방법으로 타인으로 하여금 서비스를 이용할 수 있도록 하여서는 안됩니다. 이의 위반으로 인한 책임은 회원에게 있습니다.
- 회원은 제8조의 회원이 이용하는 전용 아이디의 공유 및 로그인한 화면의 공유, “비 실명 고객정보서비스”의 고객의 개인정보 및 비식별 정보등의 공유, 화면공유, 촬영, 화면 캡춰등의 “비 실명 고객정보서비스”를 보관 하는 행위 및 공유 행위를 엄격하게 금지 합니다. 이의 위한으로 인한 책임 및 민·형사상의 책임, 손해배상책임등이 회원에게 있습니다.  `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body1_reading} fontWeight="Bold">
        제3장 회원정보의 보호 등
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제11조 (개인정보의 보호 및 이용)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사는 개인정보의 보호 및 처리와 관련하여 개인정보보호법, 신용정보의이용및보호에관한법률, 정보통신망이용촉진및정보보호에관한법률 및 각 그 하위 법령에 정하는 사항을 준수하며, 개인정보의 보호를 위하여 노력합니다.
(2) 회사는 개인 정보의 수집, 이용, 제공, 보호, 위탁 등에 관한 제반 사항의 구체적인 내용을 개인정보처리방침을 통하여 규정하며, 개인정보처리방침은 회사의 웹페이지등을 통하여 게시합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제12조 (위치정보의 보호 및 이용)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사는 서비스의 제공과 관련하여, 위치정보 서비스에 관한 약관 및 정보 제공 동의 등 필요한 요건을 구비한 회원으로부터 위치정보를 수집 및 이용할 수 있습니다.
(2) 회사는 회원의 위치 정보를 이용하는 경우, 위치정보의 보호 및 이용 등에 관한 법률(이하 위치정보법) 등 관련 법령이 정하는 바에 따라서 회원의 개인위치정보를 보호하기 위하여 노력합니다.
(3) 위치정보의 수집 및 이용에 관하여는 회사가 제시하는 별도의 약관에 정한 바에 따릅니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제13조 (손해배상)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사나 회원이 본 약관의 규정을 위반하거나 기타 불법행위를 하여 상대방에게 손해를 발생시킨 경우, 그 손해 발생에 귀책사유가 있는 당사자는 그 상대방의 피해를 배상하여야 합니다.
(2) 회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 그로 인하여 회사가 입은 손해를 배상하여야 합니다.
(3) 회사는 회원 상호간, 회원과 보험회사, 혹은 금융회사 상호간 또는 회원과 제3자 상호간에 발생한 분쟁에 대해서는 개입할 의무가 없으며, 회사에 귀책사유가 없는 한 이로 인한 손해를 배상할 책임이 없습니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제14조 (회원에 대한 공고 및 통지)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사는 이 약관에 따라 제공되는 서비스의 운영과 관련한 공지사항을 회사의 홈페이지(https://bluesale.kr) 또는 회사가 관리하는 홈페이지 화면에의 게시, 회원이 제공한 이메일, 회원이 제공한 휴대전화번호로의 문자 메세지를 보내는 방법을 통하여 통지할 수 있습니다.
(2) 전항에 따른 통지는, 관련 법령 또는 이 약관에서 특별히 전자문서가 아닌 서면 형태를 요구하는 경우가 아닌 한, 서면 통지로서의 효력을 갖습니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제15조 (광고성 또는 맞춤형 정보의 제공)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사는 회원에게 서비스 이용에 필요가 있다고 인정되거나 서비스 개선 및 회원대상의 서비스 소개 등의 목적으로 하는 각종 정보를 앱 알림, 이메일, SMS, MMS등으로 제공할 수 있습니다. 광고성 정보 및 회원 맞춤형 정보의 경우, 회원의 사전 수신 동의가 있는 경우 등 법령상 허용된 경우에 한하여 제공됩니다. 
(2) 회사는 제공하는 서비스와 관련되는 정보 또는 광고를 서비스 화면, 홈페이지 등에 게재할 수 있으며 회원은 서비스 이용 시 노출되는 광고게재에 대해 동의 하는 것으로 간주합니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제16조 (회사의 면책)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
(2) 회사는 회원이 다른 회원이 게재한 정보, 자료, 사실의 정확성 등을 신뢰함으로써 입은 손해에 대하여 책임을 지지 않습니다. 
(3) 회사는 서비스를 통해 제공된 어떠한 문서나 상담의 내용에 대해서도 책임을 지지 않으며 그 밖에 서비스를 이용함으로써 기대되는 수익을 상실한 것에 대하여 책임지지 않습니다. 그러므로 회원이 법률적 또는 의학적, 기타 재정적 사항에 관하여 필요한 결정을 할 때에는 반드시 사전에 전문가와 상의하여야 합니다. 
(4) 회사가 제공하는 서비스에 포함된 모든 정보, 소프트웨어 등의 부정확함이나 오류가 발생할 수 있으며 회사가 제공하는 서비스를 통해 제공되는 정보의 정확성에 대하여 책임을 지지 않습니다. 
(5) 회사는 서비스 화면에 표시되는 금융회사, 보험회사 또는 제3자가 제공하는 상품 또는 정보 등의 정확성, 적시성, 타당성을 보증하지 않습니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제17 조 (대리 및 보증의 부인)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회원과 제3자와의 거래에 있어서 회사는 회원이나 제3자를 대리하지 않습니다. 회사가 관련 서비스의 개별 이용약관 등을 통하여 달리 명시하지 아니하는 한, 회사의 어떠한 행위도 제3자나 회원을 대리하는 행위로 간주되지 않습니다.
(2) 회사가 서비스를 통하여 제3자의 서비스를 이용할 수 있도록 하는 경우, 해당 서비스 제공의 주체는 그 제3자이며, 회사는 그 제3자가 제공하는 정보를 회원에게 전달되도록 하는 도구를 제공합니다. 
(3) 회사는 서비스를 통하여 교환되는 회원과 제3자간의 정보와 관련하여 회원 또는 제3자의 거래의사의 존부 및 진정성, 제3자가 제공하는 상품의 품질, 완전성, 안전성, 적법성, 정보의 진실성, 타인의 권리에 대한 비침해성, 제3자와 회원이 제공하는 정보의 진실성 또는 적법성에 대하여 일체 보증하지 아니합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제18조 (지식재산권)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사의 서비스와 관련된 일체의 지식재산권은 회사에게 속합니다.
(2) 회원은 본 서비스 이용 과정에 얻은 정보를 가공, 제공, 판매하는 등 영리 목적으로 이용하거나 제3자에게 이용하게 할 수 없습니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제19조 (약관의 해석)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사와 회원 사이에 개별적으로 합의한 사항이 이 약관에 정한 사항과 다를 때에는, 그 합의사항을 이 약관에 우선하여 적용합니다.
(2) 회사가 제공하는 서비스에 따라 별도로 부가되는 약관은 본 약관과 통일적으로 해석되어야 하며, 서로 다른 내용이 있는 경우 원칙적으로 본 약관의 내용이 우선합니다. 다만, 개별 서비스 약관과 본 약관 중 해당 개별 서비스의 특성으로 인하여 서로 다른 내용이 있는 경우에는 그 범위에 한하여 해당 개별 서비스 약관의 조항이 우선하는 것으로 합니다.
(3) 본 약관의 내용 중 관련법령의 강행규정과 배치되는 부분이 있는 경우, 그 부분에 한하여 본 약관의 해당 규정은 무효로 합니다.
(4) 이 약관에 명시적으로 규정되지 않고, 당사자간에 미리 합의되지 않은 사항의 처리에 관하여는 관련법령 및 상관습에 따릅니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제20조 (분쟁의 해결)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 있는 경우 회사와 회원은 이를 해소하기 위하여 성실히 협의합니다.
(2) 전항에도 불구하고 회사와 회원의 분쟁이 해결되지 않는 경우, 해당 분쟁의 해결은 관련 법령에 정하는 절차 또는 민사소송법에 따라 관할을 가지는 법원의 판결에 따르기로 합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        부 칙
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`제1조 (시행일) 이 약관은 2024년 10월 15일부터 적용됩니다.`}
      </Typograpy>
    </FlexBox>
  );
};
