import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError, AxiosResponse } from "axios";

interface CreateNoticeParams {
  admin_id: number;
  notice_name: string;
  is_show: string;
  notice_content: string;
}

interface GetNoticeParams {
  notice_id: number;
}

interface EditNoticeProps {
  notice_id: number;
}

export interface GetNoticeListDataType {
  admin_id: number;
  notice_name: string;
  is_show: string;
  notice_content: string;
  notice_id: number;
  created_at: number;
  updated_at: number;
}

interface EditNoticeParams {
  admin_id: number;
  notice_name: string;
  is_show: string;
  notice_content: string;
}

//공지사항 생성 response
export interface CreateNoticeResponse {
  status: number; //응답
  message: string; //메세지
  data: GetNoticeListDataType;
}

interface CreateLocalProps {
  onSuccess: (data: CreateNoticeResponse) => void;
  onError: (error: AxiosError) => void;
}

//공지사항 리스트 response
export interface GetNoticeListResponse {
  status: number;
  message: string;
  data: GetNoticeListDataType[];
}

//공지사항 detail response
export interface GetNoticeResponse {
  status: number; //응답
  message: string; //메세지
  data: GetNoticeListDataType;
}

interface GetNoticeDetailProps {
  onSuccess: (data: GetNoticeResponse) => void;
  onError: (error: AxiosError) => void;
}

//공지사항 수정 response
export interface EditNoticeResponse {
  status: number; //응답
  message: string; //메세지
  data: EditNoticeParams;
}

//공지사항 생성
export const useCreateNotice = ({ onSuccess, onError }: CreateLocalProps) =>
  useMutation<CreateNoticeResponse, AxiosError, CreateNoticeParams>({
    mutationKey: ["createLocal"],
    mutationFn: (params: CreateNoticeParams) =>
      baseAxios.post("/notice", params).then((res) => res.data),
    onSuccess,
    onError,
  });

//공지사항 리스트
export const useGetNoticeList = () =>
  useQuery<GetNoticeListResponse, AxiosError>({
    queryKey: ["noticeList"],
    queryFn: () => baseAxios.get("/notice").then((res) => res.data),
  } as UseQueryOptions<GetNoticeListResponse, AxiosError>);

//공지사항 단일 조회
export const useGetNotice = ({ onSuccess, onError }: GetNoticeDetailProps) =>
  useMutation<GetNoticeResponse, AxiosError, GetNoticeParams>({
    mutationKey: ["notice"],
    mutationFn: (params: GetNoticeParams) =>
      baseAxios.get(`/notice/${params.notice_id}`).then((res) => res.data),
    onSuccess,
    onError,
  });

//공지사항 수정
export const useEditNotice = ({ notice_id }: EditNoticeProps) =>
  useMutation<AxiosResponse<EditNoticeResponse>, AxiosError, EditNoticeParams>({
    mutationKey: ["editNotice"],
    mutationFn: (params: EditNoticeParams) =>
      baseAxios.put(`/notice/${notice_id}`, { ...params }).then((res) => {
        return res.data;
      }),
  });

export const deleteNotice = (noticeId: number) =>
  baseAxios
    .delete(`/notice/${noticeId}`)
    .then((res) => res.data)
    .catch((err) => err);
