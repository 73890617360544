import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { PageType } from "api/commonType";
import axios, { AxiosError, AxiosResponse } from "axios";

interface GetPaymentListParams {
  salesmanager_id?: number;
}

interface SubProductInformationType {
  sub_product_id: number;
  local_id: number;
  sub_product_name: string;
  sub_product_content: string;
  sub_product_price: number;
  product_type: string;
  is_show: string;
  validity_term: number;
  created_at?: number;
  updated_at?: number;
}

interface CouponInformationType {
  coupon_id: number;
  couponbox_id: number;
  coupon_name: string;
  expiration_date: number;
  discount_rate: number;
  created_at: number;
  updated_at: number;
}

export interface CardInformationType {
  issuerCode: string;
  acquirerCode: string;
  number: string;
  cardType: string;
  ownerType: string;
}

interface PaymentInformationType {
  mId: string;
  paymentKey: string;
  orderId: string;
  orderName: string;
  status: string;
  requestedAt: string;
  card: CardInformationType;
  totalAmount: number;
  suppliedAmount: number;
  method: string;
}

//결제내역 response 데이터
export interface GetPaymentListResponseData {
  salesmanager_id: number;
  sub_product_information: SubProductInformationType;
  sub_type: "Y" | "N";
  price: number;
  final_discount: number;
  final_price: number;
  amount: number;
  payment_status: string;
  coupon_type: string;
  coupon_information: CouponInformationType;
  payment_method: string;
  payment_information: PaymentInformationType;
  builing_key: string;
  customer_key: string;
  toss_id: string;
  created_at?: number;
  updated_at?: number;
  payment_id?: number;
}

//결제생성 파라미터
interface useCreatePaymentListParams {
  paymentInfo: GetPaymentListResponseData[];
}

//결제리스트 response 데이터
export interface PaymentListResponse {
  status: number; //응답
  message: string; //메세지
  data: GetPaymentListResponseData[];
}

// 결제 생성 (구독정보는 salesmanager_id로 맵핑)
export const useCreatePaymentList = () =>
  useMutation<PaymentListResponse, AxiosError, useCreatePaymentListParams>({
    mutationKey: ["createPaymentList"],
    mutationFn: (params) =>
      baseAxios
        .post(`/payment/bulk/create`, params.paymentInfo)
        .then((res) => res.data),
  });

// 결제 - 모든결제 조회 (구독정보는 salesmanager_id로 맵핑)
export const useGetPaymentList = () =>
  useMutation<PaymentListResponse, AxiosError, GetPaymentListParams>({
    mutationKey: ["getPaymentList"],
    mutationFn: (params) =>
      baseAxios
        .get(
          params.salesmanager_id
            ? `/payment/${params.salesmanager_id}`
            : `/payment`
        )
        .then((res) => res.data),
  });
