import { GetNoticeListDataType, useGetNoticeList } from "api/api.notice";
import { TableTd, TableTr, TableWrapper } from "components/atoms/table/table";
import Typograpy from "components/molecules/Typograpy";
import {
  BoardFilterType,
  BoardListFilter,
} from "components/organisms/filter/BoardListFilter";
import { NoticeTableHeader } from "components/organisms/notice/NoticeTableHeader";
import { Pagination } from "components/organisms/pagination/Pagination";
import React from "react";
import { useNavigate } from "react-router";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { convertUnixDate } from "utils/func";

export const NoticeListTemplate = () => {
  const navigate = useNavigate();

  const {
    data: noticeData,
    isLoading: noticeLoading,
    isError: noticeIsError,
    error: noticeError,
    refetch: noticeRefetch,
  } = useGetNoticeList();

  //공지사항 리스트
  const [noticeList, setNoticeList] = React.useState<GetNoticeListDataType[]>(
    []
  );

  const [boardFilter, setBoardFilter] = React.useState<BoardFilterType>({
    state: "전체",
    keyword: "",
    date_start: "",
    date_end: "",
  });

  const boardFilterHandler = (value: string, key?: string) => {
    if (key) {
      setBoardFilter({
        ...boardFilter,
        [key]: value,
      });
    }
  };

  const [noticePage, setNoticePage] = React.useState(1);
  const startPost = (noticePage - 1) * 10 + 1;
  const endPost = startPost + 10 - 1;

  const applyFilters = () => {
    if (!noticeData) return;

    let filtered = [...noticeData.data];
    if (boardFilter.state) {
      filtered = filtered.filter((item) => {
        if (boardFilter.state === "전체") {
          return true;
        } else if (boardFilter.state === "노출") {
          return item.is_show === "Y";
        } else {
          return item.is_show === "N";
        }
      });
    }

    if (boardFilter.keyword) {
      filtered = filtered.filter((item) =>
        item.notice_name
          .toLowerCase()
          .includes(boardFilter.keyword.toLowerCase())
      );
    }

    if (boardFilter.date_start) {
      filtered = filtered.filter(
        (item) =>
          new Date(convertUnixDate(item.created_at || 0)) >=
          new Date(boardFilter.date_start)
      );
    }

    if (boardFilter.date_end) {
      filtered = filtered.filter(
        (item) =>
          new Date(convertUnixDate(item.created_at || 0)) <=
          new Date(boardFilter.date_end)
      );
    }

    setNoticeList(filtered);
  };

  React.useEffect(() => {
    if (noticeData) {
      setNoticeList([
        ...noticeData.data.filter((el) => el.is_show === "Y").reverse(),
      ]);
    }
  }, [noticeData]);

  return (
    <FlexBox $py={4} $bgcolor={colors.WHITE} $isFullWidth>
      <BoardListFilter
        boardFilter={boardFilter}
        boardFilterHandler={boardFilterHandler}
        applyFilters={applyFilters}
      />
      <Box $isFullWidth $mt={2}>
        <TableWrapper>
          <thead>
            <TableTr>
              <NoticeTableHeader title={"공지번호"} width={"10%"} />
              <NoticeTableHeader title={"공지명"} width={"50%"} />
              <NoticeTableHeader title={"등록일"} width={"30%"} />
              {/* <NoticeTableHeader title={"작성자"} width={"10%"} /> */}
            </TableTr>
          </thead>
          {noticeList.length > 0 && (
            <tbody>
              {noticeList.slice(startPost - 1, endPost).map((item, index) => {
                return (
                  <TableTr key={"notice" + index}>
                    <TableTd>
                      <Typograpy
                        fontStyle={fontStyle.caption1}
                        color={colors.BLACK}
                      >
                        {noticeList.length - startPost - index + 1}
                      </Typograpy>
                    </TableTd>
                    <TableTd>
                      <Box
                        $isPointer
                        onClick={() => {
                          navigate(`/notice/${item.notice_id}`);
                        }}
                      >
                        <Typograpy
                          fontStyle={fontStyle.caption1}
                          color={colors.BLACK}
                          hoverColor={colors.ADMIN_MAIN_COLOR}
                        >
                          {item.notice_name}
                        </Typograpy>
                      </Box>
                    </TableTd>
                    <TableTd>
                      <Typograpy
                        fontStyle={fontStyle.caption1}
                        color={colors.BLACK}
                      >
                        {convertUnixDate(item.created_at)}
                      </Typograpy>
                    </TableTd>
                  </TableTr>
                );
              })}
            </tbody>
          )}
        </TableWrapper>
        {noticeList.length === 0 && (
          <FlexBox $isFullWidth $ai={"center"} $jc={"center"} $mt={2}>
            <Typograpy>생성된 공지사항이 존재하지 않습니다.</Typograpy>
          </FlexBox>
        )}
      </Box>
      <FlexBox $isFullWidth $ai={"center"}>
        <Pagination
          page={noticePage}
          setPage={setNoticePage}
          rowCount={noticeList.length}
          rowPerPage={10}
        />
      </FlexBox>
    </FlexBox>
  );
};
