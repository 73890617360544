import { baseAxios } from "api/baseAxios";
import { alio_proposal } from "utils/aligoTemplate";

export const sendAligoMessagae = (params: {
  receiver: string;
  title: string;
  name: string;
}) =>
  baseAxios
    .post("/aligo/send-alimtalk", {
      ...alio_proposal(params),
    })
    .then((res) => res.data);
