import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError, AxiosResponse } from "axios";
import { LocationListElType } from "page/SignUpPage";

interface InquiryProps {
  inquiry_id: number;
}

export interface InquiryParamsWithDataType {
  inquiry_id?: number;
  salesmanager_id: number;
  title: string;
  content: string;
  admin_id: null | number;
  answer: string;
  is_faq: string;
  is_visible: string;
  created_at?: number;
  updated_at?: number;
  answer_date?: number;
}

interface GetInqueryListParams {
  salesmanager_id?: number;
  is_visible?: string;
  is_faq?: string;
}

export interface InquiryResponse {
  status: number;
  message: string;
  data: InquiryParamsWithDataType;
}

export interface InquiryListResponse {
  status: number;
  message: string;
  data: InquiryParamsWithDataType[];
}

export interface DeleteInquiryResponse {
  status: number; //응답
  message: string; //메세지
  data: {
    raw: string[];
    affected: number;
  };
}

//문의사항 생성
export const useCreateInquiry = () =>
  useMutation<InquiryResponse, AxiosError, InquiryParamsWithDataType>({
    mutationKey: ["createSaller"],
    mutationFn: (params) =>
      baseAxios.post("/inquiry", params).then((res) => res.data),
  });

//문의사항 삭제
export const useDeleteInquiry = ({ inquiry_id }: InquiryProps) =>
  useMutation<DeleteInquiryResponse, AxiosError, InquiryParamsWithDataType>({
    mutationKey: ["deleteInquiry"],
    mutationFn: () =>
      baseAxios.delete(`/inquiry_id/${inquiry_id}`).then((res) => {
        return res.data;
      }),
  });

//문의사항 상세정보
export const useGetInquiryDetail = (params: InquiryProps) =>
  useQuery<InquiryResponse, AxiosError>({
    queryKey: ["inqueryDetail"],
    queryFn: () =>
      baseAxios
        .get(`/inquiry/${params.inquiry_id}`)
        .then((res) => {
          return res.data;
        })
        .catch((err) => console.log(err)),
  } as UseQueryOptions<InquiryResponse, AxiosError>);

//문의사항 전체조회
export const useGetInquiryList = (params: GetInqueryListParams) =>
  useQuery<InquiryListResponse, AxiosError>({
    queryKey: ["inquiryList"],
    queryFn: () =>
      baseAxios
        .get("/inquiry", { params: { ...params } })
        .then((res) => res.data),
  } as UseQueryOptions<InquiryListResponse, AxiosError>);

//문의사항 수정
export const useUpdateInquiry = ({ inquiry_id }: InquiryProps) =>
  useMutation<InquiryResponse, AxiosError, InquiryParamsWithDataType>({
    mutationKey: ["updateInquiry"],
    mutationFn: (params: InquiryParamsWithDataType) =>
      baseAxios.put(`/inquiry/${inquiry_id}`, { ...params }).then((res) => {
        return res.data;
      }),
  });
