import { useMutation } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError, AxiosResponse } from "axios";
import { AdminInfoType } from "store/useAdminInfoStore";
import { UserInfoType } from "store/userInfoStore";

interface CheckTokenParams {}

export interface CheckTokenResponse {
  status: number;
  message: string;
  data: UserInfoType | AdminInfoType;
}

interface CheckTokenProps {
  onSuccess: (data: CheckTokenResponse) => void;
  onError: (error: AxiosError) => void;
}

//토큰 검사
export const useCheckToken = ({ onSuccess, onError }: CheckTokenProps) =>
  useMutation<CheckTokenResponse, AxiosError, CheckTokenParams>({
    mutationKey: ["checkToken"],
    mutationFn: () => baseAxios.post("/auth/check").then((res) => res.data),
    onSuccess,
    onError,
  });
