import { Divider } from "components/molecules/divider/Divider";
import { PrimaryTab } from "components/molecules/tab/PrimaryTab";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { SolidButton } from "components/molecules/button/SolidButton";
import ModalPortal from "utils/Portal";
import { AddCouponModal } from "components/organisms/modal/AddCouponModal";
import { CouponBoxData, useGetCouponBoxList } from "api/api.couponBox";
import { useUserInfoStore } from "store/userInfoStore";
import DataTable, { TableColumn } from "react-data-table-component";
import { convertUnixDate, isDateInRange } from "utils/func";
import { DatatablePagination } from "components/organisms/pagination/DatatablePagination";
import { tableCustomStyles } from "theme/table.custom";
import { useCouponStore } from "store/useCouponStore";
import { NoneDataComponent } from "components/organisms/table/NoneDataComponent";

const CouponTemplate = () => {
  const { salesmanager_id } = useUserInfoStore((state) => state);

  const [addCouponModal, setAddCouponModal] = React.useState(false);

  const { data: couponBoxData, refetch: couponBoxRefetch } =
    useGetCouponBoxList({ salesmanager_id });

  const { couponList } = useCouponStore((state) => state);

  const [couponBoxList, setCouponBoxList] = React.useState<CouponBoxData[]>([]);

  const refreshCouponBox = () => {
    couponBoxRefetch();
  };

  const columns: TableColumn<CouponBoxData>[] = [
    {
      name: "NO",
      sortable: false,
      cell: (_: any, index: number) => index + 1,
      width: "6rem",
      grow: 1,
    },
    {
      name: "쿠폰명",
      selector: (row) => {
        if (couponList.length === 0) {
          return "";
        } else {
          const filterCouponInfo = couponList.filter(
            (el) => el.coupon_id === row.coupon_id
          );

          if (filterCouponInfo.length === 0) {
            return "";
          } else {
            return filterCouponInfo[0].coupon_name;
          }
        }
      },
      sortable: false,
      grow: 2,
    },
    {
      name: "할인율",
      selector: (row) => {
        if (couponList.length === 0) {
          return "";
        } else {
          const filterCouponInfo = couponList.filter(
            (el) => el.coupon_id === row.coupon_id
          );

          if (filterCouponInfo.length === 0) {
            return "";
          } else {
            return filterCouponInfo[0].discount_rate + "%";
          }
        }
      },
      sortable: false,
    },
    {
      name: "사용여부",
      selector: (row) =>
        row.coupon_usage_status === "N" ? "미사용" : "사용완료",
      sortable: true,
    },
    {
      name: "비고",
      selector: (row) => {
        if (couponList.length === 0) {
          return "";
        } else {
          const filterCouponInfo = couponList.filter(
            (el) => el.coupon_id === row.coupon_id
          );

          if (filterCouponInfo.length === 0) {
            return "";
          } else {
            return filterCouponInfo[0].coupon_content;
          }
        }
      },
      sortable: true,
      grow: 2,
    },
    {
      name: "사용기간",
      selector: (row) => {
        if (couponList.length === 0) {
          return "";
        } else {
          const filterCouponInfo = couponList.filter(
            (el) => el.coupon_id === row.coupon_id
          );

          if (filterCouponInfo.length === 0) {
            return "";
          } else {
            if (
              isDateInRange(
                filterCouponInfo[0].expiration_start_date,
                filterCouponInfo[0].expiration_end_date
              )
            ) {
              return `${convertUnixDate(
                filterCouponInfo[0].expiration_start_date
              )} ~ ${convertUnixDate(filterCouponInfo[0].expiration_end_date)}`;
            } else {
              return "만료";
            }
          }
        }
      },
      sortable: true,
      grow: 2,
    },
    {
      name: "쿠폰코드",
      selector: (row) => {
        if (couponList.length === 0) {
          return "";
        } else {
          const filterCouponInfo = couponList.filter(
            (el) => el.coupon_id === row.coupon_id
          );

          if (filterCouponInfo.length === 0) {
            return "";
          } else {
            return filterCouponInfo[0].coupon_code;
          }
        }
      },
      sortable: true,
      grow: 2,
    },
  ];

  React.useEffect(() => {
    if (couponBoxData) {
      if (couponBoxData.status === 200) {
        setCouponBoxList([...couponBoxData.data]);
      }
    }
  }, [couponBoxData]);

  return (
    <FlexBox $isFullWidth>
      <ModalPortal>
        <AddCouponModal
          visible={addCouponModal}
          modalOff={() => {
            setAddCouponModal(false);
          }}
          couponBoxList={couponBoxList}
          refreshCouponBox={refreshCouponBox}
        />
      </ModalPortal>
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc="space-between"
        $ai="center"
        height={6}
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $pl={2}
        $pr={4}
      >
        <Typograpy
          fontStyle={fontStyle.body1_normal}
          fontWeight="SemiBold"
          color={colors.SALLER_SUBSIDE_TITLE}
        >
          쿠폰관리
        </Typograpy>
        <SolidButton
          buttonLabel="쿠폰등록"
          $br={4}
          size={"medium"}
          onClick={() => {
            setAddCouponModal(true);
          }}
        />
      </FlexBox>
      <Box $isFullWidth $pa={2}>
        <DataTable
          columns={columns}
          data={couponBoxList.filter((couponBoxItem) =>
            couponList.some(
              (couponListItem) =>
                couponBoxItem.coupon_id === couponListItem.coupon_id
            )
          )}
          defaultSortFieldId={1}
          pagination
          paginationComponent={DatatablePagination}
          customStyles={tableCustomStyles}
          noDataComponent={
            <NoneDataComponent comment="등록된 쿠폰이 존재하지 않습니다." />
          }
        />
      </Box>
    </FlexBox>
  );
};

export default CouponTemplate;
