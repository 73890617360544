import React from "react";
import Modal from "react-modal";
import { CommonModalType } from "./commonModalType";
import { Box, FlexBox } from "theme/globalStyle";
import Typograpy from "components/molecules/Typograpy";
import { fontStyle } from "theme/fontStyle";
import { IcClose } from "components/atoms/icon/icon";
import { Divider } from "components/molecules/divider/Divider";
import { TableTitle } from "components/molecules/table/TableTitle";
import { TableContent } from "components/molecules/table/TableContent";
import { colors } from "theme/colors";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { TableRowWrapper } from "components/atoms/table/table";
import { Switch } from "components/molecules/switch/Switch";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { SolidButton } from "components/molecules/button/SolidButton";
import useScrollLock from "hooks/useScrollLock";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftjsToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import { SelectBox } from "components/molecules/selectbox/SelectBox";
import { NumberComma, renderMonthOptions } from "utils/func";
import {
  CreateSubProductParamsWithType,
  GetSubProductListResponse,
  useCreateSubProduct,
  useEditSubproduct,
} from "api/api.subproduct";
import { useSnackbarStore } from "store/snackbarStore";
import htmlToDraft from "html-to-draftjs";
import { useQueryClient } from "@tanstack/react-query";
import { CommonModal } from "./CommonModal";

interface ManageSubscribeModalType extends CommonModalType {
  mode: "create" | "edit";
  sub_product_id?: number;
}

export const ManageSubscribeModal: React.FC<ManageSubscribeModalType> = ({
  visible,
  modalOff,
  mode,
  sub_product_id,
}) => {
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);
  const queryClient = useQueryClient();
  const subscribeData: GetSubProductListResponse | undefined =
    queryClient.getQueryData(["subProductList"]);

  const { mutate: createSubMutate } = useCreateSubProduct();
  const { mutate: updateSubMutate } = useEditSubproduct({
    sub_product_id: sub_product_id || 0,
  });

  const [subscribeInfo, setSubscribeInfo] = React.useState({
    sub_product_name: "",
    sub_product_subname: "",
    sub_product_price: 0,
    product_type: "",
    validity_term: "12",
    is_show: "Y",
  });

  const [inputError, setInputError] = React.useState({
    sub_product_name: false,
    sub_product_subname: false,
    sub_product_content: false,
    sub_product_price: false,
    sub_product_optname: false,
    product_type: false,
  });

  const [subProductList, setSubProductList] = React.useState<
    CreateSubProductParamsWithType[]
  >([]);

  function subscribeInfoHandler(value: string, key?: string) {
    if (key) {
      setInputError({ ...inputError, [key]: false });
      setSubscribeInfo((prev) => ({ ...prev, [key]: value }));
    }
  }

  const [editorState, setEditorState] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  function editSetting() {
    if (mode === "edit" && sub_product_id) {
      const selSubInfo = subProductList.find(
        (el) => el.sub_product_id === sub_product_id
      );

      if (selSubInfo) {
        setSubscribeInfo({
          is_show: selSubInfo.is_show,
          product_type: selSubInfo.product_type,
          sub_product_name: selSubInfo.sub_product_name,
          sub_product_price: selSubInfo.sub_product_price,
          sub_product_subname: selSubInfo.sub_product_subname,
          validity_term: String(selSubInfo.validity_term),
        });

        //수정모드 - 에디터 셋팅
        const contentState = ContentState.createFromBlockArray(
          htmlToDraft(selSubInfo.sub_product_content).contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }

  //editor 공백만 있을경우 및 trim처리
  const isEditorEmpty = (): boolean => {
    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText(); // 실제 텍스트 추출
    return !plainText.trim(); // 공백만 있는지 확인
  };

  function vertifySubscribe() {
    if (subscribeInfo.sub_product_name === "") {
      setInputError((prev) => ({ ...prev, sub_product_name: true }));
      return false;
    } else if (
      subProductList.filter(
        (el) => el.sub_product_name === subscribeInfo.sub_product_name
      ).length > 0 &&
      mode === "create"
    ) {
      showSnackbar("동일한 구독상품이 존재합니다.");
      return false;
    } else if (subscribeInfo.sub_product_subname === "") {
      setInputError((prev) => ({ ...prev, sub_product_subname: true }));
      return false;
    } else if (subscribeInfo.product_type === "") {
      setInputError((prev) => ({ ...prev, product_type: true }));
      return false;
    } else if (subscribeInfo.sub_product_price === 0) {
      setInputError((prev) => ({ ...prev, sub_product_price: true }));
      return false;
    } else if (isEditorEmpty()) {
      setInputError((prev) => ({ ...prev, sub_product_content: true }));
      return false;
    }
    return true;
  }

  function subscribeManageAccess() {
    if (!vertifySubscribe()) return;

    const params = {
      ...subscribeInfo,
      sub_product_content: draftjsToHtml(
        convertToRaw(editorState.getCurrentContent())
      ).replaceAll("<p></p>", "<br />"),
      sub_product_optname:
        subscribeInfo.sub_product_name +
        "(" +
        NumberComma(subscribeInfo.sub_product_price) +
        ")",
      validity_term: Number(subscribeInfo.validity_term),
      sub_product_price: Number(subscribeInfo.sub_product_price),
    };
    if (mode === "create") {
      createSubMutate(params, {
        onSuccess: (res) => {
          if (res.status === 200) {
            showSnackbar("구독상품 생성이 완료되었습니다.");
            queryClient.invalidateQueries({
              queryKey: ["subProductList"],
            });
            modalOff();
          } else {
            showSnackbar("예기치 못한 오류가 발생했습니다.");
          }
        },
        onError: (error) => {
          showSnackbar(error.message);
        },
      });
    } else {
      updateSubMutate(params, {
        onSuccess: (res) => {
          if (res.status === 200) {
            showSnackbar("구독상품 수정이 완료되었습니다.");
            queryClient.invalidateQueries({
              queryKey: ["subProductList"],
            });
            modalOff();
          } else {
            showSnackbar("예기치 못한 오류가 발생했습니다.");
          }
        },
        onError: (error) => {
          showSnackbar(error.message);
        },
      });
    }
  }

  React.useEffect(() => {
    if (subscribeData) {
      if (subscribeData.status === 200) {
        setSubProductList([...subscribeData.data]);
      }
    }
  }, [subscribeData]);

  React.useEffect(() => {
    if (mode === "edit") {
      editSetting();
    }
  }, [sub_product_id, mode]);

  React.useEffect(() => {
    if (!visible) {
      setSubscribeInfo({
        sub_product_name: "",
        sub_product_subname: "",
        sub_product_price: 0,
        product_type: "",
        validity_term: "12",
        is_show: "Y",
      });
    }
  }, [visible]);

  useScrollLock(visible);

  return (
    <CommonModal visible={visible} modalOff={modalOff}>
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc={"space-between"}
        $ai={"center"}
        $px={3}
      >
        <Typograpy fontStyle={fontStyle.heading2}>
          {mode === "create" ? "구독상품 생성" : "구독상품 관리"}
        </Typograpy>
        <Box $isPointer onClick={modalOff}>
          <IcClose />
        </Box>
      </FlexBox>
      <Box $isFullWidth $my={2}>
        <Divider variant="normal" width={"100%"} />
      </Box>
      <Box $px={0.8} $mb={2}>
        <FlexBox
          $btw={1}
          $bbc={colors.DEFAULT_BORDER_COLOR}
          $btc={colors.DEFAULT_BORDER_COLOR}
        >
          <TableRowWrapper>
            <TableTitle title={"상품명"} isRequire />
            <TableContent isColumnCenter>
              <PrimaryInput
                value={subscribeInfo.sub_product_name}
                placeholder="상품명을 입력해주세요."
                onChangeValue={subscribeInfoHandler}
                stateKey="sub_product_name"
                $isError={inputError.sub_product_name}
                errorMsg="상품명을 입력해주세요."
              />
            </TableContent>
          </TableRowWrapper>
          <TableRowWrapper>
            <TableTitle title={"소제목"} isRequire />
            <TableContent isColumnCenter>
              <PrimaryInput
                value={subscribeInfo.sub_product_subname}
                placeholder="소제목을 입력해주세요."
                onChangeValue={subscribeInfoHandler}
                stateKey="sub_product_subname"
                $isError={inputError.sub_product_subname}
                errorMsg="소제목을 입력해주세요."
              />
            </TableContent>
          </TableRowWrapper>
        </FlexBox>
        <TableRowWrapper>
          <TableTitle title={"영문명"} isRequire />
          <TableContent isColumnCenter>
            <PrimaryInput
              value={subscribeInfo.product_type}
              placeholder="영문명을 입력해주세요.(소문자)"
              onChangeValue={subscribeInfoHandler}
              stateKey="product_type"
              $isError={inputError.product_type}
              errorMsg="영문명을 입력해주세요."
            />
          </TableContent>
        </TableRowWrapper>
        <TableRowWrapper>
          <TableTitle title={"결제주기"} isRequire />
          <TableContent isLeft>
            <FlexBox
              $flexDirection="row"
              $isFullWidth
              $ai={"center"}
              style={{ gap: "1.2rem" }}
            >
              <SelectBox
                selOpt={subscribeInfo.validity_term}
                optList={renderMonthOptions()}
                onChangeValue={subscribeInfoHandler}
                placeholder="주기를 선택하세요."
                boxStyle={{ width: "auto" }}
                stateKey="validity_term"
              />
              <Typograpy fontStyle={fontStyle.body1_reading}>개월</Typograpy>
            </FlexBox>
          </TableContent>
        </TableRowWrapper>
        <TableRowWrapper>
          <TableTitle title={"구독료"} isRequire />
          <TableContent isColumnCenter>
            <PrimaryInput
              value={String(subscribeInfo.sub_product_price)}
              placeholder="구독료를 입력해주세요."
              onChangeValue={subscribeInfoHandler}
              inputType={"number"}
              stateKey="sub_product_price"
              $isError={inputError.sub_product_price}
              errorMsg="구독료를 입력해주세요."
            />
          </TableContent>
        </TableRowWrapper>
        <TableRowWrapper>
          <TableTitle title={"상품설명"} isRequire />
          <TableContent isLeft>
            <Box>
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbarClassName="tool-wrapper"
                wrapperClassName="wrapper-area"
                editorClassName="editor-area"
                //   editorClassName="editor-wrapper"
              />
              {inputError.sub_product_content && (
                <Box $mt={1.2}>
                  <Typograpy fontStyle={fontStyle.caption1} color={colors.RED}>
                    내용을 입력해주세요.
                  </Typograpy>
                </Box>
              )}
            </Box>
          </TableContent>
        </TableRowWrapper>
      </Box>
      <Divider variant="normal" width={"100%"} />
      <FlexBox
        $px={2}
        $pt={2}
        $flexDirection="row"
        $jc={"space-between"}
        $ai={"center"}
        $isFullWidth
      >
        <FlexBox $flexDirection="row" $gap={0.8} $ai={"center"}>
          <Typograpy fontStyle={fontStyle.label1_reading}>노출여부</Typograpy>
          <Switch
            $bgcolor={colors.ADMIN_MAIN_COLOR}
            $checked={subscribeInfo.is_show === "Y"}
            onClick={() => {
              subscribeInfoHandler(
                subscribeInfo.is_show === "Y" ? "N" : "Y",
                "is_show"
              );
            }}
            size="medium"
          />
        </FlexBox>
        <FlexBox $flexDirection="row" $gap={0.8}>
          <OutlineButton
            buttonLabel="취소"
            bgcolor={colors.WHITE}
            bc={colors.ADMIN_MAIN_COLOR}
            fontStyle={`color : ${colors.ADMIN_MAIN_COLOR}`}
            size={"large"}
            $br={4}
            onClick={modalOff}
          />
          <SolidButton
            buttonLabel={mode === "create" ? "등록" : "수정"}
            size={"large"}
            $br={4}
            bgcolor={colors.ADMIN_MAIN_COLOR}
            isLoading={false}
            onClick={subscribeManageAccess}
          />
        </FlexBox>
      </FlexBox>
    </CommonModal>
  );
};
