import { useAdminLogin } from "api/auth/AdminLogin";
import { IcKakao, IcNaver } from "components/atoms/icon/icon";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import { LoginForm } from "components/organisms/user/LoginForm";
import React from "react";
import { useNavigate } from "react-router";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox, UserMaxWidth } from "theme/globalStyle";

const AdminLoginTemplate = () => {
  const navigate = useNavigate();

  return (
    <FlexBox $minHeight={70} $isFullWidth $ai="center">
      <FlexBox
        $isFullWidth
        $py={4}
        $ai="center"
        $jc="center"
        $bgcolor={colors.USER_HEADER_BG}
      >
        <Typograpy
          fontSize={45}
          fontWeight="Bold"
          color={colors.WHITE}
          style={{ lineHeight: "4.8rem" }}
        >
          관리자 로그인
        </Typograpy>
      </FlexBox>
      <Box $isFullWidth $maxWidth={UserMaxWidth}>
        <Box $mt={4}>
          <LoginForm accessType="ADMIN" />
        </Box>

        {/* <Box $my={4}>
          <Divider variant="normal" width={"100%"} />
        </Box>
        <Box $mt={2} $mb={4}>
          <OutlineButton
            size={"large"}
            buttonLabel="회원가입"
            style={{ width: "100%" }}
            onClick={() => {
              navigate("/join/selType");
            }}
          />
        </Box> */}
      </Box>
    </FlexBox>
  );
};

export default AdminLoginTemplate;
