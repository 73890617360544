import { IcClose } from "components/atoms/icon/icon";
import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import Modal from "react-modal";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { CommonModalType } from "./commonModalType";
import ModalPortal from "utils/Portal";
import { AlertModal } from "./AlertModal";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { colors } from "theme/colors";
import { SolidButton } from "components/molecules/button/SolidButton";
import { DetailTableRow } from "../table/DetailTableRow";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { useSnackbarStore } from "store/snackbarStore";
import { useQueryClient } from "@tanstack/react-query";
import draftjsToHtml from "draftjs-to-html";
import { useUserInfoStore } from "store/userInfoStore";
import { useAdminInfoStore } from "store/useAdminInfoStore";
import { getUnixDate } from "utils/func";
import { useLoadingStore } from "store/useLoadingStore";
import {
  InquiryParamsWithDataType,
  useCreateInquiry,
  useGetInquiryDetail,
  useUpdateInquiry,
} from "api/api.inquiry";
import { CommonModal } from "./CommonModal";

interface QnaModalPropsType extends CommonModalType {
  qnaId?: number;
  mode: "create" | "salesUpdate" | "admin" | "detail";
}

export const QnaModal: React.FC<QnaModalPropsType> = ({
  visible,
  modalOff,
  qnaId,
  mode,
}) => {
  //modal 활성화시 body 스크롤 lock

  const queryClient = useQueryClient();
  const { salesmanager_id } = useUserInfoStore((state) => state);
  const { admin_id } = useAdminInfoStore((state) => state);

  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);
  const { updateLoading } = useLoadingStore((state) => state);
  const {
    data: detailData,
    error: detailError,
    refetch: detailRefetch,
  } = useGetInquiryDetail({
    inquiry_id: qnaId || 0,
  });
  const { mutate: createInquiry, isPending: createInquiryPending } =
    useCreateInquiry();
  const { mutate: updateInquiry, isPending: updateInquiryPending } =
    useUpdateInquiry({
      inquiry_id: qnaId || 0,
    });

  const [inquiryInfo, setInquiryInfo] =
    React.useState<InquiryParamsWithDataType>({
      salesmanager_id: 0,
      title: "",
      content: "",
      admin_id: 0,
      answer: "",
      is_faq: "N",
      is_visible: "Y",
    });

  const [inputError, setInputError] = React.useState({
    title: false,
    content: false,
    answer: false,
  });

  const [adminMode, setAdminMode] = React.useState<"edit" | "detail">("edit");

  const [editorState, setEditorState] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  //editor 공백만 있을경우 및 trim처리
  const isEditorEmpty = (): boolean => {
    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText(); // 실제 텍스트 추출
    return !plainText.trim(); // 공백만 있는지 확인
  };

  //문의하기 입력확인
  const inquiryVerify = () => {
    if (mode === "create" && inquiryInfo.title === "") {
      setInputError((prev) => ({ ...prev, title: true }));
      return false;
    } else if (mode === "create" && isEditorEmpty()) {
      setInputError((prev) => ({ ...prev, content: true }));
    } else if (mode === "admin" && isEditorEmpty()) {
      setInputError((prev) => ({ ...prev, answer: true }));
    } else {
      return true;
    }
  };

  function inquiryHandler() {
    if (inquiryVerify()) {
      if (mode === "create") {
        const params = {
          ...inquiryInfo,
          salesmanager_id,
          admin_id: null,
          content: draftjsToHtml(
            convertToRaw(editorState.getCurrentContent())
          ).replaceAll("<p></p>", "<br />"),
        };
        createInquiry(params, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ["inquiryList"],
            });
            modalOff();
            showSnackbar("문의가 완료되었습니다.");
          },
          onError: (error) => {
            console.log(error);
          },
        });
      } else if (mode === "admin") {
        const params = {
          ...inquiryInfo,
          admin_id,
          answer_date: getUnixDate(),
          answer: draftjsToHtml(
            convertToRaw(editorState.getCurrentContent())
          ).replaceAll("<p></p>", "<br />"),
        };
        updateInquiry(params, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ["inquiryList"],
            });
            modalOff();
            showSnackbar("답변이 완료되었습니다.");
          },
          onError: (error) => {
            console.log(error);
          },
        });
      }
    }
  }

  // React.useEffect(() => {
  //   updateLoading(createInquiryPending || updateInquiryPending);
  // }, [createInquiryPending, updateInquiryPending]);

  React.useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      setInquiryInfo({
        admin_id: 0,
        answer: "",
        content: "",
        is_faq: "N",
        is_visible: "Y",
        salesmanager_id: 0,
        title: "",
      });
      updateLoading(false);
      setInputError({ title: false, content: false, answer: false });
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [visible]);

  React.useEffect(() => {
    if (qnaId && visible) {
      detailRefetch();
    }
  }, [qnaId, visible]);

  React.useEffect(() => {
    if (mode !== "create") {
      if (detailData) {
        if (detailData.status === 200) {
          setInquiryInfo((prev) => ({ ...prev, ...detailData.data }));
          if (mode === "admin") {
            setAdminMode(detailData.data.admin_id ? "detail" : "edit");
          }
        }
      }
    }
  }, [detailData]);

  return (
    <CommonModal visible={visible} modalOff={modalOff}>
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc={"space-between"}
        $ai={"center"}
        $px={3}
      >
        <Typograpy fontStyle={fontStyle.heading2}>
          {mode === "create" ? "문의하기" : "상세보기 및 답변"}
        </Typograpy>
        <Box $isPointer onClick={modalOff}>
          <IcClose />
        </Box>
      </FlexBox>
      <Box $isFullWidth $my={2}>
        <Divider variant="normal" width={"100%"} />
      </Box>
      <FlexBox $isFullWidth>
        <ModalPortal>
          <AlertModal
            visible={alertModal.visible}
            msg={alertModal.msg}
            type={alertModal.type}
            modalOff={() => {
              setAlertModal({ visible: false, msg: "", type: "" });
            }}
          />
        </ModalPortal>
        <Box $px={2} $pb={2} $isFullWidth>
          <DetailTableRow
            title={"제목"}
            isBtw
            contents={
              mode === "create" ? (
                <Box $py={1.2} $isFullWidth>
                  <PrimaryInput
                    value={inquiryInfo.title}
                    onChangeValue={(value) => {
                      if (inputError.title) {
                        setInputError((prev) => ({ ...prev, title: false }));
                      }
                      setInquiryInfo((prev) => ({ ...prev, title: value }));
                    }}
                    placeholder="제목을 입력해주세요"
                    errorMsg="제목을 입력해주세요."
                    $isError={inputError.title}
                  />
                </Box>
              ) : (
                <Typograpy fontStyle={fontStyle.body2_normal} fontWeight="Bold">
                  {inquiryInfo.title}
                </Typograpy>
              )
            }
            contentsStyle={{ paddingTop: 0, paddingBottom: 0 }}
            isLeft
            isColumnCenter
          />
          <DetailTableRow
            title={"내용"}
            contents={
              mode === "create" ? (
                <Box>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={(editorState: EditorState) => {
                      if (inputError.content) {
                        setInputError((prev) => ({ ...prev, content: false }));
                      }
                      setEditorState(editorState);
                    }}
                    toolbarClassName="tool-wrapper"
                    wrapperClassName="wrapper-area"
                    editorClassName="editor-area"
                    //   editorClassName="editor-wrapper"
                  />
                  {inputError.content && (
                    <Box $mt={1.2}>
                      <Typograpy
                        fontStyle={fontStyle.caption1}
                        color={colors.RED}
                      >
                        문의내용을 입력해주세요.
                      </Typograpy>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box
                  dangerouslySetInnerHTML={{
                    __html: inquiryInfo.content,
                  }}
                ></Box>
              )
            }
            contentsStyle={{
              padding: "2rem",
              maxHeight: mode === "create" ? "unset" : "20rem",
              overflowY: "scroll",
            }}
            isLeft
            isColumnCenter
          />
          {((mode === "detail" && inquiryInfo.answer !== "") ||
            mode === "admin") && (
            <DetailTableRow
              title={"답변"}
              contents={
                mode === "admin" && adminMode === "edit" ? (
                  <FlexBox
                    $isFullWidth
                    $flexDirection="row"
                    $ai={"center"}
                    $gap={1.2}
                  >
                    <Box>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={(editorState: EditorState) => {
                          if (inputError.answer) {
                            setInputError((prev) => ({
                              ...prev,
                              answer: false,
                            }));
                          }
                          setEditorState(editorState);
                        }}
                        toolbarClassName="tool-wrapper"
                        wrapperClassName="wrapper-area"
                        editorClassName="editor-area"
                        //   editorClassName="editor-wrapper"
                      />
                      {inputError.answer && (
                        <Box $mt={1.2}>
                          <Typograpy
                            fontStyle={fontStyle.caption1}
                            color={colors.RED}
                          >
                            답변을 입력해주세요.
                          </Typograpy>
                        </Box>
                      )}
                    </Box>
                  </FlexBox>
                ) : (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: inquiryInfo.answer,
                    }}
                  ></Box>
                )
              }
              contentsStyle={{
                padding: "2rem",
                maxHeight:
                  mode === "admin" && adminMode === "edit" ? "unset" : "20rem",
                overflowY: "scroll",
              }}
              isLeft
              isColumnCenter
            />
          )}
        </Box>
        {(mode === "admin" || mode === "create") && (
          <Box $isFullWidth>
            <Divider variant="normal" width={"100%"} />
            <FlexBox
              $px={2}
              $pt={2}
              $flexDirection="row"
              $jc={"flex-end"}
              $ai={"center"}
              $isFullWidth
            >
              <FlexBox $flexDirection="row" $gap={0.8}>
                <OutlineButton
                  buttonLabel="취소"
                  bgcolor={colors.WHITE}
                  bc={colors.ADMIN_MAIN_COLOR}
                  fontStyle={`color : ${colors.ADMIN_MAIN_COLOR}`}
                  size={"large"}
                  $br={4}
                  onClick={modalOff}
                />
                <SolidButton
                  buttonLabel={
                    mode === "admin" && adminMode === "detail" ? "수정" : "저장"
                  }
                  size={"large"}
                  $br={4}
                  bgcolor={colors.ADMIN_MAIN_COLOR}
                  isLoading={false}
                  onClick={() => {
                    if (mode === "admin" && adminMode === "detail") {
                      setAdminMode("edit");
                    } else {
                      inquiryHandler();
                    }
                  }}
                />
              </FlexBox>
            </FlexBox>
          </Box>
        )}
      </FlexBox>
    </CommonModal>
  );
};
