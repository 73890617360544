import { LoginPage } from "page/LoginPage";
import { MainPage } from "page/MainPage";
import { TextPage } from "page/TextPage";
import { SallerStaticArea } from "page/static/SallerStaticArea";
import StaticArea from "page/static/StaticArea";
import { createBrowserRouter } from "react-router-dom";
import SignUpPage from "page/SignUpPage";
import { SelectUserType } from "components/templates/user/SignUpTemplate/SelectUserType";
import { WirteUserInfo } from "components/templates/user/SignUpTemplate/WriteUserInfo";
import { Payment } from "components/templates/user/SignUpTemplate/Payment";
import AgreePage from "page/AgreePage";
import AgreeTemplate from "components/templates/user/AgreeTemplate/Index";
import { SallerMainPage } from "page/saller/SallerMainPage";
import { SallerCustomerList } from "page/saller/SallerCustomerList";
import { SallerProposal } from "page/saller/SallerProposal";
import { SallerMyInfo } from "page/saller/SallerMyInfo";
import { AdminStaticArea } from "page/static/AdminStaticArea";
import { AdminSallerList } from "page/admin/AdminSallerList";
import { AdminEnv } from "page/admin/AdminEnv";
import { AdminLoginPage } from "page/AdminLoginPage";
import { AdminNotice } from "page/admin/AdminNotice";
import { SocialLogin } from "page/auth/SocialLogin";
import { NoticePage } from "page/notice/NoticePage";
import { NoticeListTemplate } from "components/templates/notice/NoticeListTemplate";
import { NoticeDetailTemplate } from "components/templates/notice/NoticeDetailTemplate";
import { NotFound } from "page/NotFound";
import { PaymentSuccess } from "page/payment/PaymentSuccess";
import { PaymentRequest } from "page/payment/PaymentRequest";
import { AdminCoupon } from "page/admin/AdminCoupon";
import { FindPage } from "page/FindPage";
import { FindIdTemplate } from "components/templates/user/FindTemplate/FindIdTemplate";
import { FindPwTemplate } from "components/templates/user/FindTemplate/FindPwTemplate";
import { AdminQna } from "page/admin/AdminQna";
import { AdminHome } from "page/admin/AdminHome";
import { FindPwSuccessTemplate } from "components/templates/user/FindTemplate/FindPwSuccessTemplate";
import { PaymentFail } from "page/payment/PaymentFail";

const rootPath = "/";

export const MasterRouter = createBrowserRouter([
  {
    path: rootPath,
    element: <StaticArea />,
    children: [
      { path: rootPath, element: <MainPage /> },
      { path: rootPath + "login", element: <LoginPage /> },
      {
        path: rootPath + "find",
        element: <FindPage />,
        children: [
          { path: "findId", element: <FindIdTemplate /> },
          { path: "findPw", element: <FindPwTemplate /> },
          { path: "findPwSuccess", element: <FindPwSuccessTemplate /> },
        ],
      },
      { path: rootPath + "adminLogin", element: <AdminLoginPage /> },
      { path: "payment", element: <Payment /> },
      { path: "paymentRequest", element: <PaymentRequest /> },
      { path: "paymentSuccess", element: <PaymentSuccess /> },
      { path: "paymentFail", element: <PaymentFail /> },
      {
        path: "notice",
        element: <NoticePage />,
        children: [
          { path: "", element: <NoticeListTemplate /> },
          { path: ":noticeId", element: <NoticeDetailTemplate /> },
        ],
      },
      {
        path: rootPath + "join",
        element: <SignUpPage />,
        children: [
          // { path: "selType", element: <SelectUserType /> },
          { path: "writeUserInfo", element: <WirteUserInfo /> },
        ],
      },
      {
        path: rootPath + "agree",
        element: <AgreePage />,
        children: [
          { path: "privacy", element: <AgreeTemplate /> },
          { path: "service", element: <AgreeTemplate /> },
          { path: "refund", element: <AgreeTemplate /> },
          { path: "marketing", element: <AgreeTemplate /> },
        ],
      },
    ],
  },
  {
    path: "/saller",
    element: <SallerStaticArea />,
    children: [
      {
        path: "home",
        element: <SallerMainPage />,
      },
      {
        path: "customer",
        element: <SallerCustomerList />,
      },
      {
        path: "proposal",
        element: <SallerProposal />,
      },
      {
        path: "myInfo",
        element: <SallerMyInfo />,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminStaticArea />,
    children: [
      {
        path: "home",
        element: <AdminHome />,
        children: [{ path: "statistics" }, { path: "situation" }],
      },
      {
        path: "sallerList",
        element: <AdminSallerList />,
      },
      {
        path: "env",
        element: <AdminEnv />,
      },
      {
        path: "notice",
        element: <AdminNotice />,
      },
      {
        path: "coupon",
        element: <AdminCoupon />,
      },
      {
        path: "qna",
        element: <AdminQna />,
      },
    ],
  },
  {
    path: "auth",
    element: <SocialLogin />,
  },
  {
    path: "form",
    element: <TextPage />,
  },
  {
    path: "/*",
    element: <NotFound />,
  },
]);
