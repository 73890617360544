import React from "react";
import Typograpy from "components/molecules/Typograpy";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";

import { PieChartComponent } from "components/organisms/chart/PieChart";
import {
  CountByRegionDataProccessType,
  useGetCountByRegion,
} from "api/blueButton/getCountByRegion";
import { Input } from "components/atoms/input/input";
import { useSnackbarStore } from "store/snackbarStore";
import { getDate } from "utils/func";
import { useLocationStore } from "store/useLocationStore";
import { useGetSallerList } from "api/api.salesmanager";

export const DashboardTitle = ({ title }: { title: string }) => {
  return (
    <Typograpy fontStyle={fontStyle.label1_normal} fontWeight="Bold">
      {title}
    </Typograpy>
  );
};

const SalesHomeTemplate = () => {
  const showSnackBar = useSnackbarStore((state) => state.showSnackbar);
  const { locationList } = useLocationStore((state) => state);

  const { data: salesData } = useGetSallerList();
  //mapchart 시작 및 종료일
  const [mapStartDate, setMapStartDate] = React.useState(
    getDate().prevWeekHipon
  );
  const [mapEndDate, setMapEndDate] = React.useState(getDate().hiponText);
  const { data: countByRegionData, refetch: countByRegionRefetch } =
    useGetCountByRegion({
      startDate: mapStartDate,
      endDate: mapEndDate,
    });

  const [servicePer, setServicePer] = React.useState([
    { name: "보험", value: 0, fill: "#96C6FA" },
    { name: "금융", value: 0, fill: "#479EF5" },
    { name: "프리미엄", value: 0, fill: "#115EA3" },
  ]);
  const [localStats, setLocalStats] = React.useState<
    { locale: string; count: number }[]
  >([]);
  const [mapChartData, setMapChartData] = React.useState<
    CountByRegionDataProccessType[]
  >([]);

  //통계 셋팅
  function servicePerSetting() {
    if (!salesData || locationList.length === 0) return;

    const lookupTable = {
      insurances: "보험",
      finance: "금융",
      premium: "프리미엄",
    };

    let tempServicePer = [...servicePer];
    salesData.data.forEach((item) => {
      item.payment.forEach((paymentItem) => {
        //가입서비스 비율 셋팅
        const subProductType = paymentItem.sub_product_information.product_type;
        const serviceName =
          lookupTable[subProductType as keyof typeof lookupTable];

        if (serviceName) {
          const arrIndex = tempServicePer.findIndex(
            (el) => el.name === serviceName
          );
          if (arrIndex !== -1) {
            tempServicePer[arrIndex].value += 1;
          }
        }
      });
    });
    setServicePer([...tempServicePer]);
  }

  const dateHandler = (value: string, type: "start" | "end") => {
    if (type === "start") {
      if (mapEndDate !== "") {
        if (new Date(value) > new Date(mapEndDate)) {
          showSnackBar("시작일보다 종료일이 빠를 수 없습니다.");
        } else {
          setMapStartDate(value);
        }
      }
    } else {
      if (mapStartDate !== "") {
        if (new Date(mapStartDate) > new Date(value)) {
          showSnackBar("시작일보다 종료일이 빠를 수 없습니다.");
        } else {
          setMapEndDate(value);
        }
      }
    }
  };

  React.useEffect(() => {
    countByRegionRefetch();
  }, [mapStartDate, mapEndDate]);

  React.useEffect(() => {
    if (!salesData || locationList.length === 0) {
      return;
    }
    if (salesData.status === 200) {
      servicePerSetting();
    }
  }, [salesData, locationList]);

  React.useEffect(() => {
    if (countByRegionData) {
      if (countByRegionData.success) {
        let tempCountByRegionData: CountByRegionDataProccessType[] = [];
        countByRegionData.response.forEach((item) => {
          tempCountByRegionData.push({
            locale: item.regionName,
            count: item.count,
          });
        });
        setMapChartData([...tempCountByRegionData]);
      }
    }
  }, [countByRegionData]);

  return (
    <FlexBox $isFullWidth $ai={"center"}>
      <FlexBox
        $isFullWidth
        $maxWidth={`calc(${MainMaxWidth}rem + 4rem)`}
        $pa={4}
      >
        <Typograpy
          color={colors.MAIN_TITLE_FONT}
          fontStyle={fontStyle.headline1}
          fontWeight="SemiBold"
        >
          대쉬보드
        </Typograpy>
        <FlexBox
          $flexDirection="row"
          $ai={"stretch"}
          $isFullWidth
          $gap={1.6}
          $mt={2}
        >
          {/* <MonthJoin /> */}
        </FlexBox>
        <FlexBox
          $flexDirection="row"
          $ai={"stretch"}
          $isFullWidth
          $gap={1.6}
          $mt={2}
        >
          <Box $flex={1} $isFullWidth $shadowType="strong" $pa={2} $br={8}>
            <FlexBox $flexDirection="row" $ai={"center"} $jc={"space-between"}>
              <FlexBox>
                <DashboardTitle title={"가입서비스 비율"} />
                <Typograpy
                  fontStyle={fontStyle.caption1}
                  color={colors.SALLER_DESCRIPTION}
                >
                  Description
                </Typograpy>
              </FlexBox>
            </FlexBox>
            <Box width={"100%"} height={40}>
              <PieChartComponent dataList={servicePer} />
            </Box>
          </Box>
          <Box $flex={1} $isFullWidth $shadowType="strong" $pa={2} $br={8}>
            <FlexBox
              $flexDirection="row"
              $isFullWidth
              $jc={"space-between"}
              $ai={"center"}
            >
              <FlexBox
                $flexDirection="row"
                $ai={"center"}
                $jc={"space-between"}
              >
                <DashboardTitle title={"지역별 가입현황"} />
              </FlexBox>

              <FlexBox
                $mt={1.2}
                $flexDirection="row"
                $ai={"flex-end"}
                $gap={1.2}
              >
                <FlexBox $flexDirection="row" $ai={"flex-end"} $gap={1.2}>
                  <Input
                    value={mapStartDate}
                    style={{ width: "auto" }}
                    type={"date"}
                    onChange={(e) => {
                      dateHandler(e.target.value, "start");
                    }}
                    max={mapEndDate}
                  />
                  <Input
                    value={mapEndDate}
                    style={{ width: "auto" }}
                    type={"date"}
                    onChange={(e) => {
                      dateHandler(e.target.value, "end");
                    }}
                    min={mapStartDate}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <Box $isFullWidth $mt={2}>
              {/* <MapChart localData={mapChartData} /> */}
            </Box>
            <FlexBox $isPointer $isFullWidth $ai={"flex-end"}>
              <Typograpy
                fontStyle={fontStyle.label1_normal}
                color={colors.MAIN}
              >
                View Details
              </Typograpy>
            </FlexBox>
          </Box>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default SalesHomeTemplate;
