import { create } from "zustand";
import { persist } from "zustand/middleware";

interface LoadingStoreType {
  isLoading: boolean;
  updateLoading: (isLoading: boolean) => void;
}

export const useLoadingStore = create<LoadingStoreType>()((set) => ({
  isLoading: false,
  updateLoading: (isLoading: boolean) => set({ isLoading: isLoading }),
}));
