import { Img } from "components/atoms/img/img";
import { Divider } from "components/molecules/divider/Divider";
import React from "react";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";
import Typograpy from "components/molecules/Typograpy";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { SalesDescription } from "components/organisms/main/salesDescription/SalesDescription";
import { IcSolution, ImgRank } from "components/atoms/icon/icon";
import RollingBanner from "components/organisms/rollingBanner/RollingBanner";
import { dummyReviewList } from "utils/dummyList";
import { ReviewCard } from "components/organisms/main/review/ReviewCard";
import { bannerList, descriptionList } from "utils/list";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RatioImage } from "components/molecules/img/RatioImage";
const MainTemplate = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  //motion 컨테이너 셋팅
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      //보여질때 설정
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // 각 자식 요소 사이의 지연 시간
      },
    },
  };

  const itemVariants = {
    //각 이미지 motion 설정
    hidden: { opacity: 0, x: -50 }, //초기엔 y위치가 50
    visible: (custom: number) => ({
      opacity: 1,
      x: 0, //보여지면 정위치로 이동
      transition: {
        duration: 0.5,
        delay: custom * 0.5,
      },
    }),
  };

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
  };

  return (
    <FlexBox $isFullWidth $ai="center">
      <Slider {...settings}>
        <FlexBox $ai="center" $isFullWidth height={53} $jc={"center"}>
          <Box $position="absolute" top={0} $left={0}>
            <Img
              style={{ objectFit: "contain" }}
              width={"100%"}
              height={"100%"}
              src={require("assets/img/rolling1.png")}
            />
          </Box>
          <FlexBox
            style={{ zIndex: 2 }}
            $isFullWidth
            height={"100%"}
            $ai="center"
          >
            <FlexBox
              width={MainMaxWidth}
              $jc={"flex-end"}
              height={"100%"}
              $pa={2}
            >
              <FlexBox
                $flexDirection="row"
                $jc="space-between"
                $isFullWidth
                $maxWidth={MainMaxWidth}
                height={"100%"}
              >
                <FlexBox style={{ alignSelf: "center" }}>
                  <Typograpy
                    fontSize={27}
                    fontWeight="Regular"
                    style={{ lineHeight: "3.6rem" }}
                    color={colors.WHITE}
                  >
                    A new innovation in{" "}
                    <Typograpy
                      fontSize={27}
                      fontWeight="Bold"
                      style={{ lineHeight: "3.6rem" }}
                      color={colors.WHITE}
                    >
                      sales
                    </Typograpy>
                  </Typograpy>
                  <Typograpy
                    fontSize={52}
                    fontWeight="Bold"
                    style={{ lineHeight: "6.4rem" }}
                    color={colors.WHITE}
                  >
                    영업의 "판"을 바꾸다
                  </Typograpy>
                  <Typograpy
                    fontSize={48}
                    fontWeight="ExtraBold"
                    style={{ lineHeight: "6.4rem" }}
                    color={colors.WHITE}
                  >
                    Blue
                    <Typograpy
                      fontSize={48}
                      fontWeight="Light"
                      style={{ lineHeight: "6.4rem" }}
                      color={colors.WHITE}
                    >
                      sales
                    </Typograpy>
                  </Typograpy>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox $ai="center" $isFullWidth height={53} $jc={"center"}>
          <Box $position="absolute" top={0} $left={0}>
            <Img
              style={{ objectFit: "contain" }}
              width={"100%"}
              height={"100%"}
              src={require("assets/img/rolling2.png")}
            />
          </Box>
          <FlexBox
            style={{ zIndex: 2 }}
            $isFullWidth
            height={"100%"}
            $ai="center"
          >
            <FlexBox
              width={MainMaxWidth}
              $jc={"flex-end"}
              height={"100%"}
              $pa={2}
            >
              <FlexBox $isFullWidth $mb={4}>
                <Typograpy
                  fontSize={52}
                  fontWeight="Bold"
                  style={{ lineHeight: "6.4rem" }}
                  color={colors.WHITE}
                >
                  거절없는 세일즈,
                </Typograpy>
                <Typograpy
                  fontSize={52}
                  fontWeight="Bold"
                  style={{ lineHeight: "6.4rem" }}
                  color={colors.WHITE}
                >
                  블루세일즈
                </Typograpy>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox $ai="center" $isFullWidth height={53} $jc={"center"}>
          <Box $position="absolute" top={0} $left={0}>
            <Img
              style={{ objectFit: "contain" }}
              width={"100%"}
              height={"100%"}
              src={require("assets/img/rolling3.png")}
            />
          </Box>
          <FlexBox
            style={{ zIndex: 2 }}
            $isFullWidth
            height={"100%"}
            $ai="center"
          >
            <FlexBox
              width={MainMaxWidth}
              $jc={"flex-end"}
              height={"100%"}
              $pa={2}
            >
              <FlexBox $isFullWidth $mb={4}>
                <Typograpy
                  fontSize={52}
                  fontWeight="Bold"
                  style={{ lineHeight: "6.4rem" }}
                  color={colors.WHITE}
                >
                  상위 5%로 향하는 영업인을 위해
                </Typograpy>
                <Typograpy
                  fontSize={52}
                  fontWeight="Bold"
                  style={{ lineHeight: "6.4rem" }}
                  color={colors.WHITE}
                >
                  Bluesales
                </Typograpy>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </Slider>
      {/* <FlexBox
        $ai="center"
        background={`url(${require("assets/img/rolling1.png")})`}
        style={{ backgroundSize: "cover" }}
        $isFullWidth
        height={53}
      >
        <Box
          $position={"absolute"}
          top={0}
          $left={0}
          width={"100%"}
          height={"100%"}
          // background={"#262A94"}
          // style={{ opacity: "0.6" }}
        ></Box>
        <motion.div
          ref={ref}
          variants={containerVariants}
          initial="hidden"
          animate={controls}
          style={{ width: `${MainMaxWidth}rem`, height: "100%" }}
        >
          <FlexBox
            $flexDirection="row"
            $jc="space-between"
            $isFullWidth
            $maxWidth={MainMaxWidth}
            height={"100%"}
          >
            <FlexBox style={{ alignSelf: "center" }}>
              <motion.div custom={0} variants={itemVariants}>
                <Typograpy
                  fontSize={27}
                  fontWeight="Regular"
                  style={{ lineHeight: "3.6rem" }}
                  color={colors.WHITE}
                >
                  A new innovation in{" "}
                  <Typograpy
                    fontSize={27}
                    fontWeight="Bold"
                    style={{ lineHeight: "3.6rem" }}
                    color={colors.WHITE}
                  >
                    sales
                  </Typograpy>
                </Typograpy>
              </motion.div>
              <motion.div custom={1} variants={itemVariants}>
                <Typograpy
                  fontSize={52}
                  fontWeight="Bold"
                  style={{ lineHeight: "6.4rem" }}
                  color={colors.WHITE}
                >
                  영업의 "판"을 바꾸다
                </Typograpy>
              </motion.div>
              <motion.div custom={1} variants={itemVariants}>
                <Typograpy
                  fontSize={48}
                  fontWeight="ExtraBold"
                  style={{ lineHeight: "6.4rem" }}
                  color={colors.WHITE}
                >
                  Blue
                  <Typograpy
                    fontSize={48}
                    fontWeight="Light"
                    style={{ lineHeight: "6.4rem" }}
                    color={colors.WHITE}
                  >
                    sales
                  </Typograpy>
                </Typograpy>
              </motion.div>
            </FlexBox>
          </FlexBox>
        </motion.div>
      </FlexBox> */}
      <FlexBox
        $ai="center"
        $py={12}
        // $bgcolor={colors.MAIN_INTRODUCE_BG}
        width={"100%"}
      >
        <Box $mb={2}>
          <IcSolution />
        </Box>
        <Typograpy
          fontStyle={fontStyle.title2}
          fontWeight="SemiBold"
          color={colors.MAIN_TITLE_FONT}
        >
          진짜 고객을 만드는 방법
        </Typograpy>
        <Box $my={1.2}>
          <Typograpy
            fontStyle={fontStyle.display2}
            fontWeight="ExtraBold"
            color={colors.MAIN_TITLE_FONT}
          >
            고객에게 직접 상품을 제안하는 세일즈 솔루션
          </Typograpy>
        </Box>
        <Box $mt={2} style={{ textAlign: "center" }}>
          <Typograpy
            fontStyle={fontStyle.title3}
            fontWeight="Regular"
            color={colors.MAIN_SUB_FONT}
            style={{ textAlign: "center" }}
          >
            강력한 고객 DB를 바탕으로 상품의 추천, 매칭, 제안까지
            <br /> 블루세일즈에서 단 몇번의 클릭으로 내 고객을 직접 만들어
            보세요
          </Typograpy>
        </Box>
      </FlexBox>
      {/* <MainProductList /> */}
      <FlexBox
        $ai="center"
        $py={12}
        // $bgcolor={colors.MAIN_INTRODUCE_BG}
        width={"100%"}
      >
        <Typograpy fontStyle={fontStyle.title1} fontWeight="ExtraBold">
          블루세일즈가 보유한 기획상품
        </Typograpy>
        <Box $mt={4} $maxWidth={"100%"}>
          <RollingBanner
            rolling={"left"}
            duration={20}
            bannerList={bannerList.map((item, index) => {
              return (
                <FlexBox
                  $ai="center"
                  $jc="center"
                  $bgcolor="#F3F4F6"
                  width={25}
                  height={18}
                  key={"product" + index}
                  $br={8}
                >
                  <Img src={item} width={"auto"} />
                </FlexBox>
              );
            })}
          />
        </Box>
      </FlexBox>
      <Box
        $btw={1}
        $bbw={1}
        $isFullWidth
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $btc={colors.DEFAULT_BORDER_COLOR}
      >
        {descriptionList.map((item, index) => {
          return (
            <Box $isFullWidth key={"description" + index}>
              <SalesDescription
                isBgGray={index % 2 === 1}
                desInfo={item}
                key={"mainDes" + index}
              />
              {index !== descriptionList.length - 1 && (
                <Divider variant="normal" width={"100%"} />
              )}
            </Box>
          );
        })}
      </Box>
      <FlexBox
        $ai="center"
        $py={12}
        // $bgcolor={colors.MAIN_INTRODUCE_BG}
        width={"100%"}
      >
        <ImgRank />
        <Box $mt={4} style={{ textAlign: "center" }}>
          <Typograpy
            style={{ whiteSpace: "pre-wrap" }}
            fontStyle={fontStyle.title1}
            fontWeight="ExtraBold"
            color={colors.BLACK}
          >
            {`블루세일즈와 동행한\n영업사원들의 후기`}
          </Typograpy>
        </Box>
        <Box $mt={4} $maxWidth={"100%"}>
          <RollingBanner
            rolling={"left"}
            duration={60}
            bannerList={dummyReviewList.map((item, index) => {
              return (
                <ReviewCard
                  key={"review" + index}
                  reviewInfo={item}
                ></ReviewCard>
              );
            })}
          />
          <RollingBanner
            rolling={"right"}
            duration={60}
            bannerList={dummyReviewList.map((item, index) => {
              return (
                <ReviewCard
                  key={"bottomReview" + index}
                  reviewInfo={item}
                ></ReviewCard>
              );
            })}
          />
        </Box>
      </FlexBox>
      {/* <MonthBestReview /> */}
    </FlexBox>
  );
};

export default MainTemplate;
