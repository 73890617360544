import styled from "styled-components";
import { colors } from "theme/colors";

export interface SwitchType {
  $checked: boolean;
  disabled?: boolean;
  size: "small" | "medium";
  $bgcolor?: string;
}

const SwitchWrapper = styled.div<SwitchType>`
  cursor: pointer;
  border-radius: 100px;
  background-color: ${({ $checked, $bgcolor }) =>
    $checked
      ? $bgcolor
        ? $bgcolor
        : colors.SWITCH_ON_BACK
      : colors.SWITCH_OFF_BACK};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: 0.4rem;
  /* position: relative;
   */
  width: ${({ size }) => (size === "small" ? "3.9rem" : "5.2rem")};
  display: flex;
  align-items: center;
  justify-content: ${({ $checked }) => ($checked ? "flex-end" : "flex-start")};
`;

const SwitchButton = styled.div<SwitchType>`
  width: ${({ size }) => (size === "small" ? "1.8rem" : "2.4rem")};
  height: ${({ size }) => (size === "small" ? "1.8rem" : "2.4rem")};
  background-color: #fff;
  border-radius: 100px;
`;

export { SwitchWrapper, SwitchButton };
