import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError, AxiosResponse } from "axios";
import { LocationListElType } from "page/SignUpPage";
import { GetPaymentListResponseData } from "./api.payment";
import { GetProposalListData } from "./api.userProposal";

//영업사원 정보가져오기 params
interface GetSallerInfoParams {
  salesmanager_id: number;
}

//유저 기본정보 (가입시 사용)
export interface SallerInfoData {
  salesmanager_name: string;
  kakao_id?: string;
  naver_id?: string;
  salesmanager_kakao_account?: string;
  salesmanager_account: string;
  salesmanager_password?: string;
  zip_code: number;
  salesmanager_address: string;
  salesmanager_address_detail: string;
  salesmanager_birthday: number;
  salesmanager_gender: string;
  sales_phone: string;
  email_address: string;
  sales_area: { local_id: number }[];
  company_name: string;
  company_zip_code: number;
  company_address: string;
  company_address_detail: string;
  created_at?: number;
  updated_at?: number;
  salesmanager_id?: number;
  sales_item: string;
}

//유저정보 수정 params type
interface UpdateSallerParams {
  salesmanager_name?: string;
  kakao_id?: string;
  naver_id?: string;
  salesmanager_account?: string;
  salesmanager_password?: string;
  zip_code?: number;
  salesmanager_address?: string;
  salesmanager_address_detail?: string;
  salesmanager_birthday?: number;
  salesmanager_gender?: string;
  sales_phone?: string;
  email_address?: string;
  sales_area?: { local_id: number }[];
  company_name?: string;
  company_address?: string;
  company_address_detail?: string;
  sales_item?: string;
}

//유저정보 (전체데이터)
export interface GetSallerData extends SallerInfoData {
  payment: GetPaymentListResponseData[];
  sales_proposal: string[];
  request_list: string[];
  user_proposal: GetProposalListData[];
}

//영업사원 정보입력 및 수정 response
export interface CreateSallerResponse {
  stateus: number;
  message: string;
  data: SallerInfoData;
}

//영업사원 단일정보 response
export interface GetSalesmanagerResponse {
  status: number; //응답
  message: string; //메세지
  data: GetSallerData;
}
//영업사원 리스트 response
export interface GetSallerListResponse {
  status: number; //응답
  message: string; //메세지
  data: GetSallerData[];
}

interface CreateSallerProps {
  onSuccess: (data: AxiosResponse<CreateSallerResponse>) => void;
  onError: (error: AxiosError) => void;
}

//영업사원 생성
export const useCreateSaller = ({ onSuccess, onError }: CreateSallerProps) =>
  useMutation<AxiosResponse<CreateSallerResponse>, AxiosError, SallerInfoData>({
    mutationKey: ["createSaller"],
    mutationFn: (params) => baseAxios.post("/salesmanager", params),
    onSuccess,
    onError,
  });

//영업사원 단일조회
export const useGetSallerInfo = () =>
  useMutation<GetSalesmanagerResponse, AxiosError, GetSallerInfoParams>({
    mutationKey: ["sallerInfo"],
    mutationFn: (params: GetSallerInfoParams) =>
      baseAxios
        .get(`/salesmanager/${params.salesmanager_id}`)
        .then((res) => res.data),
  });
//영업사원전체조회
export const useGetSallerList = () =>
  useQuery<GetSallerListResponse, AxiosError>({
    queryKey: ["salesmanagerList"],
    queryFn: () => baseAxios.get("/salesmanager").then((res) => res.data),
  } as UseQueryOptions<GetSallerListResponse, AxiosError>);

//공지사항 수정
export const useUpdateSalesmanager = ({
  salesmanager_id,
}: GetSallerInfoParams) =>
  useMutation<
    AxiosResponse<CreateSallerResponse>,
    AxiosError,
    UpdateSallerParams
  >({
    mutationKey: ["updateSalesmanager"],
    mutationFn: (params: UpdateSallerParams) =>
      baseAxios
        .put(`/salesmanager/${salesmanager_id}`, { ...params })
        .then((res) => {
          return res.data;
        }),
  });
