import { GetPaymentListResponseData } from "api/api.payment";
import {
  GetLocationResponseData,
  useGetLocation,
} from "api/blueButton/getLocationList";
import { SolidButton } from "components/molecules/button/SolidButton";
import Typograpy from "components/molecules/Typograpy";
import { SubscribePlanCard } from "components/organisms/card/SubscribePlanCard";
import { CancleSubscribeModal } from "components/organisms/modal/CancleSubscribeModal";
import { DatatablePagination } from "components/organisms/pagination/DatatablePagination";
import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useLocationStore } from "store/useLocationStore";
import { useUserInfoStore } from "store/userInfoStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { tableCustomStyles } from "theme/table.custom";
import { convertUnixDate, formatDate, NumberComma } from "utils/func";
import ModalPortal from "utils/Portal";

const SubscribeTemplate = () => {
  const [selSubscribe, setSelSubscribe] = React.useState("");

  const { payment } = useUserInfoStore((state) => state); //sales_area (영업지역 리스트)
  const { locationList } = useLocationStore((state) => state);

  //기존에 결제했던정보
  const [paymentInfo, setPaymentInfo] = React.useState<
    GetPaymentListResponseData[]
  >([]);

  const [cancleSubModal, setCancleSubModal] = React.useState({
    visible: false,
    sub_product_id: 0,
  });

  const columns: TableColumn<GetPaymentListResponseData>[] = [
    {
      name: "결제번호",
      sortable: false,
      cell: (row, index: number, column) => row.payment_id,
      // grow: 0.5,
      maxWidth: "12rem",
      center: true,
    },
    {
      name: "지역",
      selector: (row) => {
        const filterLocationInfo = locationList.filter(
          (el) => el.regionId === row.sub_product_information.local_id
        );

        if (filterLocationInfo.length > 0) {
          return filterLocationInfo[0].regionName;
        } else {
          return "";
        }
      },
      sortable: false,
    },
    {
      name: "상품타입",
      selector: (row) => {
        if (row.sub_product_information.product_type === "insurances") {
          return "보험";
        } else if (row.sub_product_information.product_type === "finance") {
          return "금융";
        } else {
          return "프리미엄";
        }
      },
      sortable: true,
    },
    {
      name: "구독시작일",
      selector: (row) => convertUnixDate(row.created_at || 0),
      sortable: true,
    },
    {
      name: "구독종료일",
      selector: (row) => {
        const startDate = new Date(convertUnixDate(row.created_at || 0));
        const endDate = new Date(startDate);
        endDate.setMonth(
          startDate.getMonth() + row.sub_product_information.validity_term
        );
        endDate.setDate(endDate.getDate() - 1);
        return formatDate(endDate);
      },
      sortable: true,
    },
    {
      name: "연간구독료",
      selector: (row) => NumberComma(row.final_price) + "원",
      sortable: false,
    },
    {
      name: "",
      // selector: (row) => row.toss_id,
      sortable: false,
      // grow: 2,
      cell: (row) => {
        return (
          <SolidButton
            buttonLabel="구독취소"
            $br={8}
            onClick={() => {
              setCancleSubModal({
                visible: true,
                sub_product_id: row.sub_product_information.sub_product_id,
              });
            }}
          />
        );
      },
    },
  ];

  React.useEffect(() => {
    if (payment.length > 0) {
      setPaymentInfo([...payment]);
    }
  }, [payment]);

  return (
    <FlexBox $isFullWidth $ai="center">
      <ModalPortal>
        <CancleSubscribeModal
          visible={cancleSubModal.visible}
          modalOff={() => {
            setCancleSubModal({ visible: false, sub_product_id: 0 });
          }}
          sub_product_id={cancleSubModal.sub_product_id}
        />
      </ModalPortal>
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc="space-between"
        $ai="center"
        height={6}
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $pl={2}
        $pr={4}
      >
        <Typograpy
          fontStyle={fontStyle.body1_normal}
          fontWeight="SemiBold"
          color={colors.SALLER_SUBSIDE_TITLE}
        >
          구독&결제 정보
        </Typograpy>
      </FlexBox>
      <Box $isFullWidth $pa={2}>
        <DataTable
          columns={columns}
          data={[...paymentInfo.reverse()]}
          defaultSortFieldId={1}
          pagination
          paginationComponent={DatatablePagination}
          customStyles={tableCustomStyles}
        />
      </Box>

      <FlexBox $isFullWidth $my={4} $maxWidth={80}>
        {/* <Typograpy
          fontStyle={fontStyle.body1_normal}
          color={colors.DEFAULT_FONT_COLOR}
        >
          이용중인 상품
        </Typograpy>
        <FlexBox $flexDirection="row" $isFullWidth $gap={1.2} $mt={2}>
          {subscribeList.map((item, index) => {
            return (
              <SubscribePlanCard
                subscribeInfo={item}
                key={"subscribe" + index}
                selSubscribe={selSubscribe}
                setSelSubscribe={setSelSubscribe}
              />
            );
          })}
        </FlexBox>
        <Box $isFullWidth $mt={4}>
          <Divider
            variant="thick"
            width={"100%"}
            height={0.2}
            $bgcolor={colors.MAIN}
          />
        </Box> */}
      </FlexBox>
    </FlexBox>
  );
};

export default SubscribeTemplate;
