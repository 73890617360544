import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { PageType } from "api/commonType";
import axios, { AxiosError, AxiosResponse } from "axios";
import { TokenDataType } from "./SallerLogin";

export interface CheckSNSCodeParams {
  rescode: string | null;
  restype: string | null;
}

export type SociaDataType = {
  social_id?: number;
  type?: "kakao" | "naver";
  token?: string;
};

export interface CheckSNSCodeResponse {
  success: boolean;
  msg: string;
  data: SociaDataType;
}

// 영업자 - 고객정보 - 상품정보 (보험,금융)
export const useCheckSNSCode = (params: CheckSNSCodeParams) =>
  useQuery<CheckSNSCodeResponse, AxiosError>({
    queryKey: ["checkSnsCode"],
    queryFn: () => {
      if (params.rescode && params.restype) {
        return baseAxios
          .get(
            `/auth/social/callback?code=${params.rescode}&type=${params.restype}`
          )
          .then((res) => res.data);
      }
    },
  } as UseQueryOptions<CheckSNSCodeResponse, AxiosError>);
