import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  RectangleProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { customLegend } from "./CustomLegend";
import { NumberComma } from "utils/func";

interface BarChartComponentPorpsType {
  dataList: { [key: string]: number | string }[];
  dataKeyList: { dataKey: string; fill: string }[];
}

// Custom Bar component with border-radius
const CustomBar = (props: RectangleProps) => {
  const { x, y, width, height, fill } = props;
  const radius = 4; // 원하는 border-radius 값

  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      rx={radius} // border-radius 적용
      ry={radius} // border-radius 적용
    />
  );
};

export const BarChartComponent: React.FC<BarChartComponentPorpsType> =
  React.memo(({ dataList, dataKeyList }) => {
    return (
      // <ResponsiveContainer width="100%" height="100%">
      //   <BarChart
      //     style={{ width: "100%", height: "100%" }}
      //     data={dataList}
      //     margin={{
      //       top: 5,
      //       right: 30,
      //       left: 20,
      //       bottom: 5,
      //     }}
      //   >
      //     <CartesianGrid strokeDasharray="3 3" />
      //     <XAxis dataKey="name" />
      //     <YAxis />
      //     <Tooltip />
      //     <Legend />
      //     {dataKeyList.map((item, index) => {
      //       <Bar
      //         dataKey={item.dataKey}
      //         fill={item.fill}
      //         key={"barchart-" + item.dataKey + index}
      //       />;
      //     })}
      //   </BarChart>
      // </ResponsiveContainer>

      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={dataList}
          margin={{
            top: 5,
            right: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="0" vertical={false} />
          <XAxis
            dataKey="name"
            tick={{ fill: "#616161", fontWeight: "Regular", fontSize: "1rem" }}
          />
          <YAxis
            onError={() => {}}
            tickFormatter={(value) => NumberComma(Number(value))}
          />
          <Tooltip formatter={(value) => NumberComma(Number(value)) + "명"} />
          <Legend align="left" content={customLegend} />
          {dataKeyList.map((item, index) => {
            return (
              <Bar
                key={"barchart" + item.dataKey + index}
                dataKey={item.dataKey}
                fill={item.fill}
                shape={<CustomBar />}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    );
  });
