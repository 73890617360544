import { useMutation, useQuery } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError, AxiosResponse } from "axios";

export interface UploadProposalFileParams {
  fileList: File[];
  fileNameList: string[];
}

export interface UploadProposalFileResponse {
  status: number; //응답
  message: string; //메세지
  data: { file_name: string; file_url: string }[];
}

//영업제안서 생성
export const useUploadProposalFile = () =>
  useMutation<UploadProposalFileResponse, AxiosError, UploadProposalFileParams>(
    {
      mutationKey: ["salesProposal"],
      mutationFn: (params: UploadProposalFileParams) => {
        const formData = new FormData();
        params.fileList.forEach((file) => {
          formData.append("files", file);
        });
        params.fileNameList.forEach((fileName) => {
          formData.append("file_names", fileName);
        });

        return baseAxios
          .post("/proposal-file/upload/multi", formData, {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          })
          .then((res) => res.data);
      },
    }
  );
