import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { PageType } from "api/commonType";
import axios, { AxiosError, AxiosResponse } from "axios";
import { base64Encode } from "utils/func";

export interface GetLocationResponseData {
  regionId: number;
  regionName: string;
}

export interface GetLocationResponse {
  success: boolean;
  response: GetLocationResponseData[];
  error: null | string;
  page: PageType;
}

// 영업자 - 고객정보 - 상품정보 (보험,금융)
export const useGetLocation = () => {
  return useQuery<GetLocationResponse, AxiosError>({
    queryKey: ["blueBtnLocation"],
    queryFn: () => {
      return baseAxios
        .get("/blue-button-api/fetch/regions")
        .then((res) => res.data.data);
    },
  } as UseQueryOptions<GetLocationResponse, AxiosError>);
};
