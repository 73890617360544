import { useRefreshToken } from "api/auth/RefreshToken";
import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { useLocation } from "react-router";
import { useLoadingStore } from "store/useLoadingStore";
import { useSubProductPaymentStore } from "store/useSubProductPaymentStore";
import { colors } from "theme/colors";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";

export const PaymentFail = () => {
  const location = useLocation();
  const { state } = location;
  const { updateLoading } = useLoadingStore((state) => state);

  const code = new URL(window.location.href).searchParams.get("code");
  const message = new URL(window.location.href).searchParams.get("message");

  React.useEffect(() => {
    updateLoading(false);
  }, []);

  return (
    <FlexBox $isFullWidth $ai={"center"} $minHeight={70}>
      <FlexBox
        $isFullWidth
        $py={4}
        $ai="center"
        $jc="center"
        $bgcolor={colors.USER_HEADER_BG}
      >
        <Typograpy
          fontSize={45}
          fontWeight="Bold"
          color={colors.WHITE}
          style={{ lineHeight: "4.8rem" }}
        >
          결제실패
        </Typograpy>
      </FlexBox>
      <FlexBox $mt={3} $isFullWidth $ai={"center"}>
        <Typograpy fontSize={24} fontWeight="Bold">
          결제에{" "}
          <Typograpy fontSize={24} fontWeight="Bold" color={colors.RED}>
            실패
          </Typograpy>
          하였습니다.
        </Typograpy>
        {state && (
          <FlexBox $ai={"center"}>
            <Box $mt={1.6}>
              <Typograpy fontSize={16} color={colors.DEFAULT_FONT_COLOR}>
                오류코드 : {state.code}
              </Typograpy>
            </Box>
            <Box $mt={0.4}>
              <Typograpy fontSize={16} color={colors.DEFAULT_FONT_COLOR}>
                {state.message}
              </Typograpy>
            </Box>
          </FlexBox>
        )}
        {code && message && (
          <FlexBox $ai={"center"}>
            <Box $mt={1.6}>
              <Typograpy fontSize={16} color={colors.DEFAULT_FONT_COLOR}>
                오류코드 : {code}
              </Typograpy>
            </Box>
            <Box $mt={0.4}>
              <Typograpy fontSize={16} color={colors.DEFAULT_FONT_COLOR}>
                {message}
              </Typograpy>
            </Box>
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
};
