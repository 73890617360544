import { useMutation, useQuery } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError, AxiosResponse } from "axios";

interface DuplicateCheckParams {
  admin_account?: string; //관리자 아이디
  admin_email?: string; //관리자 이메일
  admin_phone?: string; //관리자 휴대폰
  user_account?: string; //유저 아이디
  user_phone?: string; //유저 휴대폰
  salesmanager_account?: string; //설계사 아이디
  email_address?: string; //설계사 이메일
  sales_phone?: string; //설계사 휴대폰
  type: "ADMIN" | "SALESMANAGER"; //회원가입 타입
}

export interface DuplicateCheckResponse {
  status: number; //응답
  message: string; //메세지
  data: boolean;
}

//중복검사 모듈
export const useDuplicateCheck = () =>
  useMutation<
    AxiosResponse<DuplicateCheckResponse>,
    AxiosError,
    DuplicateCheckParams
  >({
    mutationKey: ["duplicateCheck"],
    mutationFn: (params: DuplicateCheckParams) =>
      baseAxios.post("/auth/duplicate/check", params).then((res) => {
        return res;
      }),
  });
