import {
  StyleSelectBox,
  StyleSelectBoxWrapper,
  StyleSelectOptBox,
  StyleSelectOption,
} from "components/atoms/selectbox/selectbox";
import React, { useRef } from "react";
import Typograpy from "../Typograpy";
import { colors } from "theme/colors";
import { IcGraySelArrow, IcSelArrow } from "components/atoms/icon/icon";
import { Box } from "theme/globalStyle";
import { CSSProperties } from "styled-components";
import { Label } from "../label/Label";

interface SelectBoxPropsType {
  placeholder: string;
  optList: string[];
  selOpt?: string;
  onChangeValue: (value: string, key?: string) => void;
  stateKey?: string;
  fontStyle?: string;
  boxStyle?: CSSProperties;
  label?: string;
  optFooterLabel?: string;
  footerLabel?: string;
  disable?: boolean;
}

export const SelectBox: React.FC<SelectBoxPropsType> = ({
  placeholder,
  optList,
  selOpt,
  stateKey,
  onChangeValue,
  fontStyle,
  boxStyle,
  label,
  optFooterLabel,
  footerLabel,
  disable,
}) => {
  const [isOptOpen, setIsOptOpen] = React.useState(false);

  const optRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (optRef !== null) {
        if (optRef.current && !optRef.current.contains(e.target as Node)) {
          setIsOptOpen(false);
        }
      }
    };
    window.addEventListener("mousedown", handleClick);

    return () => window.removeEventListener("mousedown", handleClick);
  }, [optRef]);

  return (
    <Box
      style={{ ...boxStyle, whiteSpace: "nowrap" }}
      width={"100%"}
      $position="relative"
    >
      {label && <Label label={label} />}
      <StyleSelectBoxWrapper>
        <StyleSelectBox
          $gap={1.6}
          onClick={() => {
            if (!disable) {
              setIsOptOpen(!isOptOpen);
            }
          }}
          $disable={disable}
        >
          {selOpt ? (
            <Typograpy
              fontStyle={fontStyle}
              fontSize={16}
              color={colors.DEFAULT_FONT_COLOR}
            >
              {selOpt}
              {footerLabel}
            </Typograpy>
          ) : (
            <Typograpy
              fontStyle={fontStyle}
              fontSize={16}
              color={colors.SELCTBOX_PLACEHOLDER}
            >
              {placeholder}
            </Typograpy>
          )}

          {!disable && (selOpt ? <IcSelArrow /> : <IcGraySelArrow />)}
        </StyleSelectBox>
      </StyleSelectBoxWrapper>
      {isOptOpen && optList.length > 0 && (
        <StyleSelectOptBox ref={optRef}>
          {optList.map((item, index) => {
            return (
              <StyleSelectOption
                $isSel={item === selOpt}
                onClick={() => {
                  if (stateKey) {
                    onChangeValue(item, stateKey);
                  } else {
                    onChangeValue(item);
                  }
                  setIsOptOpen(false);
                }}
                key={"sel" + index}
              >
                <Typograpy
                  fontSize={16}
                  color={
                    selOpt === item
                      ? colors.SELECT_FONT
                      : colors.DEFAULT_FONT_COLOR
                  }
                  fontStyle={fontStyle}
                >
                  {item}
                  {optFooterLabel}
                </Typograpy>
              </StyleSelectOption>
            );
          })}
        </StyleSelectOptBox>
      )}
    </Box>
  );
};
