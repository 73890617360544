import {
  IcDocumentOff,
  IcDocumentOn,
  IcHomeOff,
  IcHomeOn,
  IcPeopleOff,
  IcPeopleOn,
  IcTeamOff,
  IcTeamOn,
} from "components/atoms/icon/icon";
import Typograpy from "components/molecules/Typograpy";
import { ToggleSubSideBarType } from "page/static/SallerStaticArea";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { AdminSubMenuListType } from "utils/list";

interface MainSideBarPropsType {
  toggleSubSideBar: ToggleSubSideBarType;
  setToggleSubSideBar: ({ visible, label }: ToggleSubSideBarType) => void;
  adminType?: "admin" | "sales";
}

export const MainSideBar: React.FC<MainSideBarPropsType> = ({
  toggleSubSideBar,
  setToggleSubSideBar,
  adminType = "admin",
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.replace(
    adminType === "admin" ? /^\/admin\/?/ : /^\/saller\/?/,
    ""
  );

  const menuList = [
    // {
    //   label: "홈",
    //   element: currentPath === "home" ? <IcHomeOn /> : <IcHomeOff />,
    //   path: "home",
    // },
    {
      label: "고객리스트",
      element: currentPath === "customer" ? <IcTeamOn /> : <IcTeamOff />,
      path: "customer",
    },
    {
      label: "제안서",
      element:
        currentPath === "proposal" ? <IcDocumentOn /> : <IcDocumentOff />,
      path: "proposal",
    },
    {
      label: "내정보",
      element: currentPath === "myInfo" ? <IcPeopleOn /> : <IcPeopleOff />,
      path: "myInfo",
    },
  ];

  const companyAdminMenu = [
    {
      label: "홈",
      element: currentPath === "home" ? <IcHomeOn /> : <IcHomeOff />,
      path: "home",
    },
  ];

  return (
    <FlexBox $flexDirection="row">
      <FlexBox
        $flex={1}
        height={"100%"}
        $ml={0.4}
        $py={1.5}
        $ai="center"
        $gap={2}
      >
        {(adminType === "admin" ? companyAdminMenu : menuList).map(
          (item, index) => {
            return (
              <FlexBox
                key={"mainList" + index}
                $isFullWidth
                $flexDirection={"row"}
                $ai="center"
              >
                {currentPath === item.path && (
                  <Box
                    width={0.2}
                    height={"100%"}
                    $br={20}
                    $bgcolor={colors.MAIN}
                    $position={"absolute"}
                    $left={0}
                  />
                )}
                <FlexBox
                  $isFullWidth
                  $px={1.5}
                  $minWidth={6}
                  $ai="center"
                  key={"memuList" + index}
                  $isPointer
                  onClick={() => {
                    if (currentPath === item.path) {
                      setToggleSubSideBar({
                        ...toggleSubSideBar,
                        visible: !toggleSubSideBar.visible,
                      });
                    } else {
                      setToggleSubSideBar({ visible: true, label: item.label });
                    }

                    navigate(item.path);
                  }}
                >
                  {item.element}
                  <Box $mt={0.4}>
                    <Typograpy
                      style={{ whiteSpace: "nowrap" }}
                      fontStyle={fontStyle.caption2}
                      color={"#616161"}
                    >
                      {item.label}
                    </Typograpy>
                  </Box>
                </FlexBox>
              </FlexBox>
            );
          }
        )}
      </FlexBox>
      <Box
        className={"main-side-gradient"}
        width={0.8}
        height={"100%"}
        $position={"absolute"}
        $right={0}
      />
    </FlexBox>
  );
};
