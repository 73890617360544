import React from "react";

import Modal from "react-modal";
import DaumPostcode from "react-daum-postcode";
import { CommonModalType } from "./commonModalType";
import useScrollLock from "hooks/useScrollLock";
import { CommonModal } from "./CommonModal";
interface PostCodeModalProps extends CommonModalType {
  onChange: (data: any) => void;
}

export const PostCodeModal: React.FC<PostCodeModalProps> = ({
  visible,
  modalOff,
  onChange,
}) => {
  //modal 활성화시 body 스크롤 lock
  useScrollLock(visible);

  return (
    <CommonModal
      modalOff={modalOff}
      visible={visible}
      customStyle={{
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.6)",
        },
        content: {
          width: "70%",
          height: "70%",
          position: "relative",
          inset: 0,
        },
      }}
    >
      <DaumPostcode style={{ height: "100%" }} onComplete={onChange} />
    </CommonModal>
  );
};
