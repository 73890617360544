import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { fontStyle } from "theme/fontStyle";
import { FlexBox } from "theme/globalStyle";

export const RefundAgree = () => {
  return (
    <FlexBox $gap={1.2} $mb={4}>
      <Typograpy fontStyle={fontStyle.title3} fontWeight="Bold">
        취소 및 환불, 청약철회 규정
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body1_reading} fontWeight="Bold">
        제 1 조 [관계 법령상 환불 정책]
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사의 본 약관상 환불규정 등은 “온라인 디지털콘텐츠산업 발전법”, “전자상거래 등에서의 소비자보호에 관한 법률” 등을 준수합니다.
(2) 전 항의 관계법령에 따른 소비자의 청약철회가 불가능한 경우는 다음과 같습니다.
– 「문화산업진흥 기본법」 제2조 제5호의 디지털콘텐츠의 제공이 개시된 경우.
(3) 「문화산업진흥 기본법」 제2조 제5호에 따라 블루세일에서 제공하는 맞춤형 비식별 고객 데이터는 디지털콘텐츠에 해당합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body1_reading} fontWeight="Bold">
        제 2 조 [거래의 취소, 청약철회, 환불규정]
      </Typograpy>
      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        1. [목적]
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`블루라이언스(이하 “회사”라 칭함), 블루세일 서비스(이하 “서비스”라 칭함)의 거래의 취소, 청약철히, 환불규정을 정함에 목적이 있습니다.

(1) 전자상거래법 제17조(청약철회 등)는 소비자가 재화 등의 공급을 받거나 공급이 개시된 날부터 7일 이내 청약 철회가 가능합니다.

(2) 회사의 서비스는 데이터의 용역과 디지털콘텐츠로 이루어져 있으며, 이는 법률 제17조(청약철회등) ②의 5(용역 또는 「문화산업진흥 기본법」 제2조제5호의 디지털콘텐츠의 제공이 개시된 경우. 다만, 가분적 용역 또는 가분적 디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한 부분에 대하여는 그러하지 아니하다.)에 따라 서비스가 개시된 이후에는 청약철회를 진행할 수 없습니다. 단, (1)항의 전자상거래법에 의거 7일 이내 청약철회만 인정됩니다.

(3) 회사의 서비스 구매 확정은 회사의 블루버튼 서비스에서 비 식별된 고객데이터 불러오기 적용 후, 서비스의 지속된 미응답이 발생할 경우, 이용자는 고객 상담을 통하여 거래 취소를 요청할 수 있습니다. 회사는 해당 요청에 대한 합당성을 검토한 뒤 관련 조치를 취하고, 이에 대한 내용을 이용자에게 전달합니다.

(4) 서비스 제공이 예상치 못한 사유로 인해 정상적인 서비스 공급이 어렵다고 판단할 경우, 회사에 의한 거래 취소가 진행될 수 있고, 이 때 환불이 적용됩니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        2. [환불 규정]
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사가 제공하는 서비스는 ‘전자상거래 등에서의 소비자보호에 관한 법률’의 청약철회 및 환불 규정을 따르며, 환불에 관한 세부 규정은 아래와 같습니다

(1) 회사의 서비스는 법률 제17조(청약철회등) ②의 5에 의거하여 서비스가 개시된 이후7일 이내 청약철회가 신청된 때에는 청약철회가 가능하고, 환불이 이뤄집니다. 청약개시일로부터7일이 지나면 청약철회가 불가함으로써 그에 따른 환불 진행은 어렵습니다.

(2) 회사의 서비스는 소비자의 주문에 따라 개별적으로 생산되는 상품 또는 이와 유사한 상품에서 나타나는 특성을 지니고 있으므로 회사의 서비스가 제공한 비실명 고객 데이터 서비스에 대한 수신이 발생한 이후에는 거래 취소 및 환불이 불가합니다.

(4) 예상치 못한 사유로 인해 정상적인 서비스 공급이 어렵다고 판단한 회사의 서비스는 부득이 거래 취소를 진행할 경우, 고객의 결제 건은 취소 처리가 되며, 해당 결제 금액은 환불처리 됩니다.(진행상황에 따라 영업일 기준 2~7일 가량 소요될 수 있습니다.)

(5) 회사의 서비스 내 결제시스템을 거치지 안고서 직원과 구매자간의 직거래로 발생한 금전 거래 건에 대하여 회사는 일체의 책임을 지지 않으며, 환불의 의무를 지지 않습니다. 안전한 거래를 위하여 이용 약관 및 블루세일의 이용약관을 준수하여 주시기 바랍니다.

(6) 환불 처리 시 수수료 등 별도로 감액되는 금액 없이 구매 금액 전액이 환불 처리됩니다.
– 고객 데이터 자료를 열람 혹은 서비스에서 제공한 경우 해당 회사의 서비스를 이용한 
  것으로 간주합니다.
– 이외 회사의 서비스가 진행한 상황이 확인되면 환불이 불가능합니다.

(7) 기간이 지정된 유료 서비스의 제공 기간이 만료 되었을 경우에는 환불이 이루어지지 않습니다.

(8) “이용자”의 할인에 의해 서비스 대금을 결제하였을 경우, 서비스 대금 반환금액의 기준은 할인이 적용되어 실제 결제한 금액에 의하여 정합니다.

(9) 환불은 신용카드 결제, 휴대폰 결제, 계좌이체 등 “회원”이 결제한 수단과 동일한 방식으로 함을 원칙으로 합니다. 단, 기술적으로 불가능한 경우에는 “회사”는 “회원”에게 합리적인 환불방법을 정하여 통지할 수 있습니다. “회원”이 해당 방법에 협조하지 않아 발생하는 환불의 지연 등에 관한 모든 불이익의 책임은 “회원”에게 있습니다.

a. “이용자”는 서비스를 카드로 결제한 경우, 서비스 시작 전 환불 요청 시에는 회사의 서비스에 마련된 ‘결제 취소’ 창구를 통해, 서비스 시작 후 환불 요청시에는 info@bluelions.kr로 환불 요청 의사를 표시하고 환불 절차에 필요한 정보를 전송해야 합니다.`}
      </Typograpy>
    </FlexBox>
  );
};
