import React from "react";
import { Box, FlexBox } from "theme/globalStyle";
import { DashboardTitle } from "..";
import Typograpy from "components/molecules/Typograpy";
import { fontStyle } from "theme/fontStyle";
import { colors } from "theme/colors";
import { BarChartComponent } from "components/organisms/chart/BarChart";
import { convertUnixDate, generateInitialJoinData, getDate } from "utils/func";
import { SelectBox } from "components/molecules/selectbox/SelectBox";
import { Input } from "components/atoms/input/input";
import { useSnackbarStore } from "store/snackbarStore";
import { LineChartComponent } from "components/organisms/chart/LineChart";
import { useGetPaymentList } from "api/api.payment";

export const PaymentStatistics: React.FC = () => {
  const showSnackBar = useSnackbarStore((state) => state.showSnackbar);
  const { mutate: paymentMutate, data: paymentData } = useGetPaymentList();

  const [monthJoinData, setMonthJoinData] = React.useState<
    { [x: string]: string | number; name: string }[]
  >([...generateInitialJoinData("연간")]);

  const [filterType, setFilterType] = React.useState<"연간" | "월간" | "일간">(
    "연간"
  );
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");

  //월별 가입자 통계 (기준은 결제로 잡음)
  function monthJoinDataSetting(
    value: "연간" | "월간" | "일간",
    startDate?: string,
    endDate?: string
  ) {
    if (!paymentData) return;
    let settingMonthData = [
      ...generateInitialJoinData(value, startDate, endDate),
    ];
    paymentData.data.forEach((item, index) => {
      const [year, month, day] = convertUnixDate(item.created_at || 0).split(
        "."
      );
      const indexOfMonthData = settingMonthData.findIndex((el) => {
        const [graphYear, graphMonthText] = el.name.split("년");
        if (value === "연간") {
          return Number(graphYear) === Number(year);
        } else if (value === "월간") {
          const [graphMonth, graphDayText] = graphMonthText.split("월");
          return (
            Number(graphYear) === Number(year) &&
            Number(graphMonth) === Number(month)
          );
        } else if (value === "일간") {
          const [graphMonth, graphDayText] = graphMonthText.split("월");
          const [graphDay] = graphDayText.split("일");
          return (
            Number(graphYear) === Number(year) &&
            Number(graphMonth) === Number(month) &&
            Number(graphDay) === Number(day)
          );
        }
      });

      if (indexOfMonthData !== -1) {
        switch (item.sub_product_information.product_type) {
          case "insurances":
            settingMonthData[indexOfMonthData]["보험"] =
              (settingMonthData[indexOfMonthData]["보험"] as number) +
              item.final_price;
            break;
          case "finance":
            settingMonthData[indexOfMonthData]["금융"] =
              (settingMonthData[indexOfMonthData]["금융"] as number) +
              item.final_price;
            break;
          case "premium":
            settingMonthData[indexOfMonthData]["프리미엄"] =
              (settingMonthData[indexOfMonthData]["프리미엄"] as number) +
              item.final_price;
            break;
        }
        settingMonthData[indexOfMonthData]["합계"] =
          (settingMonthData[indexOfMonthData]["합계"] as number) +
          item.final_price;
      }
    });

    setMonthJoinData([...settingMonthData]);
  }

  const dateHandler = (value: string, type: "start" | "end") => {
    if (type === "start") {
      if (endDate !== "") {
        if (new Date(value) > new Date(endDate)) {
          showSnackBar("시작일보다 종료일이 빠를 수 없습니다.");
        } else {
          setStartDate(value);
          monthJoinDataSetting(filterType, value, endDate);
        }
      }
    } else {
      if (startDate !== "") {
        if (new Date(startDate) > new Date(value)) {
          showSnackBar("시작일보다 종료일이 빠를 수 없습니다.");
        } else {
          setEndDate(value);
          monthJoinDataSetting(filterType, startDate, value);
        }
      }
    }
  };

  React.useEffect(() => {
    paymentMutate({});
  }, []);

  React.useEffect(() => {
    if (filterType === "일간") {
      setStartDate(getDate().prevWeekHipon);
      setEndDate(getDate().hiponText);

      monthJoinDataSetting(
        filterType,
        getDate().prevWeekHipon,
        getDate().hiponText
      );
    }
  }, [filterType]);

  React.useEffect(() => {
    if (!paymentData) return;

    if (paymentData.status === 200) {
      monthJoinDataSetting("연간");
    }
  }, [paymentData]);

  return (
    <FlexBox $isFullWidth>
      <FlexBox
        $flexDirection="row"
        $ai={"stretch"}
        $isFullWidth
        $gap={1.6}
        $mt={2}
        // height={"45rem"}
        height={45}
      >
        <Box $flex={2} $isFullWidth $shadowType="strong" $pa={2} $br={8}>
          <FlexBox $flexDirection="row" $ai={"center"} $jc={"space-between"}>
            <FlexBox $flexDirection="row" $ai={"center"} $jc={"space-between"}>
              <FlexBox>
                <DashboardTitle title={"매출 통계"} />
                <Typograpy
                  fontStyle={fontStyle.caption1}
                  color={colors.SALLER_DESCRIPTION}
                >
                  Description
                </Typograpy>
              </FlexBox>
            </FlexBox>
            <FlexBox $mt={1.2} $flexDirection="row" $ai={"flex-end"} $gap={1.2}>
              <SelectBox
                optList={["연간", "월간", "일간"]}
                selOpt={filterType}
                onChangeValue={(value: string) => {
                  if (
                    value === "연간" ||
                    value === "월간" ||
                    value === "일간"
                  ) {
                    setFilterType(value);
                    monthJoinDataSetting(value, startDate, endDate);
                  }
                }}
                placeholder="선택하세요"
                boxStyle={{ width: "auto" }}
                fontStyle="font-size:1.3rem;"
              />
              {filterType === "일간" && (
                <FlexBox $flexDirection="row" $ai={"flex-end"} $gap={1.2}>
                  <Input
                    value={startDate}
                    style={{ width: "auto" }}
                    type={"date"}
                    onChange={(e) => {
                      dateHandler(e.target.value, "start");
                    }}
                    max={endDate}
                  />
                  <Input
                    value={endDate}
                    style={{ width: "auto" }}
                    type={"date"}
                    onChange={(e) => {
                      dateHandler(e.target.value, "end");
                    }}
                    min={startDate}
                  />
                </FlexBox>
              )}
            </FlexBox>
          </FlexBox>
          <FlexBox $ai={"center"} height={"100%"} $isFullWidth>
            <Box width={"100%"} height={"80%"} $mt={2}>
              <LineChartComponent
                dataList={monthJoinData}
                dataKeyList={[
                  { dataKey: "합계", fill: colors.MAIN },
                  { dataKey: "보험", fill: "#96C6FA" },
                  { dataKey: "금융", fill: "#479EF5" },
                  { dataKey: "프리미엄", fill: "#115EA3" },
                ]}
              />
            </Box>
          </FlexBox>
        </Box>
      </FlexBox>
    </FlexBox>
  );
};
