import { GetPaymentListResponseData } from "api/api.payment";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface SubProductPaymentStoreType {
  paymentSubProductList: GetPaymentListResponseData[];
  updatePaymentSubProductList: (info: GetPaymentListResponseData[]) => void;
  clearPaymentSubProductList: () => void;
}

export const useSubProductPaymentStore = create<SubProductPaymentStoreType>()(
  persist(
    (set) => ({
      paymentSubProductList: [],
      updatePaymentSubProductList: (info: GetPaymentListResponseData[]) =>
        set({ paymentSubProductList: [...info] }),
      clearPaymentSubProductList: () => set({ paymentSubProductList: [] }),
    }),
    {
      name: "subPaymentList", // Unique name for the storage
      getStorage: () => sessionStorage, // Use sessionStorage for persistence
    }
  )
);
