import { IcClose } from "components/atoms/icon/icon";
import { Divider } from "components/molecules/divider/Divider";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { TableContent } from "components/molecules/table/TableContent";
import { TableTitle } from "components/molecules/table/TableTitle";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Switch } from "components/molecules/switch/Switch";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { color } from "framer-motion";
import { SolidButton } from "components/molecules/button/SolidButton";
import { useQueryClient } from "@tanstack/react-query";
import draftjsToHtml from "draftjs-to-html";
import { useAdminInfoStore } from "store/useAdminInfoStore";
import { useSnackbarStore } from "store/snackbarStore";
import { AxiosError } from "axios";
import ModalPortal from "utils/Portal";
import { AlertModal } from "components/organisms/modal/AlertModal";
import { convertToRaw, EditorState } from "draft-js";
import { DetailTableRow } from "components/organisms/table/DetailTableRow";
import {
  CreateNoticeResponse,
  GetNoticeListResponse,
  useCreateNotice,
} from "api/api.notice";

interface NoticeCreateProps {
  modalOff: () => void;
}

export const NoticeCreate: React.FC<NoticeCreateProps> = ({ modalOff }) => {
  const queryClient = useQueryClient();

  const noticeList: any = queryClient.getQueriesData({
    queryKey: ["noticeList"],
  });

  const { showSnackbar } = useSnackbarStore((state) => state);
  const { admin_id } = useAdminInfoStore((state) => state);
  const data = useAdminInfoStore((state) => state);

  const [noticeInfo, setNoticeInfo] = React.useState({
    notice_name: "",
    is_show: "Y",
  });

  const [editorState, setEditorState] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const [lastNoticeId, setLastNoticeId] = React.useState(0);

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });
  const [inputError, setInputError] = React.useState({
    notice_name: false,
    notice_content: false,
  });

  function noticeInfoHandler(value: string, key?: string) {
    if (key) {
      setInputError({ notice_name: false, notice_content: false });
      setNoticeInfo((prev) => ({ ...prev, [key]: value }));
    }
  }

  const { mutate, isPending } = useCreateNotice({
    onSuccess: noticeCreateSuccess,
    onError: noticeCreateFail,
  });

  //editor 공백만 있을경우 및 trim처리
  const isEditorEmpty = (): boolean => {
    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText(); // 실제 텍스트 추출
    return !plainText.trim(); // 공백만 있는지 확인
  };

  const createNoticeVerify = () => {
    if (noticeInfo.notice_name === "") {
      setInputError((prev) => ({ ...prev, notice_name: true }));
      return false;
    } else if (isEditorEmpty()) {
      setInputError((prev) => ({ ...prev, notice_content: true }));
    } else {
      return true;
    }
  };

  const createNoticeHandler = () => {
    if (createNoticeVerify()) {
      const params = {
        admin_id,
        notice_name: noticeInfo.notice_name,
        notice_content: draftjsToHtml(
          convertToRaw(editorState.getCurrentContent())
        ).replaceAll("<p></p>", "<br />"),
        is_show: noticeInfo.is_show,
      };

      mutate(params);
    }
  };

  //공지사항 등록 성공
  function noticeCreateSuccess(res: CreateNoticeResponse) {
    showSnackbar("공지사항 등록이 완료되었습니다.");
    modalOff();
  }

  //공지사항 등록 실패
  function noticeCreateFail(error: AxiosError) {
    setAlertModal({
      visible: true,
      msg: `공지사항 등록에 실패하였습니다. \n ${error.message}`,
      type: "",
    });
  }

  React.useEffect(() => {
    if (noticeList[0]) {
      const queryData: GetNoticeListResponse = noticeList[0][1];

      const maxObjArr = queryData.data.reduce((prev, value) => {
        return prev.admin_id >= value.admin_id ? prev : value;
      });
      setLastNoticeId(maxObjArr.admin_id);
    }
  }, [noticeList]);

  return (
    <FlexBox $isFullWidth>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          msg={alertModal.msg}
          type={alertModal.type}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
        />
      </ModalPortal>

      <Box $px={2} $pb={2} $isFullWidth>
        <DetailTableRow
          isBtw
          title={"제목"}
          contents={
            <FlexBox
              $isFullWidth
              $flexDirection="row"
              $ai={"center"}
              $gap={1.2}
              $py={0.8}
            >
              <PrimaryInput
                value={noticeInfo.notice_name}
                onChangeValue={noticeInfoHandler}
                placeholder="제목을 입력해주세요."
                stateKey="notice_name"
                errorMsg="제목을 입력해주세요."
                $isError={inputError.notice_name}
              />
            </FlexBox>
          }
          contentsStyle={{ paddingTop: 0, paddingBottom: 0 }}
          isColumnCenter
        />
        <DetailTableRow
          title={"내용"}
          contents={
            <FlexBox
              $isFullWidth
              $flexDirection="row"
              $ai={"center"}
              $gap={1.2}
            >
              <Box>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  toolbarClassName="tool-wrapper"
                  wrapperClassName="wrapper-area"
                  editorClassName="editor-area"
                  //   editorClassName="editor-wrapper"
                />
                {inputError.notice_content && (
                  <Box $mt={1.2}>
                    <Typograpy
                      fontStyle={fontStyle.caption1}
                      color={colors.RED}
                    >
                      내용을 입력해주세요.
                    </Typograpy>
                  </Box>
                )}
              </Box>
            </FlexBox>
          }
          isColumnCenter
        />

        {/* <FlexBox $isFullWidth $flexDirection="row" $ai="stretch">
          <TableTitle title={"구분"} width={15} />
          <TableContent isColumnCenter>
            <FlexBox
              $isFullWidth
              $flexDirection="row"
              $ai={"center"}
              $gap={1.2}
            ></FlexBox>
          </TableContent>
        </FlexBox> */}
      </Box>
      <Divider variant="normal" width={"100%"} />
      <FlexBox
        $px={2}
        $pt={2}
        $flexDirection="row"
        $jc={"space-between"}
        $ai={"center"}
        $isFullWidth
      >
        <FlexBox $flexDirection="row" $gap={0.8} $ai={"center"}>
          <Typograpy fontStyle={fontStyle.label1_reading}>노출여부</Typograpy>
          <Switch
            $bgcolor={colors.ADMIN_MAIN_COLOR}
            $checked={noticeInfo.is_show === "Y"}
            onClick={() => {
              setNoticeInfo((prev) => ({
                ...prev,
                is_show: prev.is_show === "Y" ? "N" : "Y",
              }));
            }}
            size="medium"
          />
        </FlexBox>
        <FlexBox $flexDirection="row" $gap={0.8}>
          <OutlineButton
            buttonLabel="취소"
            bgcolor={colors.WHITE}
            bc={colors.ADMIN_MAIN_COLOR}
            fontStyle={`color : ${colors.ADMIN_MAIN_COLOR}`}
            size={"large"}
            $br={4}
            onClick={modalOff}
          />
          <SolidButton
            buttonLabel="등록"
            size={"large"}
            $br={4}
            bgcolor={colors.ADMIN_MAIN_COLOR}
            isLoading={isPending}
            onClick={createNoticeHandler}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
