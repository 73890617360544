import { IcStar } from "components/atoms/icon/icon";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";

interface ReviewCardPropsType {
  reviewInfo: {
    id: string;
    rank: number;
    contents: string;
  };
}

export const ReviewCard: React.FC<ReviewCardPropsType> = React.memo(
  ({ reviewInfo }) => {
    return (
      <Box $pb={3}>
        <FlexBox
          $py={5}
          $px={3.5}
          width={40}
          $shadowType="strong"
          // $ai="center"
          // $jc="center"
          $bgcolor={colors.REVIEW_CARD_BG}
          $br={8}
          $minHeight={25}
          $maxHeight={25}
        >
          <FlexBox
            $flexDirection="row"
            $jc="space-between"
            $ai="center"
            $isFullWidth
          >
            <Typograpy
              fontStyle={fontStyle.headline1}
              color={colors.REVIEW_CARD_ID}
              fontWeight="ExtraBold"
            >
              {reviewInfo.id}
            </Typograpy>
            <FlexBox $flexDirection="row" $gap={0.4}>
              {Array.from({ length: reviewInfo.rank }).map((item, index) => {
                return <IcStar key={"star" + index} />;
              })}
            </FlexBox>
          </FlexBox>

          <Box $mt={2}>
            <Typograpy
              fontStyle={fontStyle.headline1}
              color={colors.BLACK}
              style={{ lineHeight: "3.6rem" }}
              className={"multi-line-ellips"}
            >
              {reviewInfo.contents}
            </Typograpy>
          </Box>
        </FlexBox>
      </Box>
    );
  }
);
