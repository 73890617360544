import React from "react";
import Modal from "react-modal";
import { CommonModalType } from "./commonModalType";
import { customModalStyles } from "./customModalStyle";
import Typograpy from "components/molecules/Typograpy";
import { Box, FlexBox } from "theme/globalStyle";
import { colors } from "theme/colors";
import useScrollLock from "hooks/useScrollLock";

interface AlertModalPropsType extends CommonModalType {
  msg: string;
  subMsg?: string;
  type?: string;
  action?: () => void;
  btnLabel?: string;
}

export const AlertModal: React.FC<AlertModalPropsType> = ({
  visible,
  modalOff,
  msg,
  subMsg,
  type,
  action,
  btnLabel = "확인",
}) => {
  //modal 활성화시 body 스크롤 lock
  useScrollLock(visible);

  return (
    <Modal
      isOpen={visible}
      style={{
        ...customModalStyles,
        overlay: {
          ...customModalStyles.overlay,
          zIndex: type?.includes("confirm") ? 99999 : 9999999,
        },
      }}
    >
      <Typograpy fontSize={14}>{msg}</Typograpy>

      <FlexBox
        $gap={1.2}
        $flexDirection="row"
        $ai="center"
        $jc="flex-end"
        $mt={4}
      >
        {type?.includes("confirm") && (
          <Box onClick={modalOff} style={{ cursor: "pointer" }}>
            <Typograpy fontSize={16} color={colors.CANCLE}>
              취소
            </Typograpy>
          </Box>
        )}
        <Box
          onClick={() => {
            if (action) action();
            modalOff();
          }}
          style={{ cursor: "pointer" }}
        >
          <Typograpy fontSize={16} color={colors.BTN_MAIN_COLOR}>
            {btnLabel}
          </Typograpy>
        </Box>
      </FlexBox>
    </Modal>
  );
};
