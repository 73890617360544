import { IcList } from "components/atoms/icon/icon";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { useNavigate } from "react-router";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";

export const TopMenu = () => {
  const topMenuList = [
    { label: "블루세일즈?", link: "/" },
    { label: "구독상품", link: "/payment" },
  ];

  const navigate = useNavigate();

  return (
    <FlexBox $py={1.2} $flexDirection="row" $jc="space-between" $ai="center">
      <FlexBox $isPointer $flexDirection="row" $ai="center" $gap={0.4}>
        <IcList />
        <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="SemiBold">
          전체보기
        </Typograpy>
      </FlexBox>

      <FlexBox $flexDirection="row" $ai="center" $gap={2.8}>
        {topMenuList.map((item, index) => {
          return (
            <Box
              $isPointer
              key={"topMenu" + index}
              onClick={() => {
                navigate(item.link);
              }}
            >
              <Typograpy
                fontStyle={fontStyle.body2_normal}
                fontWeight="SemiBold"
              >
                {item.label}
              </Typograpy>
            </Box>
          );
        })}
      </FlexBox>
    </FlexBox>
  );
};
