import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { Box, FlexBox } from "theme/globalStyle";
import { motion } from "framer-motion";
import { SolidButton } from "components/molecules/button/SolidButton";
import { fontStyle } from "theme/fontStyle";
import { Divider } from "components/molecules/divider/Divider";
import { CusDetailInfo } from "components/organisms/saller/customerDetail/CusDetailInfo";
import { Proposal } from "components/organisms/saller/customerDetail/Proposal";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { useQueryClient } from "@tanstack/react-query";
import {
  GetUsersResponse,
  GetUsersResponseData,
} from "api/blueButton/GetUsers";
import { useAdminInfoStore } from "store/useAdminInfoStore";
import { ProposalInfo } from "../saller/customerDetail/ProposalInfo";

interface ConsumerDetailModalProps {
  setIsDetailModal: (modalInfo: { visible: boolean; selectId: number }) => void; //modal 셋팅
  selectId: number; //선택한 고객 번호
  productType: "insurances" | "finance"; //선택한 금융,보험 타입
  isAccecptDetail?: boolean;
  userData?: GetUsersResponse;
  proposal_id?: number;
  isJustDetail?: boolean;
}

export const ConsumerDetailModal: React.FC<ConsumerDetailModalProps> = ({
  setIsDetailModal,
  selectId,
  productType,
  userData,
  proposal_id,
  isJustDetail,
  isAccecptDetail,
}) => {
  const [selTab, setSelTab] = React.useState<"detail" | "proposal">("detail");
  const [detailUserInfo, setDetailUserInfo] =
    React.useState<GetUsersResponseData>({
      id: 0, //유저 id
      email: "", //이메일
      name: "", //이름
      address: "", //주소
      year: 0, //데이터 연도
      businessName: "", //회사이름
      age: 0, //나이
      totalIncome: 0, //총소득
      isInsuranceInfo: false, //보험 데이터가 있는지 여부
      isFinancialInfo: false, // 금융 데이터 있는지 여부
      phoneNumber: "",
      createdDate: 0,
    });

  const variants = {
    slideStart: {
      x: 860,
    },
    slideComplete: {
      x: 0,
    },
  };

  React.useEffect(() => {
    if (userData) {
      const filterUserInfo = userData.data.response.filter(
        (el: GetUsersResponseData) => el.id === selectId
      )[0];
      setDetailUserInfo({ ...filterUserInfo });
    }
  }, [selectId]);

  return (
    <FlexBox
      $position={"fixed"}
      top={0}
      $left={0}
      width={"100vw"}
      $minHeight={"100vh"}
      height={"100vh"}
      $bgcolor="rgba(12,12,12,0.4)"
      $ai="flex-end"
    >
      <motion.div
        variants={variants}
        initial={"slideStart"}
        animate="slideComplete"
        transition={{
          ease: "easeIn",
          duration: 0.5,
        }}
        style={{ minHeight: "100vh" }}
      >
        <FlexBox
          width={86}
          height={"100%"}
          $bgcolor={colors.WHITE}
          style={{ overflowY: "auto" }}
          $jc="space-between"
        >
          <FlexBox
            $px={2}
            $py={isJustDetail ? 2 : 0}
            $flexDirection="row"
            $ai="center"
            $jc="space-between"
            $isFullWidth
          >
            <Typograpy
              fontStyle={fontStyle.headline1}
              fontWeight="Bold"
              color={colors.SALLER_SUBSIDE_TITLE}
            >
              고객정보
            </Typograpy>
            {!isJustDetail && (
              <FlexBox $flexDirection="row" $gap={1.6}>
                <Box>
                  <Box
                    $py={1.5}
                    $isPointer
                    onClick={() => {
                      setSelTab("detail");
                    }}
                  >
                    <Typograpy
                      fontStyle={fontStyle.label1_normal}
                      fontWeight={selTab === "detail" ? "SemiBold" : "Regular"}
                      color={colors.SALLER_SUBSIDE_TITLE}
                    >
                      기본상세정보
                    </Typograpy>
                  </Box>
                  {selTab === "detail" && (
                    <Box
                      width={"100%"}
                      $bc={colors.MAIN}
                      $btlr={10}
                      $btrr={10}
                      height={0.3}
                      $bgcolor={colors.MAIN}
                    />
                  )}
                </Box>
                {proposal_id ? (
                  <Box>
                    <Box
                      $py={1.5}
                      $isPointer
                      onClick={() => {
                        setSelTab("proposal");
                      }}
                    >
                      <Typograpy
                        fontStyle={fontStyle.label1_normal}
                        color={colors.SALLER_SUBSIDE_NONE_SEL_TITLE}
                        fontWeight={
                          selTab === "proposal" ? "SemiBold" : "Regular"
                        }
                      >
                        제안 정보
                      </Typograpy>
                    </Box>
                    {selTab === "proposal" && (
                      <Box
                        width={"100%"}
                        $bc={colors.MAIN}
                        $btlr={10}
                        $btrr={10}
                        height={0.3}
                        $bgcolor={colors.MAIN}
                      />
                    )}
                  </Box>
                ) : (
                  <Box>
                    <Box
                      $py={1.5}
                      $isPointer
                      onClick={() => {
                        setSelTab("proposal");
                      }}
                    >
                      <Typograpy
                        fontStyle={fontStyle.label1_normal}
                        color={colors.SALLER_SUBSIDE_NONE_SEL_TITLE}
                        fontWeight={
                          selTab === "proposal" ? "SemiBold" : "Regular"
                        }
                      >
                        {productType === "insurances" ? "보험" : "금융"}상품
                        제안하기
                      </Typograpy>
                    </Box>
                    {selTab === "proposal" && (
                      <Box
                        width={"100%"}
                        $bc={colors.MAIN}
                        $btlr={10}
                        $btrr={10}
                        height={0.3}
                        $bgcolor={colors.MAIN}
                      />
                    )}
                  </Box>
                )}
              </FlexBox>
            )}
            <Box />
          </FlexBox>
          {/* <Divider variant="normal" width={"100%"} /> */}
          {detailUserInfo.id && (
            <Box
              $isFullWidth
              $pa={2}
              $flex={1}
              $btw={1}
              $btc={colors.DEFAULT_BORDER_COLOR}
            >
              {selTab === "detail" ? (
                <CusDetailInfo
                  detailUserInfo={detailUserInfo}
                  productType={productType}
                  setSelTab={setSelTab}
                  proposal_id={proposal_id}
                  isAccecptDetail={isAccecptDetail}
                />
              ) : proposal_id ? (
                <ProposalInfo
                  setIsDetailModal={setIsDetailModal}
                  detailUserInfo={detailUserInfo}
                  productType={productType}
                  proposal_id={proposal_id}
                  isAccecptDetail={isAccecptDetail}
                />
              ) : (
                <Proposal
                  setIsDetailModal={setIsDetailModal}
                  detailUserInfo={detailUserInfo}
                  productType={productType}
                />
              )}
            </Box>
          )}
          <FlexBox
            $isFullWidth
            $pa={2}
            $btw={1}
            $btc={colors.DEFAULT_BORDER_COLOR}
          >
            <OutlineButton
              buttonLabel="닫기"
              type={"assis"}
              $br={8}
              onClick={() => {
                setIsDetailModal({
                  visible: false,
                  selectId: 0,
                });
              }}
            />
          </FlexBox>
        </FlexBox>
      </motion.div>
    </FlexBox>
  );
};
