import {
  SwitchButton,
  SwitchType,
  SwitchWrapper,
} from "components/atoms/switch/switch";
import React from "react";

interface SwitchComponentType extends SwitchType {
  onClick: () => void;
}

//스위치 - 애니메이션 추가 필요
export const Switch = ({
  $checked,
  disabled,
  size,
  onClick,
  $bgcolor,
}: SwitchComponentType) => {
  return (
    <SwitchWrapper
      onClick={onClick}
      $checked={$checked}
      $bgcolor={$bgcolor}
      disabled={disabled}
      size={size}
    >
      <SwitchButton $checked={$checked} disabled={disabled} size={size} />
    </SwitchWrapper>
  );
};
