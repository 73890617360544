import React from "react";
import Typograpy from "components/molecules/Typograpy";
import ModalPortal from "utils/Portal";
import DataTable, { TableColumn } from "react-data-table-component";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import {
  convertUnixDate,
  formatAndMaskVariableDigits,
  maskAllButFirst,
  NumberComma,
  operatorTypeValueReturn,
  searchFilterValueReturn,
  subtypeValueReturn,
} from "utils/func";
import { DatatablePagination } from "components/organisms/pagination/DatatablePagination";
import { ConsumerDetailModal } from "components/organisms/modal/ConsumerDetailModal";
import { CustomerListFilter } from "components/organisms/filter/CustomerListFilter";
import { tableCustomStyles } from "theme/table.custom";

import {
  GetUsersParams,
  GetUsersResponseData,
  useGetUsersList,
} from "api/blueButton/GetUsers";
import { IcMore } from "components/atoms/icon/icon";
import { useQueryClient } from "@tanstack/react-query";
import { Pagination } from "components/organisms/pagination/Pagination";
import { NoneDataComponent } from "components/organisms/table/NoneDataComponent";
import { useUserInfoStore } from "store/userInfoStore";
import { useSnackbarStore } from "store/snackbarStore";
import LoadingComponent from "components/organisms/loadingComponent";
import useScrollLock from "hooks/useScrollLock";
import { useLocationStore } from "store/useLocationStore";
import { GetPaymentListResponseData } from "api/api.payment";

const MyCustomerTemplate = () => {
  const queryClient = useQueryClient();

  const { payment } = useUserInfoStore((state) => state);
  const showSnackBar = useSnackbarStore((state) => state.showSnackbar);
  const { locationList } = useLocationStore((state) => state);
  //고객 상세정보
  const [isDetailModal, setIsDetailModal] = React.useState({
    visible: false,
    selectId: 0,
  });

  const [myCustomerList, setMyCustomerList] = React.useState<
    GetUsersResponseData[]
  >([]);

  //필터 정보
  const [filterInfo, setFilterInfo] = React.useState<GetUsersParams>({
    regionId: "",
    type1: "",
    type2: "",
    searchKeyword: "",
    totalIncomeOperatorType: "",
    totalIncome: "",
    ageOperatorType: "",
    age: "",
    page: 0,
    size: 10,
  });

  //보험 & 금융 탭 설정
  const [productType, setProductType] = React.useState<
    "insurances" | "finance"
  >("insurances");

  //구독타입
  const [subscribeType, setSubscribeType] = React.useState("insurnaces");

  const filterSetting = (value: string, key?: string) => {
    if (key) {
      if (key === "regionId") {
        setFilterInfo({
          ...filterInfo,
          [key]: value,
          page: 0,
        });
      } else {
        setFilterInfo({
          ...filterInfo,
          [key]: value,
        });
      }
    }
  };

  //regionId 가져오기
  const getRegionId = React.useCallback((): {
    regionName: string;
    regionId: number;
  } => {
    const firPaymentInfo = payment[0].sub_product_information.local_id;
    const firRegionInfo = locationList.filter(
      (el) => el.regionId === firPaymentInfo
    )[0];

    if (!firRegionInfo) return { regionId: 0, regionName: "" };
    if (filterInfo.regionId === "")
      return { regionName: firRegionInfo.regionName, regionId: firPaymentInfo };
    const regionInfo = locationList.filter(
      (el) => el.regionName === filterInfo.regionId
    )[0];
    return regionInfo;
  }, [filterInfo.regionId, locationList, payment]);

  //고객 리스트 불러오기
  const {
    data: userData,
    isPending,
    refetch: userRefetch,
  } = useGetUsersList({
    ...filterInfo,
    regionId: getRegionId().regionId,
    type1: subtypeValueReturn(filterInfo.type1 || ""),
    type2: "BUSINESS_NAME",
    age: Number(filterInfo.age),
    ageOperatorType:
      filterInfo.age === ""
        ? "NONE"
        : operatorTypeValueReturn(filterInfo.ageOperatorType || ""),
  });

  //금융 & 보험 탭 설정
  function productTypeHandler(type: "insurances" | "finance") {
    setProductType(type);
  }

  const columns: TableColumn<GetUsersResponseData>[] = [
    { name: "NO", cell: (_, index) => index + 1, width: "6rem" },
    {
      name: "구분",
      selector: (row) =>
        row.isFinancialInfo && row.isInsuranceInfo
          ? "보험/금융"
          : row.isFinancialInfo
          ? "금융"
          : row.isInsuranceInfo
          ? "보험"
          : "",
    },
    { name: "성명", selector: (row) => maskAllButFirst(row.name) },
    { name: "연령", selector: (row) => `만 ${row.age}세`, sortable: true },
    { name: "직장", selector: (row) => row.businessName, grow: 4 },
    {
      name: "가입일",
      selector: (row) => convertUnixDate(row.createdDate / 1000),
      grow: 2,
    },
    {
      name: "종합소득",
      selector: (row) => row.totalIncome + "원",
      grow: 2,
    },
    {
      name: "",
      cell: (row) => (
        <FlexBox
          $isPointer
          onClick={() => setIsDetailModal({ visible: true, selectId: row.id })}
          $br={4}
          $bgcolor={colors.MAIN}
          // $isFullWidth
          $px={2.4}
          $py={0.8}
          $ai={"center"}
        >
          <Typograpy color={colors.WHITE}>상세보기</Typograpy>
          {/* <IcMore /> */}
        </FlexBox>
      ),
      // grow: 1.5,
    },
  ];

  //선택한 지역의 구독타입에 따라 product type 설정
  function selProductTypeHandler(subList: GetPaymentListResponseData[]) {
    const firRegion = locationList.filter(
      (el) => el.regionId === payment[0].sub_product_information.local_id
    );
    if (subList.length === 0) {
      showSnackBar("조회할 수 없는 지역입니다.");
      setFilterInfo((prev) => ({
        ...prev,
        regionId: firRegion[0].regionName,
      }));
    } else if (subList.length === 1) {
      setSubscribeType(subList[0].sub_product_information.product_type);
      if (subList[0].sub_product_information.product_type === "finance") {
        setProductType("finance");
      } else {
        setProductType("insurances");
      }
    } else {
      setSubscribeType("premium");
      setProductType("insurances");
    }
  }

  const searchAction = () => {
    userRefetch();
    setting();
  };

  //초기 셋팅 및 regionId 예외셋팅
  const setting = () => {
    const regionLocInfo = payment.filter(
      (el) => el.sub_product_information.local_id === getRegionId().regionId
    ); //선택한 지역의 paymentInfo를 가져옴. (동일한 지역이 여러개일수 있음)
    if (filterInfo.regionId === "") {
      //초기 셋팅
      if (!getRegionId().regionId) return;
      setFilterInfo((prev) => ({
        ...prev,
        regionId: getRegionId().regionName,
      }));
      selProductTypeHandler(regionLocInfo);
    } else {
      //첫선택이후 리스트 뽑기
      const selSubTypeInfo = payment.filter(
        (el) => el.sub_product_information.local_id === getRegionId().regionId
      );

      selProductTypeHandler(selSubTypeInfo);
    }
  };

  React.useEffect(() => {
    setting();
  }, [locationList]);

  React.useEffect(() => {
    if (filterInfo.regionId !== "") {
      userRefetch();
    }
  }, [filterInfo.regionId, filterInfo.page]);

  React.useEffect(() => {
    if (userData) {
      if (userData.data.response) {
        setMyCustomerList([...userData.data.response]);
      }
    }
  }, [userData]);

  useScrollLock(isDetailModal.visible);
  return (
    <FlexBox $isFullWidth>
      {isDetailModal.visible && (
        <ModalPortal>
          <ConsumerDetailModal
            userData={userData}
            setIsDetailModal={setIsDetailModal}
            selectId={isDetailModal.selectId}
            productType={productType}
          />
        </ModalPortal>
      )}
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc="space-between"
        $ai="center"
        height={6}
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $pl={2}
        $pr={4}
      >
        <Typograpy
          fontStyle={fontStyle.body1_normal}
          fontWeight="SemiBold"
          color={colors.SALLER_SUBSIDE_TITLE}
        >
          기존 고객 리스트
        </Typograpy>
        <FlexBox $flexDirection="row" $gap={2.4} height={"100%"}>
          {(subscribeType === "insurances" || subscribeType === "premium") && (
            <Box height={"calc(100% - 0.3rem)"}>
              <FlexBox
                $isPointer
                height={"100%"}
                $jc={"center"}
                onClick={() => {
                  productTypeHandler("insurances");
                }}
              >
                <Typograpy
                  fontStyle={fontStyle.headline2}
                  fontWeight={productType === "insurances" ? "Bold" : "Regular"}
                  color={colors.SALLER_TAB_SEL}
                >
                  보험상품
                </Typograpy>
              </FlexBox>
              {productType === "insurances" && (
                <Box
                  width={"100%"}
                  $bc={colors.MAIN}
                  $btlr={10}
                  $btrr={10}
                  height={0.3}
                  $bgcolor={colors.SALLER_TAB_SEL}
                />
              )}
            </Box>
          )}
          {(subscribeType === "finance" || subscribeType === "premium") && (
            <Box height={"calc(100% - 0.3rem)"}>
              <FlexBox
                $isPointer
                height={"100%"}
                $jc={"center"}
                onClick={() => {
                  productTypeHandler("finance");
                }}
              >
                <Typograpy
                  fontStyle={fontStyle.headline2}
                  fontWeight={productType === "finance" ? "Bold" : "Regular"}
                  color={colors.SALLER_TAB_NONE_SEL}
                >
                  금융상품
                </Typograpy>
              </FlexBox>
              {productType === "finance" && (
                <Box
                  width={"100%"}
                  $bc={colors.MAIN}
                  $btlr={10}
                  $btrr={10}
                  height={0.3}
                  $bgcolor={colors.SALLER_TAB_SEL}
                />
              )}
            </Box>
          )}
        </FlexBox>
      </FlexBox>
      <Box $isFullWidth $pa={3}>
        <CustomerListFilter
          filterInfo={filterInfo}
          filterSetting={filterSetting}
          searchAction={searchAction}
        />
      </Box>
      {!isPending ? (
        <Box $isFullWidth $px={3} $pb={3}>
          <Box $isFullWidth>
            <Box $mb={0.8}>
              <Typograpy fontStyle={fontStyle.label1_normal}>
                총 {myCustomerList.length}명
              </Typograpy>
            </Box>
            <DataTable
              columns={columns}
              data={myCustomerList}
              defaultSortFieldId={1}
              customStyles={tableCustomStyles}
              noDataComponent={<NoneDataComponent />}
              // pagination
              // paginationComponent={DatatablePagination}
            />
            <Pagination
              page={
                filterInfo.page === 0 || filterInfo.page
                  ? filterInfo.page + 1
                  : 0
              }
              rowCount={userData?.data.page.totalElements || 0}
              rowPerPage={filterInfo.size || 10}
              setPage={(page: number) => {
                setFilterInfo({
                  ...filterInfo,
                  page: page - 1,
                });
              }}
            />
          </Box>
        </Box>
      ) : (
        <LoadingComponent />
      )}
    </FlexBox>
  );
};

export default MyCustomerTemplate;
