import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { Outlet, useLocation } from "react-router";
import { colors } from "theme/colors";
import { Box, FlexBox, UserMaxWidth } from "theme/globalStyle";

export const FindPage = () => {
  const location = useLocation();

  const { state } = location;

  const [findId, setFindId] = React.useState("");

  return (
    <FlexBox $isFullWidth $ai={"center"} $minHeight={70}>
      <FlexBox
        $isFullWidth
        $py={4}
        $ai={"center"}
        $jc={"center"}
        $bgcolor={colors.USER_HEADER_BG}
      >
        <Typograpy
          fontSize={45}
          fontWeight="Bold"
          color={colors.WHITE}
          style={{ lineHeight: "4.8rem" }}
        >
          {state.type === "id" ? "아이디 찾기" : "비밀번호 찾기"}
        </Typograpy>
      </FlexBox>
      <Box $isFullWidth $maxWidth={UserMaxWidth}>
        <Outlet
          context={{
            findId,
            setFindId,
          }}
        />
      </Box>
    </FlexBox>
  );
};
