import { IcLeftArrow, IcRightArrow } from "components/atoms/icon/icon";
import { StyleNumberPagination } from "components/atoms/pagination/pagination";
import { PaginationButton } from "components/molecules/pagination/PaginationButton";
import React from "react";
import { FlexBox } from "theme/globalStyle";

interface PaginationPropsType {
  btnType?: "number" | "dot";
  rowPerPage: number;
  rowCount: number;
  page: number; //page
  setPage: (page: number) => void;
}

export const Pagination: React.FC<PaginationPropsType> = ({
  btnType = "number",
  rowPerPage,
  rowCount,
  page,
  setPage,
}) => {
  const totalPages = Math.ceil(rowCount / rowPerPage);
  const pages = [];

  let startPage = 1;
  let endPage = totalPages;

  if (totalPages > 5) {
    if (page <= 3) {
      endPage = 5;
    } else if (page + 2 >= totalPages) {
      startPage = totalPages - 4;
    } else {
      startPage = page - 2;
      endPage = page + 2;
    }
  }

  //이전페이지 랜더링
  if (page > 1 && btnType === "number") {
    pages.push(
      <StyleNumberPagination key={"prev"} onClick={() => setPage(page - 1)}>
        <IcLeftArrow />
      </StyleNumberPagination>
    );
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <PaginationButton
        key={i}
        pageNum={i}
        $isNowPage={i === page}
        onChangePage={() => setPage(i)}
        btnType={btnType}
      />
    );
  }

  //다음페이지 버튼 랜더링
  if (page < totalPages && btnType === "number") {
    pages.push(
      <StyleNumberPagination key={"next"} onClick={() => setPage(page + 1)}>
        <IcRightArrow />
      </StyleNumberPagination>
    );
  }

  return (
    <FlexBox $mt={2} $flexDirection="row" $jc="center" $ai="center">
      {pages}
    </FlexBox>
  );
};
