import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { FlexBox } from "theme/globalStyle";

interface DescriptionPropsType {
  desList: string[];
}

//디스크럽션
export const Description: React.FC<DescriptionPropsType> = React.memo(
  ({ desList }) => {
    return (
      <FlexBox
        $gap={1.6}
        $px={2}
        $py={2.4}
        $bgcolor={colors.DESCRIPTION_BG}
        $btw={1}
        $btc={colors.DESCRIPTION_TOP_BORDER}
      >
        {desList.map((item, index) => {
          return (
            <FlexBox $flexDirection="row" $gap={0.8}>
              <Typograpy
                key={"desKey" + index}
                fontSize={13}
                color={colors.DESCRIPTION_FONT}
              >
                ·
              </Typograpy>
              <Typograpy
                key={"desKey" + index}
                fontSize={13}
                color={colors.DESCRIPTION_FONT}
                style={{ lineHeight: "1.8rem" }}
              >
                {item}
              </Typograpy>
            </FlexBox>
          );
        })}
      </FlexBox>
    );
  }
);
