import {
  BannerContainer,
  BannerTrack,
} from "components/atoms/rollingBanner/rollingBanner";
import Banner from "components/molecules/rollingBanner/Banner";
import React, { ReactNode } from "react";

interface RollingBannerPropsType {
  rolling: "left" | "right";
  bannerList: ReactNode[]; //롤링될 배너 리스트 (Node 형태)
  duration: number; //롤링 속도
}

//롤링배너
const RollingBanner: React.FC<RollingBannerPropsType> = ({
  rolling,
  bannerList,
  duration,
}) => {
  const trackRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    if (trackRef.current) {
      const track = trackRef.current;
      const clonedNode = track.cloneNode(true); //트랙복제
      track.parentElement?.appendChild(clonedNode); //복제된 요소를 부모 요소에 추가
    }
  }, [rolling]);

  return (
    <BannerContainer>
      <BannerTrack $rolling={rolling} $duration={duration} ref={trackRef}>
        {bannerList.map((item, index) => (
          <Banner key={index}>{item}</Banner>
        ))}
      </BannerTrack>
    </BannerContainer>
  );
};

export default RollingBanner;
