import { useUpdateSalesmanager } from "api/api.salesmanager";
import { IcEyeOff, IcEyeOn } from "components/atoms/icon/icon";
import { SolidButton } from "components/molecules/button/SolidButton";
import { Divider } from "components/molecules/divider/Divider";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useSnackbarStore } from "store/snackbarStore";
import { useLoadingStore } from "store/useLoadingStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";

export const FindPwSuccessTemplate = () => {
  const showSnackBar = useSnackbarStore((state) => state.showSnackbar);
  const { updateLoading } = useLoadingStore((state) => state);
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;

  const { mutate: changePasswordMutate, isPending: changePasswordPending } =
    useUpdateSalesmanager({
      salesmanager_id: state.id,
    });

  const [changeInfo, setChangeInfo] = React.useState({
    password: "",
    password_re: "",
  });

  const [inputError, setInputError] = React.useState({
    password: false,
    password_msg: "",
    password_re: false,
    password_re_msg: "",
  });

  const [isInputShow, setIsInputShow] = React.useState({
    password: false,
    password_re: false,
  });

  const [isCapslock, setIsCapslock] = React.useState(false);

  function verificationHandler() {
    if (changeInfo.password === "") {
      setInputError((prev) => ({
        ...prev,
        password: true,
        password_msg: "변경할 비밀번호를 입력해주세요.",
      }));
      return false;
    } else if (changeInfo.password_re === "") {
      setInputError((prev) => ({
        ...prev,
        password_re: true,
        password_re_msg: "변경할 비밀번호를 한번 더 입력해주세요.",
      }));
      return false;
    } else if (changeInfo.password !== changeInfo.password_re) {
      setInputError((prev) => ({
        ...prev,
        password_re: true,
        password_re_msg: "비밀번호가 일치하지 않습니다.",
      }));
      return false;
    }

    return true;
  }

  async function accessChangePassword() {
    if (!verificationHandler()) return;

    changePasswordMutate(
      {
        salesmanager_password: changeInfo.password,
      },
      {
        onSuccess: () => {
          showSnackBar("비밀번호 변경이 완료되었습니다.");
          setChangeInfo({
            password: "",
            password_re: "",
          });
          navigate("/login", { replace: true });
        },
      }
    );
  }

  React.useEffect(() => {
    updateLoading(changePasswordPending);
  }, [changePasswordPending]);

  return (
    <FlexBox $isFullWidth $mt={4}>
      <PrimaryInput
        value={changeInfo.password}
        placeholder="새 비밀번호를 입력해주세요."
        onChangeValue={(value, key) => {
          setChangeInfo((prev) => ({ ...prev, password: value }));
          setInputError((prev) => ({
            ...prev,
            password: false,
            password_msg: "",
          }));
        }}
        label={"새 비밀번호"}
        stateKey="password"
        inputType={isInputShow.password ? "text" : "password"}
        rightIcon={isInputShow.password ? <IcEyeOn /> : <IcEyeOff />}
        iconAction={() => {
          setIsInputShow((prev) => ({
            ...prev,
            password: !prev.password,
          }));
        }}
        capsLockCheck={(capslock) => {
          setIsCapslock(capslock);
        }}
        $isError={inputError.password}
        errorMsg={inputError.password_msg}
      />
      <Box $mt={2} $isFullWidth>
        <PrimaryInput
          value={changeInfo.password_re}
          placeholder="비밀번호를 한번 더 입력해주세요."
          onChangeValue={(value) => {
            setChangeInfo((prev) => ({ ...prev, password_re: value }));
            setInputError((prev) => ({
              ...prev,
              password_re: false,
              password_re_msg: "",
            }));
          }}
          label={"비밀번호 확인"}
          stateKey="password_re"
          inputType={isInputShow.password_re ? "text" : "password"}
          rightIcon={isInputShow.password_re ? <IcEyeOn /> : <IcEyeOff />}
          iconAction={() => {
            setIsInputShow((prev) => ({
              ...prev,
              password_re: !prev.password_re,
            }));
          }}
          capsLockCheck={(capslock) => {
            setIsCapslock(capslock);
          }}
          $isError={inputError.password_re}
          errorMsg={inputError.password_re_msg}
        />
      </Box>

      {isCapslock && (
        <Box $mt={2}>
          <Typograpy fontStyle={fontStyle.caption1} color={colors.RED}>
            Capslock이 켜져 있습니다.
          </Typograpy>
        </Box>
      )}

      <Box $isFullWidth $mt={4}>
        <Divider
          variant="thick"
          width={"100%"}
          height={0.2}
          $bgcolor={colors.MAIN}
        />
      </Box>
      <Box $mt={2} $isFullWidth>
        <SolidButton
          size={"large"}
          buttonLabel="비밀번호 찾기"
          style={{
            width: "100%",
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
          onClick={() => {
            accessChangePassword();
            // findPwAccess();
            // navigate("/join/payment");
            // validateInput();
          }}
          disable={changeInfo.password === "" || changeInfo.password_re === ""}
        />
      </Box>
    </FlexBox>
  );
};
