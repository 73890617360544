import React from "react";
import Typograpy from "../Typograpy";
import {
  CheckIcon,
  CheckboxProps,
  CheckboxWrapper,
  HiddenCheckbox,
  StyleRoundCheckbox,
} from "components/atoms/checkbox/checkbox";

export const RoundCheckbox: React.FC<CheckboxProps> = ({
  size,
  $checked,
  disabled,
  onChange,
  name,
  value,
  label,
  fontStyle,
  fontWeight,
  fontColor,
}) => (
  <CheckboxWrapper disabled={disabled}>
    <HiddenCheckbox
      checked={$checked}
      value={value}
      name={name}
      onChange={onChange}
      disabled={disabled}
    />
    <StyleRoundCheckbox
      size={size}
      name={name}
      value={value}
      $checked={$checked}
    >
      <CheckIcon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </CheckIcon>
    </StyleRoundCheckbox>
    {label && (
      <Typograpy
        color={fontColor}
        fontWeight={fontWeight}
        fontStyle={fontStyle}
      >
        {label}
      </Typograpy>
    )}
  </CheckboxWrapper>
);
