import { useGetLocation } from "api/blueButton/getLocationList";
import { Divider } from "components/molecules/divider/Divider";
import { CompanyInfo } from "components/organisms/footer/CompanyInfo";
import { NoticeBar } from "components/organisms/footer/NoticeBar";
import { Header } from "components/organisms/header/Header";
import { TopMenu } from "components/organisms/header/TopMenu";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAdminInfoStore } from "store/useAdminInfoStore";

import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";

const StaticArea = () => {
  const location = useLocation();
  const { pathname } = location;
  const { admin_id } = useAdminInfoStore((state) => state);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <FlexBox $isFullWidth $ai="center">
      <Box $isFullWidth $maxWidth={MainMaxWidth}>
        <Header />
      </Box>
      <Divider variant="normal" width={"100%"} />
      {!admin_id && (
        <Box $isFullWidth $maxWidth={MainMaxWidth}>
          <TopMenu />
        </Box>
      )}

      <Outlet />

      <NoticeBar />
      {/* <FooterTab /> */}
      <CompanyInfo />
    </FlexBox>
  );
};

export default StaticArea;
