import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { Outlet } from "react-router-dom";
import { colors } from "theme/colors";

import { Box, FlexBox, UserMaxWidth } from "theme/globalStyle";

export interface LocationListElType {
  location_name: string;
}

export interface JoinInfoType {
  type: "consumer" | "business"; //고객타입
  id: string; //아이디
  id_check: boolean; //아이디 중복확인
  password: string; //패스워드
  password_re: string; //패드워드 재입력
  name: string; //이름
  phone: string; //휴대폰번호
  phone_code: string; //휴대폰 인증번호
  phone_check: boolean; //휴대폰 인증여부
  gender: "M" | "F" | "N"; //성별 M : 남자, F : 여자 , N : 입력안함
  year: string; //생년
  month: string; //월
  day: string; //일
  zip: number; //우편번호
  address: string; //주소
  address_detail: string; //상세주소
}

export interface SalesInfoType {
  group: string; //소속
  email: string; //영업 이메일
  email_code: string; //영업이메일 인증번호
  email_check: boolean; //영업이메일 인증여부
  tel: string; //영업 전화번호
  tel_code: string;
  tel_check: boolean;
  zip: number; //회사 우편번호
  address: string; //회사 기본주소
  address_detail: string; //회사 상세주소
  location_list: LocationListElType[]; // 영업지역 리스트
  sales_item: string; //영업품목
}

const SignUpPage = () => {
  //개인정보
  const [joinInfo, setJoinInfo] = React.useState<JoinInfoType>({
    type: "consumer",
    id: "",
    id_check: false,
    password: "",
    password_re: "",
    name: "",
    phone: "",
    phone_code: "",
    phone_check: false,
    gender: "M",
    year: "",
    month: "",
    day: "",
    zip: 0,
    address: "",
    address_detail: "",
  });

  //영업정보
  const [salesInfo, setSalesInfo] = React.useState<SalesInfoType>({
    group: "",
    email: "",
    email_code: "",
    email_check: false,
    tel: "",
    tel_code: "",
    tel_check: false,
    zip: 0,
    address: "",
    address_detail: "",
    location_list: [
      {
        location_name: "",
      },
    ],
    sales_item: "",
});

  return (
    <FlexBox $isFullWidth $ai="center" $minHeight={70}>
      <FlexBox
        $isFullWidth
        $py={4}
        $ai="center"
        $jc="center"
        $bgcolor={colors.USER_HEADER_BG}
      >
        <Typograpy
          fontSize={45}
          fontWeight="Bold"
          color={colors.WHITE}
          style={{ lineHeight: "4.8rem" }}
        >
          회원가입
        </Typograpy>
      </FlexBox>
      <Box $isFullWidth $maxWidth={UserMaxWidth}>
        <Outlet
          context={{
            joinInfo,
            salesInfo,
            setJoinInfo,
            setSalesInfo,
          }}
        />
      </Box>
    </FlexBox>
  );
};

export default SignUpPage;
