import React, { ReactNode } from "react";
import { CSSProperties } from "styled-components";
import { FlexBox } from "theme/globalStyle";

interface TableContentProps {
  children: ReactNode;
  isColumn?: boolean;
  isLeft?: boolean;
  isColumnCenter?: boolean;
  style?: CSSProperties;
}

export const TableContent: React.FC<TableContentProps> = ({
  children,
  isColumn,
  isLeft,
  isColumnCenter,
  style,
}) => {
  return (
    <FlexBox
      $minHeight={5.8}
      $flex={1}
      $py={1.2}
      $px={2}
      $flexDirection={isColumn ? "column" : "row"}
      $jc={isLeft ? "flex-start" : "center"}
      $ai={isColumnCenter ? "center" : "flex-start"}
      style={{ ...style }}
    >
      {children}
    </FlexBox>
  );
};
