import { TableHeader } from "components/atoms/table/table";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";

interface NoticeTableHeaderType {
  title: string;
  width: string | number;
}

export const NoticeTableHeader = ({ title, width }: NoticeTableHeaderType) => {
  return (
    <TableHeader $tdWidth={width} $bgcolor={colors.TABLE_HEADER_BACK}>
      <Typograpy fontStyle={fontStyle.caption1} color={colors.BLACK}>
        {title}
      </Typograpy>
    </TableHeader>
  );
};
