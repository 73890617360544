import React from "react";
import Modal from "react-modal";
import { CommonModalType } from "./commonModalType";
import { Box, FlexBox } from "theme/globalStyle";
import Typograpy from "components/molecules/Typograpy";
import { fontStyle } from "theme/fontStyle";
import { IcClose } from "components/atoms/icon/icon";
import { Divider } from "components/molecules/divider/Divider";
import { TableTitle } from "components/molecules/table/TableTitle";
import { TableContent } from "components/molecules/table/TableContent";
import { colors } from "theme/colors";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { TableRowWrapper } from "components/atoms/table/table";
import { Textarea } from "components/molecules/textarea/TextArea";
import { HiddenFileInput } from "components/atoms/file/file";
import { Switch } from "components/molecules/switch/Switch";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { SolidButton } from "components/molecules/button/SolidButton";
import useScrollLock from "hooks/useScrollLock";
import {
  CouponDataWithParams,
  useCreateCoupon,
  useUpdateCoupon,
} from "api/api.coupon";
import { useSnackbarStore } from "store/snackbarStore";
import { convertUnixDate, getToday, getUnixDate } from "utils/func";
import { useQueryClient } from "@tanstack/react-query";
import { CommonModal } from "./CommonModal";
import { useCouponStore } from "store/useCouponStore";

interface CouponManageModalProps extends CommonModalType {
  detailCouponInfo?: CouponDataWithParams;
  mode: "create" | "edit";
}

export const CouponManageModal: React.FC<CouponManageModalProps> = ({
  visible,
  modalOff,
  detailCouponInfo,
  mode,
}) => {
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);

  const { mutate: createCoupon, isPending: createCouponLoading } =
    useCreateCoupon();
  const { mutate: updateCoupon, isPending: updateCouponLoading } =
    useUpdateCoupon(detailCouponInfo?.coupon_id || 0);
  const queryClient = useQueryClient();

  const { updateCouponList, couponList } = useCouponStore((state) => state);

  const [couponInfo, setCouponInfo] = React.useState<CouponDataWithParams>({
    coupon_name: "",
    coupon_type: "all",
    coupon_content: "",
    coupon_code: "",
    expiration_start_date: getUnixDate(getToday()),
    expiration_end_date: getUnixDate(getToday()),
    discount_rate: 0,
  });

  const [inputError, setInputError] = React.useState({
    coupon_name: false,
    coupon_code: false,
    discount_rate: false,
    coupon_content: false,
  });

  function couponInfoHandler(value: string | number, key?: string) {
    if (key) {
      setCouponInfo({
        ...couponInfo,
        [key]: value,
      });
    }
  }

  function verifityCoupon() {
    if (couponInfo.coupon_name === "") {
      setInputError((prev) => ({ ...prev, coupon_name: true }));
      return false;
    } else if (couponInfo.coupon_code === "") {
      setInputError((prev) => ({ ...prev, coupon_code: true }));
      return false;
    } else if (couponInfo.discount_rate === 0) {
      setInputError((prev) => ({ ...prev, discount_rate: true }));
      return false;
    } else if (couponInfo.coupon_content === "") {
      setInputError((prev) => ({ ...prev, coupon_content: true }));
      return false;
    }

    return true;
  }

  function createCouponAccess() {
    if (!verifityCoupon()) return;

    const params = {
      ...couponInfo,
      discount_rate: Number(couponInfo.discount_rate),
      updated_at: getUnixDate(),
      created_at: getUnixDate(),
    };
    if (mode === "create") {
      createCoupon(params, {
        onSuccess: () => {
          updateCouponList([...couponList, params]);
          showSnackbar("쿠폰등록이 완료되었습니다.");
          modalOff();
        },
        onError: (error) => {
          showSnackbar(error.message);
        },
      });
    } else {
      updateCoupon(params, {
        onSuccess: () => {
          showSnackbar("쿠폰수정이 완료되었습니다.");
          modalOff();
          let tempCouponList = [...couponList];
          const updateCouponId = tempCouponList.findIndex(
            (el) => el.coupon_id === detailCouponInfo?.coupon_id
          );
          tempCouponList[updateCouponId] = {
            ...tempCouponList[updateCouponId],
            ...params,
          };
          updateCouponList([...tempCouponList]);
        },
        onError: (error) => {
          showSnackbar(error.message);
        },
      });
    }
  }

  React.useEffect(() => {
    if (detailCouponInfo) {
      setCouponInfo({
        ...detailCouponInfo,
      });
    }
  }, [detailCouponInfo]);

  useScrollLock(visible);

  return (
    <CommonModal visible={visible} modalOff={modalOff}>
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc={"space-between"}
        $ai={"center"}
        $px={3}
      >
        <Typograpy fontStyle={fontStyle.heading2}>
          {mode === "create" ? "쿠폰등록" : "쿠폰수정"}
        </Typograpy>
        <Box $isPointer onClick={modalOff}>
          <IcClose />
        </Box>
      </FlexBox>
      <Box $isFullWidth $my={2}>
        <Divider variant="normal" width={"100%"} />
      </Box>
      <Box $px={0.8} $mb={2}>
        <FlexBox
          $btw={1}
          $bbc={colors.DEFAULT_BORDER_COLOR}
          $btc={colors.DEFAULT_BORDER_COLOR}
        >
          <TableRowWrapper>
            <TableTitle title={"쿠폰명"} isRequire />
            <TableContent isColumnCenter>
              <PrimaryInput
                value={couponInfo.coupon_name}
                placeholder="쿠폰명을 입력해주세요."
                // rightIcon={
                //   <FlexBox $flexDirection={"row"}>
                //     <Typograpy>0</Typograpy>
                //     <Typograpy>/100</Typograpy>
                //   </FlexBox>
                // }
                onChangeValue={couponInfoHandler}
                stateKey="coupon_name"
              />
            </TableContent>
          </TableRowWrapper>
        </FlexBox>
        <TableRowWrapper>
          <TableTitle isRequire title={"쿠폰코드"} />
          <TableContent isColumnCenter>
            <PrimaryInput
              value={couponInfo.coupon_code}
              placeholder="쿠폰코드를 입력하세요."
              onChangeValue={couponInfoHandler}
              stateKey="coupon_code"
              rightButton={
                <FlexBox
                  height={"100%"}
                  $jc={"center"}
                  $px={2}
                  $bw={1}
                  $br={4}
                  $bc={colors.ADMIN_MAIN_COLOR}
                  $isPointer
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Typograpy color={colors.ADMIN_MAIN_COLOR}>
                    자동생성
                  </Typograpy>
                </FlexBox>
              }
            />
          </TableContent>
        </TableRowWrapper>
        <TableRowWrapper>
          <TableTitle title={"할인금액"} isRequire />
          <TableContent isLeft>
            <FlexBox $flexDirection={"row"} $ai={"center"} $gap={1.2}>
              <PrimaryInput
                value={
                  String(couponInfo.discount_rate) === "0"
                    ? ""
                    : String(couponInfo.discount_rate)
                }
                placeholder="할인금액을 입력해주세요."
                onChangeValue={(value, key) => {
                  if (Number(value) > 100) {
                    showSnackbar("할인율은 최대 100%까지 적용가능합니다.");
                    couponInfoHandler("0", key);
                  } else {
                    couponInfoHandler(value, key);
                  }
                }}
                inputType={"number"}
                max={100}
                stateKey="discount_rate"
              />
              <Typograpy>%</Typograpy>
            </FlexBox>
          </TableContent>
        </TableRowWrapper>
        <TableRowWrapper>
          <TableTitle title={"쿠폰설명"} isRequire />
          <TableContent isColumnCenter>
            <PrimaryInput
              value={couponInfo.coupon_content}
              placeholder="설명을 입력해주세요."
              onChangeValue={couponInfoHandler}
              stateKey="coupon_content"
            />
          </TableContent>
        </TableRowWrapper>
        <TableRowWrapper>
          <TableTitle title={"사용기간"} isRequire />
          <TableContent isLeft>
            <FlexBox $flexDirection="row" $ai={"center"} $gap={1.2}>
              <PrimaryInput
                value={convertUnixDate(
                  couponInfo.expiration_start_date
                ).replaceAll(".", "-")}
                placeholder="시작일"
                onChangeValue={(value, key) => {
                  const [year, month, day] = value.split("-");
                  couponInfoHandler(getUnixDate(year, month, day), key);
                }}
                inputType={"date"}
                stateKey="expiration_start_date"
                min={getToday()}
                max={convertUnixDate(couponInfo.expiration_end_date).replaceAll(
                  ".",
                  "-"
                )}
              />
              <Typograpy>~</Typograpy>
              <PrimaryInput
                min={getToday()}
                value={convertUnixDate(
                  couponInfo.expiration_end_date
                ).replaceAll(".", "-")}
                placeholder="종료일"
                onChangeValue={(value, key) => {
                  const [year, month, day] = value.split("-");
                  couponInfoHandler(getUnixDate(year, month, day), key);
                }}
                inputType={"date"}
                stateKey={"expiration_end_date"}
              />
            </FlexBox>
          </TableContent>
        </TableRowWrapper>
        {/* <TableRowWrapper>
          <TableTitle title={"이미지"} isRequire />
          <TableContent isLeft>
            <FlexBox $flexDirection="row" $gap={1.6}>
              <HiddenFileInput
                id={"couponImg"}
                onChange={(e) => {
                  // console.log(e);
                }}
              />
              <label htmlFor={"couponImg"}>
                <FlexBox
                  width={6.5}
                  height={6.5}
                  $br={5}
                  $bgcolor={colors.TYPE_SEL_NONE_BG}
                  $bw={1}
                  $bc={"#C3C3C3"}
                  $ai={"center"}
                  $jc={"center"}
                  $isPointer
                >
                  <Typograpy fontSize={30} color={"#C3C3C3"}>
                    +
                  </Typograpy>
                </FlexBox>
              </label>
              <FlexBox
                width={6.5}
                height={6.5}
                $br={5}
                $bw={1}
                $bc={"#C3C3C3"}
                style={{ borderStyle: "dashed" }}
              ></FlexBox>
            </FlexBox>
          </TableContent>
        </TableRowWrapper> */}
      </Box>
      <Divider variant="normal" width={"100%"} />
      <FlexBox
        $px={2}
        $pt={2}
        $flexDirection="row"
        $jc={"flex-end"}
        $ai={"center"}
        $isFullWidth
      >
        {/* <FlexBox $flexDirection="row" $gap={0.8} $ai={"center"}>
          <Typograpy fontStyle={fontStyle.label1_reading}>노출여부</Typograpy>
          <Switch
            $bgcolor={colors.ADMIN_MAIN_COLOR}
            $checked={true}
            onClick={() => {}}
            size="medium"
          />
        </FlexBox> */}
        <FlexBox $flexDirection="row" $gap={0.8}>
          <OutlineButton
            buttonLabel="취소"
            bgcolor={colors.WHITE}
            bc={colors.ADMIN_MAIN_COLOR}
            fontStyle={`color : ${colors.ADMIN_MAIN_COLOR}`}
            size={"large"}
            $br={4}
            onClick={modalOff}
          />
          <SolidButton
            buttonLabel={mode === "create" ? "등록" : "수정"}
            size={"large"}
            $br={4}
            bgcolor={colors.ADMIN_MAIN_COLOR}
            isLoading={createCouponLoading || updateCouponLoading}
            onClick={createCouponAccess}
          />
        </FlexBox>
      </FlexBox>
    </CommonModal>
  );
};
