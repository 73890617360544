import styled from "styled-components";
import { colors } from "theme/colors";
import { marginPaddingMixin } from "theme/globalStyle";
import { CustomTextType } from "theme/styles.type";

export const Text = styled.span<CustomTextType>`
  font-size: ${({ $fs }) => $fs && `${$fs / 10}rem`};
  color: ${({ $fc }) => $fc || colors.BLACK};
  font-family: ${({ $fw }) =>
    $fw ? `${$fw},'Apple SD Gothic Neo'` : `'Regular','Apple SD Gothic Neo'`};
  text-align: ${({ text_align }) => text_align || "left"};
  word-break: break-word;
  ${marginPaddingMixin}
  ${({ background }) => background && `background : ${background};`}
    ${({ textclip }) => textclip && `-webkit-background-clip: text;`}
  ${({ fontStyle }) => fontStyle && `${fontStyle}`};

  ${({ $hoverColor }) =>
    $hoverColor &&
    `
      &:hover{
        color : ${$hoverColor};
      }
    `}
`;

export const PreText = styled.pre<CustomTextType>`
  font-size: ${({ $fs }) => $fs && `${$fs / 10}rem`};
  color: ${({ $fc }) => $fc || colors.BLACK};
  font-family: ${({ $fw }) =>
    $fw ? `${$fw},'Apple SD Gothic Neo'` : `'Regular','Apple SD Gothic Neo'`};
  text-align: ${({ text_align }) => text_align || "left"};
  word-break: break-word;
  ${marginPaddingMixin}
  ${({ background }) => background && `background : ${background};`}
    ${({ textclip }) => textclip && `-webkit-background-clip: text;`}
  ${({ fontStyle }) => fontStyle && `${fontStyle}`};

  ${({ $hoverColor }) =>
    $hoverColor &&
    `
      &:hover{
        color : ${$hoverColor};
      }
    `}
`;
