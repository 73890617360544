import { TabButton } from "components/atoms/tab/tabStyles";
import styled from "styled-components";

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
`;

interface ScrollableTabProps {
  items: string[];
  activeTab: number;
  onTabChange: (index: number) => void;
}

export const ScrollableTab: React.FC<ScrollableTabProps> = ({
  items,
  activeTab,
  onTabChange,
}) => {
  return (
    <ScrollContainer>
      {items.map((item, index) => (
        <TabButton
          key={index}
          active={index === activeTab}
          onClick={() => onTabChange(index)}
        >
          {item}
        </TabButton>
      ))}
    </ScrollContainer>
  );
};
