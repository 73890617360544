import React, { ReactNode } from "react";
import { FlexBox } from "theme/globalStyle";
import { PrimaryInput } from "../Input/PrimaryInput";
import { HiddenFileInput, UploadOutlineBtn } from "components/atoms/file/file";
import { OutlineButton } from "../button/OutlineButton";
import { colors } from "theme/colors";
import { SolidButton } from "../button/SolidButton";
import { useSnackbarStore } from "store/snackbarStore";
import { maxFileSize } from "utils/func";

interface FileProps {
  fileId: string; //input id
  onChange: (e: FileList) => void; //파일 변경 이벤트
  uploadForm?: ReactNode; //업로드내용이 표시될 ui
  uploadButton?: ReactNode; //업로드버튼
  uploadLabel: string; //업로드버튼에 들어갈 label
  isLimit?: number; //파일 최대 개수
}

export const File: React.FC<FileProps> = ({
  fileId,
  onChange,
  uploadForm,
  uploadButton,
  uploadLabel,
  isLimit,
}) => {
  const { showSnackbar } = useSnackbarStore((state) => state);

  return (
    <FlexBox $flexDirection="row" $ai="center" $gap={1.2}>
      {uploadForm || (
        <PrimaryInput
          value=""
          onChangeValue={() => {}}
          placeholder={"파일을 선택하여 업로드해주세요."}
          readonly
        />
      )}
      {uploadButton || (
        <UploadOutlineBtn htmlFor={fileId}>{uploadLabel}</UploadOutlineBtn>
      )}

      <HiddenFileInput
        id={fileId}
        onChange={(e) => {
          if (e.target.files) {
            if (e.target.files[0].size > maxFileSize) {
              showSnackbar("파일은 4MB이하로 업로드해주세요.");
            } else {
              onChange(e.target.files);
            }
          }
        }}
      />
    </FlexBox>
  );
};
