import React from "react";

import { SolidButton } from "components/molecules/button/SolidButton";
import { SubscribePlanCard } from "components/organisms/card/SubscribePlanCard";
import { AlertModal } from "components/organisms/modal/AlertModal";
import { InputHeader } from "components/organisms/user/InputHeader";
import { useSnackbarStore } from "store/snackbarStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import {
  generateOrderId,
  getUnixDate,
  isDateInRange,
  NumberComma,
} from "utils/func";
import ModalPortal from "utils/Portal";

import Typograpy from "components/molecules/Typograpy";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { useUserInfoStore } from "store/userInfoStore";
import {
  GetLocationResponseData,
  useGetLocation,
} from "api/blueButton/getLocationList";
import { useNavigate } from "react-router";
import { SalesLocationSelBox } from "components/organisms/saller/payment/SalesLoacationSelBox";
import { useSubProductPaymentStore } from "store/useSubProductPaymentStore";
import {
  CreateSubProductParamsWithType,
  useGetSubProductList,
} from "api/api.subproduct";
import { CouponBoxData, useGetCouponBoxList } from "api/api.couponBox";
import { useCouponStore } from "store/useCouponStore";
import { GetPaymentListResponseData } from "api/api.payment";
import { PaymentResultTable } from "components/organisms/saller/payment/PaymentResultTable";
import { useLocationStore } from "store/useLocationStore";
import { useRefreshToken } from "api/auth/RefreshToken";
import { SelectBox } from "components/molecules/selectbox/SelectBox";
import { cardInstallmentPlanList } from "utils/list";

export const Payment = () => {
  const navigate = useNavigate();

  const {
    sales_area,
    salesmanager_id,
    salesmanager_name,
    payment,
    customer_key,
  } = useUserInfoStore((state) => state); //sales_area (영업지역 리스트)

  //결제완료했을때를 위한 상품목록 저장
  const { updatePaymentSubProductList, clearPaymentSubProductList } =
    useSubProductPaymentStore((state) => state);
  const { data: subProductData } = useGetSubProductList();
  const { data: couponBoxData } = useGetCouponBoxList({ salesmanager_id });
  const { couponList } = useCouponStore((state) => state);

  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);

  //지역 추가 했을때 초기 상태
  const initialPaymentInfo: GetPaymentListResponseData = {
    salesmanager_id: salesmanager_id,
    sub_type: "Y",
    sub_product_information: {
      sub_product_id: 0,
      local_id: 0,
      product_type: "",
      sub_product_name: "",
      sub_product_content: "",
      sub_product_price: 0,
      is_show: "Y",
      validity_term: 12,
    },
    price: 0,
    final_discount: 0,
    final_price: 0,
    amount: 0,
    payment_status: "",
    coupon_type: "N",
    coupon_information: {
      coupon_id: 0,
      couponbox_id: 0,
      coupon_name: "",
      expiration_date: 0,
      discount_rate: 0,
      created_at: 0,
      updated_at: 0,
    },
    payment_information: {
      card: {
        acquirerCode: "",
        cardType: "",
        issuerCode: "",
        number: "",
        ownerType: "",
      },
      method: "",
      mId: "",
      orderId: "",
      orderName: "",
      paymentKey: "",
      requestedAt: "",
      status: "",
      suppliedAmount: 0,
      totalAmount: 0,
    },
    customer_key: "",
    builing_key: "",
    payment_method: "",
    toss_id: "",
  };

  //지역 리스트
  const { locationList } = useLocationStore((state) => state);

  //기존에 결제했던정보
  const [paymentInfo, setPaymentInfo] = React.useState<
    GetPaymentListResponseData[]
  >([]);

  //새로 추가될 결제정보
  const [newPaymentInfo, setNewPaymentInfo] = React.useState<
    GetPaymentListResponseData[]
  >([]);

  const [subProductList, setSubProductList] = React.useState<
    CreateSubProductParamsWithType[]
  >([]);

  const [cardInstallPlan, setCardInsatallPlan] = React.useState(0);

  const [couponBoxList, setCouponBoxList] = React.useState<CouponBoxData[]>([]);

  //총가격

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  const alertAction = () => {
    if (alertModal.type === "go_login") {
      navigate("/login", { replace: true });
    }
  };

  //지역 선택
  function locationHandler(location: string, index: number) {
    if (locationList.length > 0) {
      let isOverwrap = false;

      const selLocationId = locationList.filter(
        (el) => el.regionName === location
      )[0].regionId;

      if (!isOverwrap) {
        let tempArr = [...newPaymentInfo];
        tempArr[index] = {
          ...tempArr[index],
          sub_product_information: {
            ...tempArr[index].sub_product_information,
            local_id: selLocationId,
          },
        };
        setNewPaymentInfo((prev) => [...tempArr]);
      }
    }
  }

  //지역 추가
  function addLocation() {
    // setPaymentSalesArea((prev) => [...prev, { local_id: 0 }]);
    setNewPaymentInfo((prev) => [...prev, initialPaymentInfo]);
  }

  //구독상품 선택
  function choiceSubProduct(opt: string, index: number) {
    let tempSubList = newPaymentInfo;
    let price = 0;
    const optFilter = subProductList.filter(
      (el) => el.sub_product_optname === opt
    );

    if (opt === "") {
      tempSubList[index] = {
        ...tempSubList[index],
        amount: 0,
        final_price: 0,
        price: 0,
        sub_product_information: {
          ...tempSubList[index].sub_product_information,
          sub_product_id: 0,
          sub_product_name: "",
          sub_product_content: "",
          sub_product_price: 0,
          product_type: "",
          is_show: "Y",
          validity_term: 0,
        },
        coupon_information: {
          coupon_id: 0,
          couponbox_id: 0,
          coupon_name: "",
          created_at: 0,
          discount_rate: 0,
          expiration_date: 0,
          updated_at: 0,
        },
        coupon_type: "",
      };
    } else {
      tempSubList[index] = {
        ...tempSubList[index],
        amount: 1,
        final_price: optFilter[0].sub_product_price,
        price: optFilter[0].sub_product_price,
        coupon_information: {
          coupon_id: 0,
          couponbox_id: 0,
          coupon_name: "",
          created_at: 0,
          discount_rate: 0,
          expiration_date: 0,
          updated_at: 0,
        },
        coupon_type: "",
        sub_product_information: {
          ...tempSubList[index].sub_product_information,
          sub_product_id: optFilter[0].sub_product_id || 0,
          sub_product_name: optFilter[0].sub_product_name,
          sub_product_content: optFilter[0].sub_product_content,
          sub_product_price: optFilter[0].sub_product_price,
          product_type: optFilter[0].product_type,
          is_show: "Y",
          validity_term: optFilter[0].validity_term,
        },
      };
    }
    tempSubList.forEach((item) => {
      price += item.price;
    });

    setNewPaymentInfo([...tempSubList]);
  }

  //구독상품 쿠폰 선택
  function choiceCoupon(opt: string, index: number) {
    let tempSubList = newPaymentInfo;
    let price = 0;
    const optFilter = couponList.filter((el) => el.coupon_name === opt);
    if (optFilter.length > 0) {
      const couponBoxInfo = couponBoxList.filter(
        (el) => el.coupon_id === optFilter[0].coupon_id
      );
      //할인금액
      const discountPrice =
        tempSubList[index].sub_product_information.sub_product_price *
        (optFilter[0].discount_rate / 100);
      //쿠폰 상품 구독상품에 적용
      tempSubList[index] = {
        ...tempSubList[index],
        amount: 1,
        final_price:
          tempSubList[index].sub_product_information.sub_product_price -
          discountPrice,
        price: tempSubList[index].sub_product_information.sub_product_price,
        final_discount: discountPrice,
        coupon_type: "Y",
        coupon_information: {
          coupon_id: optFilter[0].coupon_id || 0,
          couponbox_id: couponBoxInfo[0].couponbox_id || 0,
          coupon_name: optFilter[0].coupon_name,
          created_at: optFilter[0].created_at || 0,
          discount_rate: optFilter[0].discount_rate,
          updated_at: optFilter[0].updated_at || 0,
          expiration_date: optFilter[0].expiration_end_date,
        },
      };
    } else {
      tempSubList[index] = {
        ...tempSubList[index],
        amount: 1,
        final_price:
          tempSubList[index].sub_product_information.sub_product_price,
        final_discount: 0,
        price: tempSubList[index].sub_product_information.sub_product_price,
        coupon_type: "",
        coupon_information: {
          coupon_id: 0,
          couponbox_id: 0,
          coupon_name: "",
          created_at: 0,
          discount_rate: 0,
          updated_at: 0,
          expiration_date: 0,
        },
      };
    }

    tempSubList.forEach((item) => {
      price += item.final_price;
    });

    //쿠폰중복사용 방지
    if (couponBoxData) {
      let tempCouponBox: CouponBoxData[] = [];

      const filterCouponBox = couponBoxData.data.filter(
        (el) =>
          el.coupon_usage_status === "N" && getUnixDate() <= el.expired_date
      );

      filterCouponBox.forEach((item) => {
        const selCouponLength = tempSubList.filter(
          (el) => el.coupon_information.coupon_id === item.coupon_id
        );

        if (selCouponLength.length === 0) {
          tempCouponBox.push(item);
        }
      });
      setCouponBoxList([...tempCouponBox]);
    }

    setNewPaymentInfo([...tempSubList]);
  }

  //선택한 상품 삭제
  function cancleSelProduct(productIndex: number) {
    setNewPaymentInfo((prev) => [
      ...prev.filter((_, index) => index !== productIndex),
    ]);
  }

  //결제 유효성 검사
  const vertifyPayment = () => {
    let flag = true;

    newPaymentInfo.forEach((item) => {
      const filterLocation = locationList.filter(
        (el) => el.regionId === item.sub_product_information.local_id
      );
      if (filterLocation.length === 0) {
        showSnackbar(`지역 및 상품을 선택해주세요.`);
        flag = false;
      } else if (
        (item.sub_product_information.sub_product_id === 0 ||
          item.sub_product_information.sub_product_name === "" ||
          item.sub_product_information.sub_product_price === 0) &&
        flag
      ) {
        const selLocation = filterLocation[0].regionName;
        showSnackbar(`${selLocation}의 상품을 선택해주세요.`);
        flag = false;
      }
    });
    return flag;
  };
  //결제요청 tosspayments
  const requestPayment = async () => {
    if (vertifyPayment()) {
      //결제 성공했을때 표시를 위한 상품정보 저장
      await updatePaymentSubProductList([...newPaymentInfo]);
      await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY || "").then(
        (tossPayments) => {
          // ------ 결제창 띄우기 ------
          tossPayments
            .requestBillingAuth("카드", {
              customerKey: generateOrderId(),
              customerName: salesmanager_name, // 구매자 이름
              successUrl: `${window.location.origin}/paymentRequest?plan=${cardInstallPlan}`,
              failUrl: `${window.location.origin}/paymentFail`,
            })
            .then((res) => console.log(res))
            // 결제창에서 발생할 수 있는 에러를 확인하세요.
            // https://docs.tosspayments.com/reference/error-codes#결제창공통-sdk-에러
            .catch(function (error) {
              console.log(error);
              clearPaymentSubProductList();
              if (error.code === "USER_CANCEL") {
                showSnackbar("결제가 취소되었습니다.");
              } else if (error.code === "INVALID_CARD_COMPANY") {
                // 유효하지 않은 카드 코드에 대한 에러 처리
              }
            });
        }
      );
    }
  };

  React.useEffect(() => {
    if (payment.length > 0) {
      setPaymentInfo([...payment]);
    }
  }, [payment]);

  React.useEffect(() => {
    if (couponBoxData) {
      if (couponBoxData.status === 200) {
        const filterCouponBox = couponBoxData.data.filter(
          (el) =>
            el.coupon_usage_status === "N" &&
            isDateInRange(el.created_at, el.expired_date)
        );
        setCouponBoxList([...filterCouponBox]);
      }
    }
  }, [couponBoxData]);

  React.useEffect(() => {
    if (subProductData) {
      if (subProductData.status === 200) {
        setSubProductList([...subProductData.data]);
      }
    }
  }, [subProductData]);

  React.useEffect(() => {
    if (payment.length === 0) {
      if (sales_area.length > 0) {
        let tempAreaList: GetPaymentListResponseData[] = [];
        sales_area.forEach((item) => {
          tempAreaList.push({
            ...initialPaymentInfo,
            sub_product_information: {
              ...initialPaymentInfo.sub_product_information,
              local_id: item.local_id,
            },
          });
        });

        setNewPaymentInfo([...tempAreaList]);
      }
    }
  }, []);

  React.useEffect(() => {
    //로그인하지 않았을때 로그인페이지로 이동
    if (salesmanager_id === 0 || !salesmanager_id) {
      setAlertModal({
        visible: true,
        msg: "로그인 후 이용해주세요.",
        type: "go_login",
      });
    }
  }, []);

  return (
    <FlexBox $isFullWidth $ai="center" $minHeight={70}>
      <FlexBox
        $isFullWidth
        $py={4}
        $ai="center"
        $jc="center"
        $bgcolor={colors.USER_HEADER_BG}
      >
        <Typograpy
          fontSize={45}
          fontWeight="Bold"
          color={colors.WHITE}
          style={{ lineHeight: "4.8rem" }}
        >
          구독상품
        </Typograpy>
      </FlexBox>
      <Box $isFullWidth $maxWidth={80}>
        <FlexBox $ai="center" $isFullWidth>
          <ModalPortal>
            <AlertModal
              visible={alertModal.visible}
              modalOff={() => {
                setAlertModal({ visible: false, msg: "", type: "" });
              }}
              msg={alertModal.msg}
              type={alertModal.type}
              action={alertAction}
            />
          </ModalPortal>
          <div id="payment-widget" />
          <Box $mb={4} $isFullWidth>
            <InputHeader title={"구독플랜"} />
            <Box
              display="grid"
              $mt={2}
              style={{ gridTemplateColumns: "repeat(3, 1fr)", gap: "1.2rem" }}
            >
              {subProductList.map((item, index) => {
                return (
                  <SubscribePlanCard
                    subscribeInfo={item}
                    key={"subscribe" + index}
                  />
                );
              })}
            </Box>
            <InputHeader title={"영업지역"} />
            <FlexBox
              $isFullWidth
              $flexDirection="row"
              $ai={"center"}
              $jc={"space-between"}
              $mt={0.8}
            >
              <Typograpy
                fontStyle={fontStyle.caption2}
                color={colors.CAPTION_COLOR}
              >
                기본 선택지는 회사주소를 기반으로한 지역입니다.
              </Typograpy>
            </FlexBox>
            {locationList.length > 0 && (
              <FlexBox $isFullWidth $gap={0.8} $mt={2}>
                {paymentInfo.length > 0 && (
                  <FlexBox
                    $isFullWidth
                    $bw={1}
                    $bc={colors.DEFAULT_BORDER_COLOR}
                    $br={4}
                    $bgcolor={"#F5F5F5"}
                    $pa={0.8}
                    $maxHeight={23}
                    style={{ overflowY: "scroll" }}
                    $gap={1.2}
                  >
                    {paymentInfo.map((item, index) => {
                      const filterRegionName = locationList.find(
                        (el) =>
                          el.regionId === item.sub_product_information.local_id
                      );
                      const subProductInfo = subProductList.find(
                        (el) =>
                          el.sub_product_id ===
                          item.sub_product_information.sub_product_id
                      );

                      return (
                        <FlexBox
                          $isFullWidth
                          $flexDirection="row"
                          $gap={0.8}
                          key={"beforepayment" + index}
                        >
                          <Box
                            $bw={1}
                            $bc={colors.DEFAULT_BORDER_COLOR}
                            $br={4}
                            $bgcolor={colors.WHITE}
                            $pa={1.2}
                            $flex={1}
                          >
                            <Typograpy>
                              {filterRegionName?.regionName}
                            </Typograpy>
                          </Box>
                          <Box
                            $bw={1}
                            $bc={colors.DEFAULT_BORDER_COLOR}
                            $br={4}
                            $bgcolor={colors.WHITE}
                            $pa={1.2}
                            $flex={1}
                          >
                            <Typograpy>
                              {subProductInfo?.sub_product_optname}
                            </Typograpy>
                          </Box>
                        </FlexBox>
                      );
                    })}
                  </FlexBox>
                )}
                {newPaymentInfo.length > 0 && (
                  <FlexBox $mt={1.2} $isFullWidth $gap={1.2}>
                    {newPaymentInfo.map((item, index) => {
                      return (
                        <SalesLocationSelBox
                          selIndex={index}
                          leftDisabled={false}
                          rightDisabled={false}
                          locPaymentInfo={item}
                          locationList={locationList}
                          subProductList={subProductList}
                          couponBoxList={couponBoxList}
                          choiceSubProduct={choiceSubProduct}
                          locationHandler={locationHandler}
                          choiceCoupon={choiceCoupon}
                          key={"paylocationList" + index}
                          selSalesArea={[...paymentInfo, ...newPaymentInfo]}
                          isNew
                          cancleSelProduct={cancleSelProduct}
                        />
                      );
                    })}
                  </FlexBox>
                )}
                <Box $isFullWidth $mt={1.2}>
                  <SolidButton
                    style={{
                      width: "100%",
                      paddingTop: "1.6rem",
                      paddingBottom: "1.6rem",
                    }}
                    buttonLabel="+ 지역추가"
                    onClick={addLocation}
                  />
                </Box>
              </FlexBox>
            )}
            {newPaymentInfo.length > 0 && (
              <FlexBox $isFullWidth>
                <PaymentResultTable newPaymentInfo={newPaymentInfo} />

                <SelectBox
                  selOpt={
                    cardInstallmentPlanList.find(
                      (el) => el.value === cardInstallPlan
                    )?.label
                  }
                  onChangeValue={(value) => {
                    const findPlanValue = cardInstallmentPlanList.find(
                      (el) => el.label === value
                    )?.value;
                    if (findPlanValue) {
                      setCardInsatallPlan(findPlanValue);
                    }
                  }}
                  optList={cardInstallmentPlanList.map((el) => el.label)}
                  placeholder="할부개월"
                  label="할부 선택"
                />
              </FlexBox>
            )}

            {newPaymentInfo.length > 0 && (
              <Box>
                <Box $mt={3} />
                <SolidButton
                  style={{
                    width: "100%",
                    paddingTop: "1.6rem",
                    paddingBottom: "1.6rem",
                  }}
                  buttonLabel="결제하기"
                  onClick={requestPayment}
                />
              </Box>
            )}
          </Box>
        </FlexBox>
      </Box>
    </FlexBox>
  );
};
