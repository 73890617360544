import { OutlineButton } from "components/molecules/button/OutlineButton";
import { SolidButton } from "components/molecules/button/SolidButton";
import Typograpy from "components/molecules/Typograpy";
import { CustomerListFilter } from "components/organisms/filter/CustomerListFilter";
import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { tableCustomStyles } from "theme/table.custom";
import {
  convertUnixDate,
  filterUserId,
  maskAllButFirst,
  phoneAddHipone,
} from "utils/func";
import ModalPortal from "utils/Portal";

import UploadIcon from "assets/img/ic_upload.svg";
import PerpleCheckIcon from "assets/img/ic_perple_check.svg";
import GreenCheckIcon from "assets/img/ic_green_check.svg";
import {
  GetUsersParams,
  GetUsersResponseData,
  useGetUsersList,
} from "api/blueButton/GetUsers";
import { DatatablePagination } from "components/organisms/pagination/DatatablePagination";
import { NoneDataComponent } from "components/organisms/table/NoneDataComponent";
import { useUserInfoStore } from "store/userInfoStore";
import { ConsumerDetailModal } from "components/organisms/modal/ConsumerDetailModal";
import { GetProposalListData, useGetProposalList } from "api/api.userProposal";
import { useLocationStore } from "store/useLocationStore";

interface DataItemType {
  location: string;
  type: string;
  name: string;
  age: string;
  employ: string;
  email: string;
  pay: string;
  state: "proposal" | "WAIT" | "refuse" | "accept";
}

interface ProposalTemplateProps {
  type: "WAIT" | "REJECT" | "ACCEPT";
}

const ProposalTemplate: React.FC<ProposalTemplateProps> = ({ type }) => {
  const { salesmanager_id } = useUserInfoStore((state) => state);
  const { locationList } = useLocationStore((state) => state);

  const [isDetailModal, setIsDetailModal] = React.useState({
    visible: false,
    selectId: 0,
  });
  const [selProposalInfo, setSelProposalInfo] = React.useState<{
    proposal_id: number;
    proposal_type: "insurances" | "finance";
  }>({
    proposal_id: 0,
    proposal_type: "insurances",
  });
  const [userIdList, setUserIdList] = React.useState("");
  const [userList, setUserList] = React.useState<GetUsersResponseData[]>([]);

  const [filterInfo, setFilterInfo] = React.useState<GetUsersParams>({
    regionId: "",
    type1: "",
    type2: "",
    searchKeyword: "",
    totalIncomeOperatorType: "",
    totalIncome: "",
    ageOperatorType: "",
    age: "",
    page: 0,
    size: 10,
  });
  const [proposalList, setProposalList] = React.useState<GetProposalListData[]>(
    []
  );

  const { data: proposalData, isLoading: proposalLoading } =
    useGetProposalList(salesmanager_id);
  const { data: userData, refetch } = useGetUsersList({ userIds: userIdList });

  const filterSetting = (value: string, key?: string) => {
    if (key) {
      setFilterInfo({
        ...filterInfo,
        [key]: value,
      });
    }
  };

  const columns: TableColumn<GetProposalListData>[] = [
    {
      name: "NO",
      // selector: (row: any, index: number) => index,
      sortable: false,
      cell: (_, index: number) => index + 1,
      width: "6rem",
    },
    {
      name: "지역",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        return filterUserList[0].address;
      },
      sortable: false,
      grow: 1,
    },
    {
      name: "상품종류",
      selector: (row) =>
        row.sales_proposal.proposal_type === "insurances" ? "보험" : "금융",
      sortable: false,
    },
    {
      name: "제안상품명",
      selector: (row) => row.sales_proposal.proposal_title,
      sortable: false,
      grow: 3,
    },
    {
      name: "성명",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        return row.proposition_status === "ACCEPT"
          ? filterUserList[0].name
          : maskAllButFirst(filterUserList[0].name);
      },
      sortable: true,
    },
    {
      name: "연령",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        return "만 " + filterUserList[0].age + "세";
      },
      sortable: true,
    },
    {
      name: "직장",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        return filterUserList[0].businessName;
      },
      sortable: true,
      grow: 2,
    },
    ...(type === "ACCEPT"
      ? [
          {
            name: "휴대폰번호",
            selector: (row: GetProposalListData) => {
              const filterUserList = userList.filter(
                (el) => el.id === row.user_id
              );
              if (filterUserList.length === 0) {
                return "";
              }
              return phoneAddHipone(filterUserList[0].phoneNumber);
            },
            grow: 1.5,
          },
        ]
      : []),
    {
      name: "발송일",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        return convertUnixDate(row.created_at);
      },
      sortable: true,
      grow: 1,
    },
    {
      name: "제안",
      // selector: (row) => row.readCount,
      sortable: false,
      grow: 2,
      cell: (row) => {
        function openDetailModal() {
          setIsDetailModal({
            visible: true,
            selectId: row.user_id,
          });
          setSelProposalInfo({
            proposal_id: row.user_proposal_id,
            proposal_type: row.sales_proposal.proposal_type as
              | "insurances"
              | "finance",
          });
        }

        if (row.proposition_status === "SEND") {
          return (
            <SolidButton
              size={"small"}
              buttonLabel="상품제안"
              $br={100}
              leftIcon={UploadIcon}
              onClick={openDetailModal}
              style={{
                minWidth: "9.7rem",
              }}
            />
          );
        } else if (row.proposition_status === "WAIT") {
          return (
            <OutlineButton
              size={"small"}
              buttonLabel="발송완료"
              $br={100}
              leftIcon={PerpleCheckIcon}
              onClick={openDetailModal}
              style={{
                minWidth: "9.7rem",
              }}
            />
          );
        } else if (row.proposition_status === "REJECT") {
          return (
            <OutlineButton
              size={"small"}
              buttonLabel="X 거절"
              $br={100}
              onClick={openDetailModal}
              bc={colors.REFUSE_BTN}
              fontStyle={`color:${colors.REFUSE_BTN};`}
              style={{
                minWidth: "9.7rem",
              }}
            />
          );
        } else {
          return (
            <OutlineButton
              size={"small"}
              buttonLabel="수락"
              $br={100}
              leftIcon={GreenCheckIcon}
              onClick={openDetailModal}
              bc={colors.ACCEPT_BTN}
              fontStyle={`color:${colors.ACCEPT_BTN};`}
              style={{
                minWidth: "9.7rem",
              }}
            />
          );
        }
      },
    },
  ];
  const setFilterProposal = () => {
    if (!proposalData) return;
    const filterProList: GetProposalListData[] = proposalData.data.filter(
      (el) => {
        const filterUserData = userList.filter(
          (user) => user.id === el.user_id
        );
        if (filterUserData.length > 0) {
          const productType =
            el.sales_proposal.proposal_type === "insurances" ? "보험" : "금융";

          const matchRegion =
            filterInfo.regionId === ""
              ? true
              : filterUserData[0].address === filterInfo.regionId;
          const matchProductType =
            filterInfo.type1 === "" || filterInfo.type1 === "전체"
              ? true
              : productType === filterInfo.type1;
          const matchCompanyName =
            filterInfo.searchKeyword === ""
              ? true
              : filterUserData[0].businessName.includes(
                  filterInfo.searchKeyword || ""
                );
          const matchAge =
            filterInfo.age === ""
              ? true
              : filterInfo.ageOperatorType === "초과"
              ? filterUserData[0].age > Number(filterInfo.age)
              : filterInfo.ageOperatorType === "이상"
              ? filterUserData[0].age >= Number(filterInfo.age)
              : filterInfo.ageOperatorType === "동일"
              ? filterUserData[0].age === Number(filterInfo.age)
              : filterInfo.ageOperatorType === "이하"
              ? filterUserData[0].age <= Number(filterInfo.age)
              : filterUserData[0].age < Number(filterInfo.age);
          return (
            matchRegion && matchProductType && matchCompanyName && matchAge
          );
        }
      }
    );
    setProposalList([...filterProList]);
  };

  //블루버튼 user를 조회하기위한 id리스트 string변환
  const idListFilter = () => {
    if (!proposalData) return;
    const idList = filterUserId(proposalData.data.map((el) => el.user_id));
    setUserIdList(idList);
  };

  React.useEffect(() => {
    if (proposalData) {
      if (proposalData.status === 200) {
        setProposalList([...proposalData.data]);
      } else {
      }
    }
  }, [proposalData]);

  React.useEffect(() => {
    setFilterProposal();
  }, [filterInfo.regionId]);

  React.useEffect(() => {
    if (proposalList.length > 0) {
      idListFilter();
    }
  }, [proposalList]);

  React.useEffect(() => {
    refetch();
  }, [userIdList, filterInfo.regionId]);

  React.useEffect(() => {
    // console.log("data ? ", userData);
    if (userData) {
      setUserList([...userData.data.response]);
    }
  }, [userData]);

  return (
    <FlexBox $isFullWidth>
      {isDetailModal.visible && (
        <ModalPortal>
          <ConsumerDetailModal
            isAccecptDetail={type == "ACCEPT"}
            setIsDetailModal={setIsDetailModal}
            selectId={isDetailModal.selectId}
            productType={selProposalInfo.proposal_type}
            proposal_id={selProposalInfo.proposal_id}
            userData={userData}
          />
        </ModalPortal>
      )}
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc="space-between"
        $ai="center"
        height={6}
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $pl={2}
        $pr={4}
      >
        <Typograpy
          fontStyle={fontStyle.body1_normal}
          fontWeight="SemiBold"
          color={colors.SALLER_SUBSIDE_TITLE}
        >
          {type === "WAIT" ? "발송" : type === "ACCEPT" ? "수락" : "거절"} 고객
          리스트
        </Typograpy>
      </FlexBox>
      <Box $isFullWidth $px={3} $pt={2}>
        <CustomerListFilter
          filterInfo={filterInfo}
          filterSetting={filterSetting}
          searchAction={setFilterProposal}
        />
      </Box>
      {proposalData && (
        <Box $isFullWidth $pa={2}>
          <Box $isFullWidth>
            <DataTable
              // customStyles={tableCustomStyles}
              columns={columns}
              data={[
                ...proposalList.filter((el) => el.proposition_status === type),
              ].reverse()}
              // selectableRows
              defaultSortFieldId={1}
              pagination
              paginationComponent={DatatablePagination}
              customStyles={tableCustomStyles}
              noDataComponent={<NoneDataComponent />}
            />
          </Box>
          {/* <Box $isFullWidth $mt={2}>
            <DataTable
              // customStyles={tableCustomStyles}
              columns={columns}
              data={[...dataList]}
              selectableRows
              defaultSortFieldId={1}
              pagination
              paginationComponent={Pagination}
              customStyles={tableCustomStyles}
            />
          </Box> */}
        </Box>
      )}
    </FlexBox>
  );
};

export default ProposalTemplate;
