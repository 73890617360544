import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError } from "axios";
import { useCouponStore } from "store/useCouponStore";

export interface CouponDataWithParams {
  coupon_id?: number; //쿠폰 id
  coupon_type: string;
  coupon_name: string; //쿠폰이름
  coupon_content: string; //쿠폰설명
  coupon_code: string; //쿠폰코드
  expiration_start_date: number; //쿠폰만료일
  expiration_end_date: number; //쿠폰만료일
  discount_rate: number; //할인율
  created_at?: number; //생성일
  updated_at?: number; //수정일
}

export interface CouponUpdateParams {
  coupon_id?: number; //쿠폰 id
  coupon_type?: string;
  coupon_name?: string; //쿠폰이름
  coupon_content?: string; //쿠폰설명
  coupon_code?: string; //쿠폰코드
  expiration_start_date?: number; //쿠폰만료일
  expiration_end_date?: number; //쿠폰만료일
  discount_rate?: number; //할인율
  created_at?: number; //생성일
  updated_at?: number; //수정일
}

export interface CreateCouponResponse {
  status: number;
  message: string;
  data: CouponDataWithParams;
}

export interface CreateCouponDataListResponse {
  status: number; //응답
  message: string; //메세지
  data: CouponDataWithParams[];
}

export interface GetCouponDataListResponse {
  status: number; //응답
  message: string; //메세지
  data: CouponDataWithParams[];
}

//쿠폰 생성
export const useCreateCoupon = () =>
  useMutation<CreateCouponDataListResponse, AxiosError, CouponDataWithParams>({
    mutationKey: ["createCoupon"],
    mutationFn: (params: CouponDataWithParams) =>
      baseAxios
        .post("/coupon", params)
        .then((res) => res.data)
        .catch((err) => console.log(err)),
  });

//쿠폰리스트 불러오기
export const useGetCouponList = () => {
  return useQuery<GetCouponDataListResponse, AxiosError>({
    queryKey: ["couponList"],
    queryFn: () => {
      return baseAxios.get("coupon").then((res) => {
        return res.data;
      });
    },
  } as UseQueryOptions<GetCouponDataListResponse, AxiosError>);
};

//쿠폰 수정
export const useUpdateCoupon = (coupon_id: number) =>
  useMutation<CreateCouponResponse, AxiosError, CouponUpdateParams>({
    mutationKey: ["updateCoupon"],
    mutationFn: (params: CouponUpdateParams) =>
      baseAxios
        .put(`/coupon/${coupon_id}`, params)
        .then((res) => res.data)
        .catch((err) => console.log(err)),
  });

export const deleteCoupon = (coupon_id: number) => {
  return baseAxios.delete(`/coupon/${coupon_id}`).then((res) => res.data);
};
