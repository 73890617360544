import { useGetSallerInfo } from "api/api.salesmanager";
import { useCheckSNSCode } from "api/auth/CheckSNSCode";
import { CheckTokenResponse, useCheckToken } from "api/auth/CheckToken";
import axios from "axios";
import { AlertModal } from "components/organisms/modal/AlertModal";
import { LoadingModal } from "components/organisms/modal/LoadingModal";
import React from "react";
import { useNavigate } from "react-router";
import { AdminInfoType, useAdminInfoStore } from "store/useAdminInfoStore";
import { UserInfoType, useUserInfoStore } from "store/userInfoStore";
import ModalPortal from "utils/Portal";

export const SocialLogin = () => {
  //카카오 서버에서 리다이렉트 쿼리

  const navigate = useNavigate();

  const rescode = new URL(window.location.href).searchParams.get("code");
  const restype = new URL(window.location.href).searchParams.get("type");

  const { data } = useCheckSNSCode({
    rescode,
    restype,
  });

  const { updateUserInfo } = useUserInfoStore((state) => state);
  const { updateAdminInfo } = useAdminInfoStore((state) => state);
  const { mutate: getSallerInfo } = useGetSallerInfo();

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  //토큰 검증 및 유저정보 불러오기
  const {
    mutate: tokenCheck,
    isError,
    error,
  } = useCheckToken({
    onSuccess: (data: CheckTokenResponse) => {
      if (data.data.type === "ADMIN") {
        updateAdminInfo(data.data as AdminInfoType);
        navigate("/");
      } else {
        const userInfo = data.data as UserInfoType;

        getSallerInfo(
          { salesmanager_id: userInfo.salesmanager_id },
          {
            onSuccess: (res) => {
              updateUserInfo({
                ...(data.data as UserInfoType),
                type: "SALESMANAGER",
              });
              navigate("/");
            },
          }
        );
      }
    },
    onError: (error) => {
      setAlertModal({
        visible: true,
        msg: `예상치 못한 에러가 발생하였습니다. \n ${error}`,
        type: "",
      });
    },
  });

  const alertAction = () => {
    navigate(-1);
  };

  // const getKakaoToken = () => {
  //   axios
  //     .post(
  //       `https://kauth.kakao.com/oauth/token`,
  //       {
  //         grant_type: "authorization_code",
  //         client_id: process.env.REACT_APP_KAKAO_APP_KEY,
  //         redirect_uri: `${window.location.origin}/auth?type=kakao`,
  //         code: rescode,
  //       },
  //       {
  //         headers: {
  //           "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((error) => console.log(error));
  // };

  const isMemberCheck = () => {
    //social_id 및 type이 같이오면 회원x
    if (data?.data.social_id && data?.data.type) {
      console.log(data);
      // navigate("/join/writeUserInfo", {
      //   state: { socialId: data?.data.social_id, type: data?.data.type },
      // });
    } else if (data?.data.token) {
      tokenCheck(data?.data.token);
    }
  };

  //카카오 서버에서 리다이렉트 code 감지
  React.useEffect(() => {
    isMemberCheck();
  }, [data]);

  return (
    <>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
          msg={alertModal.msg}
          type={alertModal.type}
          action={alertAction}
        />
      </ModalPortal>
      <LoadingModal />
    </>
  );
};
