import { Img } from "components/atoms/img/img";
import React from "react";
import { colors } from "theme/colors";
import { FlexBox } from "theme/globalStyle";
import { CustomImgType } from "theme/styles.type";

interface IconType {
  imgLink: string;
  type?: "normal" | "background" | "outline" | "solid";
  isPushBadge?: boolean;
  disabled?: boolean;
  size?: "normal" | "small" | "custom";
  customSize?: number;
  $bgcolor?: string; //background시 사용
}

export const Icon = React.memo(
  ({
    width = 2,
    height,
    imgLink,
    type = "normal",
    disabled,
    size,
    customSize,
    $bgcolor,
  }: IconType & CustomImgType) => {
    function iconBgSetting() {
      if (disabled) {
        if (type === "normal" || type === "outline") {
          return "transparent";
        } else {
          return colors.ICON_SOLID_DIS;
        }
      } else {
        if (type === "normal" || type === "outline") {
          return "transparent";
        } else if (type === "background") {
          return $bgcolor || colors.ICON_BACK;
        } else {
          return colors.ICON_SOLID;
        }
      }
    }

    return (
      <FlexBox
        $jc="center"
        $ai="center"
        $br={100}
        $pa={size === "custom" ? customSize || 1 : size === "small" ? 0.7 : 1}
        $bgcolor={iconBgSetting()}
        $bw={1}
        $bc={type === "outline" ? colors.ICON_OUTLINE : "transparent"}
      >
        <Img
          opacity={disabled ? 0.16 : 1}
          src={imgLink}
          width={width}
          height={height}
        />
      </FlexBox>
    );
  }
);
