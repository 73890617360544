export const dummyBestReviewList = [
  {
    title: "항상 애용 제품임다~~ 좋아요~~!!!!!",
    writer: "kyoungtae",
    date: new Date(),
  },
  {
    title: "항상 애용 제품임다~~ 좋아요~~!!!!!",
    writer: "kyoungtae",
    date: new Date(),
  },
  {
    title: "항상 애용 제품임다~~ 좋아요~~!!!!!",
    writer: "kyoungtae",
    date: new Date(),
  },
  {
    title: "항상 애용 제품임다~~ 좋아요~~!!!!!",
    writer: "kyoungtae",
    date: new Date(),
  },
  {
    title: "항상 애용 제품임다~~ 좋아요~~!!!!!",
    writer: "kyoungtae",
    date: new Date(),
  },
];

export const dummyReviewList = [
  {
    id: "blue***",
    rank: 5,
    contents: "끌려다니는 영업은 그만, 고객을 리드할 수 있는 블루세일즈의 DB",
  },
  {
    id: "kim***",
    rank: 5,
    contents: "지금까지 만나본 DB와는 질이 다릅니다!",
  },
  {
    id: "miwa***",
    rank: 5,
    contents:
      "전화로 거절당하는 게 당연한 일이었는데 블루세일즈는 거절당할 일이 없네요",
  },
  {
    id: "gzby***",
    rank: 5,
    contents: "DB에 자세한 고객정보까지... 저보다 고객이 더 만족합니다.",
  },
  {
    id: "bgjb***",
    rank: 5,
    contents: "미리 파악한 데이터 덕분에 멘트도 술술!",
  },
  {
    id: "yyvd***",
    rank: 5,
    contents: "이젠 영업도 똑똑한 정보전이 필수네요.",
  },
  {
    id: "nypu***",
    rank: 5,
    contents: "쏟아지는 DB, 쏟아지는 고객 데이터, 나만 알고 싶은 블루세일즈",
  },
  {
    id: "tnpm***",
    rank: 5,
    contents: "누군지도 모르는 분들과 통화할 필요가 없어서 좋습니다.",
  },
  {
    id: "ljjd***",
    rank: 5,
    contents: "아침마다 전화 돌릴 시간에 다른 업무에 집중할 수 있습니다!",
  },
  {
    id: "tfmw***",
    rank: 5,
    contents:
      "미팅 잡기도 힘든 무작위 콜에 지쳤는데 이젠 블루세일즈 영업방식으로 바꿨습니다.",
  },
  {
    id: "nbzj***",
    rank: 5,
    contents: "고객이 쏟아지는 블루세일즈 DB!",
  },
  {
    id: "jatm***",
    rank: 5,
    contents: "고객의 말보다 더 정확한 고객정보를 보여주는 블루세일즈!",
  },
  {
    id: "hznh***",
    rank: 5,
    contents:
      "사전 고객정보 조사에 얼마나 많은 시간을 썼는지... 고객보다 더 고객정보를 잘 알고 있는 블루세일즈 덕분에 계약체결 속도가 빠릅니다.",
  },
  {
    id: "roe***",
    rank: 5,
    contents: "고객에게 끌려다니는 영업보다 주도하는 영업으로!",
  },
  {
    id: "llsr***",
    rank: 5,
    contents:
      "진짜 전문가처럼 세세한 정보까지 알고 미팅하니 계약이 수월해졌습니다.",
  },
  {
    id: "diw11***",
    rank: 5,
    contents: "이렇게 상세한 고객정보까지 담긴 DB는 처음 봤습니다!",
  },
];

export const dummyProfileList = [
  {
    name: "박정민",
    company: "(주)한화손해보험",
    location: "부산광역시",
    customer: 24,
    type: "일반",
    id: 1,
  },
  {
    name: "김정민",
    company: "(주)삼성화재",
    location: "울산광역시",
    customer: 24,
    type: "금융",
    id: 2,
  },
  {
    name: "최현우",
    company: "(주)DB손해보험",
    location: "부산광역시",
    customer: 24,
    type: "프리미엄",
    id: 3,
  },
  {
    name: "박민정",
    company: "(주)한화손해보험",
    location: "부산광역시",
    customer: 24,
    type: "금융",
    id: 4,
  },
  {
    name: "김혜민",
    company: "(주)삼성화재",
    location: "울산광역시",
    customer: 24,
    type: "일반",
    id: 5,
  },
  {
    name: "김경태",
    company: "(주)국민생명보험",
    location: "부산광역시",
    customer: 24,
    type: "금융",
    id: 6,
  },
  {
    name: "다다다",
    company: "(주)보험회사",
    location: "부산광역시",
    customer: 24,
    type: "프리미엄",
    id: 7,
  },
  {
    name: "김혜민",
    company: "(주)삼성화재",
    location: "울산광역시",
    customer: 24,
    type: "일반",
    id: 5,
  },
  {
    name: "김경태",
    company: "(주)국민생명보험",
    location: "부산광역시",
    customer: 24,
    type: "금융",
    id: 6,
  },
  {
    name: "다다다",
    company: "(주)보험회사",
    location: "부산광역시",
    customer: 24,
    type: "프리미엄",
    id: 7,
  },
  {
    name: "김혜민",
    company: "(주)삼성화재",
    location: "울산광역시",
    customer: 24,
    type: "일반",
    id: 5,
  },
  {
    name: "김경태",
    company: "(주)국민생명보험",
    location: "부산광역시",
    customer: 24,
    type: "금융",
    id: 6,
  },
  {
    name: "다다다",
    company: "(주)보험회사",
    location: "부산광역시",
    customer: 24,
    type: "프리미엄",
    id: 7,
  },
  {
    name: "김혜민",
    company: "(주)삼성화재",
    location: "울산광역시",
    customer: 24,
    type: "일반",
    id: 5,
  },
  {
    name: "김경태",
    company: "(주)국민생명보험",
    location: "부산광역시",
    customer: 24,
    type: "금융",
    id: 6,
  },
  {
    name: "다다다",
    company: "(주)보험회사",
    location: "부산광역시",
    customer: 24,
    type: "프리미엄",
    id: 7,
  },
  {
    name: "김혜민",
    company: "(주)삼성화재",
    location: "울산광역시",
    customer: 24,
    type: "일반",
    id: 5,
  },
  {
    name: "김경태",
    company: "(주)국민생명보험",
    location: "부산광역시",
    customer: 24,
    type: "금융",
    id: 6,
  },
  {
    name: "다다다",
    company: "(주)보험회사",
    location: "부산광역시",
    customer: 24,
    type: "프리미엄",
    id: 7,
  },
  {
    name: "김혜민",
    company: "(주)삼성화재",
    location: "울산광역시",
    customer: 24,
    type: "일반",
    id: 5,
  },
  {
    name: "김경태",
    company: "(주)국민생명보험",
    location: "부산광역시",
    customer: 24,
    type: "금융",
    id: 6,
  },
  {
    name: "다다다",
    company: "(주)보험회사",
    location: "부산광역시",
    customer: 24,
    type: "프리미엄",
    id: 7,
  },
  {
    name: "김혜민",
    company: "(주)삼성화재",
    location: "울산광역시",
    customer: 24,
    type: "일반",
    id: 5,
  },
  {
    name: "김경태",
    company: "(주)국민생명보험",
    location: "부산광역시",
    customer: 24,
    type: "금융",
    id: 6,
  },
  {
    name: "다다다",
    company: "(주)보험회사",
    location: "부산광역시",
    customer: 24,
    type: "프리미엄",
    id: 7,
  },
];
