import { updateCouponBox } from "api/api.couponBox";
import {
  CardInformationType,
  GetPaymentListResponseData,
  useCreatePaymentList,
} from "api/api.payment";
import { TossGetBillingKeyType, TossSuccessPayType } from "api/api.toss";
import axios from "axios";
import { AlertModal } from "components/organisms/modal/AlertModal";
import { LoadingModal } from "components/organisms/modal/LoadingModal";
import React from "react";
import { useNavigate } from "react-router";
import { useLocationStore } from "store/useLocationStore";
import { useUserInfoStore } from "store/userInfoStore";
import { useSubProductPaymentStore } from "store/useSubProductPaymentStore";
import { base64Encode } from "utils/func";
import ModalPortal from "utils/Portal";
import { v4 as uuidv4 } from "uuid";

export const PaymentRequest = () => {
  const navigate = useNavigate();

  const { email_address, salesmanager_name } = useUserInfoStore(
    (state) => state
  );
  const { locationList } = useLocationStore((state) => state);

  const customerKey = new URL(window.location.href).searchParams.get(
    "customerKey"
  );
  const authKey = new URL(window.location.href).searchParams.get("authKey");
  const planMonth = new URL(window.location.href).searchParams.get("plan");

  const {
    paymentSubProductList,
    updatePaymentSubProductList,
    clearPaymentSubProductList,
  } = useSubProductPaymentStore((state) => state);

  const { mutate: createPaymentMutate } = useCreatePaymentList();

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  const getPaymentInfo = () => {
    let tempPayList: GetPaymentListResponseData[] = [...paymentSubProductList];
    const firLocInfo = locationList.find(
      (el) => el.regionId === tempPayList[0].sub_product_information.local_id
    );
    let amount: number = 0;
    let paymentName = "";

    if (tempPayList.length === 1) {
      paymentName = `[${tempPayList[0].sub_product_information.sub_product_name}] ${firLocInfo?.regionName}`;
    } else {
      paymentName = `[${
        tempPayList[0].sub_product_information.sub_product_name
      }] ${firLocInfo?.regionName} 외 ${tempPayList.length - 1}건`;
    }

    tempPayList.forEach((item, index) => {
      amount += item.final_price;
    });

    return {
      amount,
      paymentName,
    };
  };

  function insertPaymentInfo(
    paymentInfo: TossSuccessPayType,
    billingKey: string
  ) {
    let tempPayList: GetPaymentListResponseData[] = [...paymentSubProductList];
    let couponIdxList: number[] = [];

    tempPayList.forEach((item, index) => {
      tempPayList[index] = {
        ...item,
        toss_id: "",
        payment_method: paymentInfo.method,
        payment_status: paymentInfo.status,
        builing_key: billingKey,
        customer_key: customerKey || "",
        payment_information: {
          card: {
            issuerCode: paymentInfo.card.issuerCode,
            acquirerCode: paymentInfo.card.acquirerCode,
            number: paymentInfo.card.number,
            cardType: paymentInfo.card.cardType,
            ownerType: paymentInfo.card.ownerType,
          },
          mId: paymentInfo.mId,
          paymentKey: paymentInfo.paymentKey,
          orderId: paymentInfo.orderId,
          orderName: paymentInfo.orderName,
          status: paymentInfo.status,
          requestedAt: paymentInfo.requestedAt,
          totalAmount: paymentInfo.totalAmount,
          suppliedAmount: paymentInfo.suppliedAmount,
          method: paymentInfo.method,
        },
      };
      if (item.coupon_information.coupon_id) {
        if (!couponIdxList.includes(item.coupon_information.couponbox_id)) {
          couponIdxList.push(item.coupon_information.couponbox_id);
        }
      }
    });
    if (couponIdxList.length > 0) {
      const couponIdxStr = couponIdxList.join(",");
      //쿠폰 사용처리
      updateCouponBox({
        couponbox_ids: couponIdxStr,
        coupon_usage_status: "Y",
      }).then((res) => {
        createPaymentMutate(
          {
            paymentInfo: tempPayList,
          },
          {
            onSuccess: (res) => {
              updatePaymentSubProductList([...tempPayList]);
              navigate("/paymentSuccess", { replace: true });
            },
            onError: (error) => {
              console.log(error);
            },
          }
        );
      });
    } else {
      createPaymentMutate(
        {
          paymentInfo: tempPayList,
        },
        {
          onSuccess: (res) => {
            updatePaymentSubProductList([...tempPayList]);
            navigate("/paymentSuccess", { replace: true });
          },
          onError: (error) => {
            console.log(error);
          },
        }
      );
    }
  }

  async function requestBillingPay(billingData: TossGetBillingKeyType) {
    const payParams = {
      // billingKey : billingData.billingKey,
      amount: getPaymentInfo().amount,
      customerKey: billingData.customerKey,
      orderId: uuidv4(),
      orderName: getPaymentInfo().paymentName,
      customerName: salesmanager_name,
      customerEmail: email_address,
      cardInstallmentPlan: Number(planMonth),
    };

    await axios
      .post(
        `https://api.tosspayments.com/v1/billing/${billingData.billingKey}`,
        {
          ...payParams,
        },
        {
          headers: {
            Authorization: `Basic ${btoa(
              `${process.env.REACT_APP_TOSS_SECRET_KEY}:`
            )}`,
          },
        }
      )
      .then((res) => res.data)
      .then((data: TossSuccessPayType) => {
        if (data.failure !== null) {
          setAlertModal({
            visible: true,
            msg: `${data.failure.code}\n${data.failure.message}`,
            type: "go_back",
          });
        } else {
          insertPaymentInfo(data, billingData.billingKey);
        }
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;

        navigate("/paymentFail", {
          replace: true,
          state: { message: data.message, code: data.code },
        });
      });
  }

  function getBillingKey() {
    axios
      .post(
        "https://api.tosspayments.com/v1/billing/authorizations/issue",
        {
          authKey,
          customerKey,
        },
        {
          headers: {
            Authorization: `Basic ${btoa(
              `${process.env.REACT_APP_TOSS_SECRET_KEY}:`
            )}`,
          },
        }
      )
      .then((res) => {
        requestBillingPay(res.data);
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;

        navigate("/paymentFail", {
          replace: true,
          state: { message: data.message, code: data.code },
        });
      });
  }

  function alertAction() {
    if (alertModal.type === "go_back") {
      clearPaymentSubProductList();
      navigate(-1);
    }
  }

  React.useEffect(() => {
    if (paymentSubProductList.length > 0 && locationList.length > 0) {
      getBillingKey();
    }
  }, [locationList]);

  return (
    <>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
          msg={alertModal.msg}
          type={alertModal.type}
          action={alertAction}
        />
      </ModalPortal>
      <LoadingModal />
    </>
  );
};
