import { useMutation, useQuery } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError, AxiosResponse } from "axios";

interface AdminLoginParams {
  admin_account: string;
  password: string;
  platform: "PC";
}

export interface AdminLoginResponse {
  status: number; //응답
  message: string; //메세지
  data: { token: string };
}

interface AdminLoginProps {
  onSuccess: (data: AdminLoginResponse) => void;
  onError: (error: AxiosError) => void;
}

//관리자 로그인
export const useAdminLogin = ({ onSuccess, onError }: AdminLoginProps) =>
  useMutation<AdminLoginResponse, AxiosError, AdminLoginParams>({
    mutationKey: ["adminLogin"],
    mutationFn: (params: AdminLoginParams) =>
      baseAxios.post("/auth/admin/login", params).then((res) => res.data),
    onSuccess,
    onError,
  });
