import { GetUsersResponseData } from "api/blueButton/GetUsers";
import { TableContent } from "components/molecules/table/TableContent";
import { TableTitle } from "components/molecules/table/TableTitle";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import {
  formatAndMaskVariableDigits,
  maskAllButFirst,
  phoneAddHipone,
} from "utils/func";

interface CusMemberInfoProps {
  detailUserInfo: GetUsersResponseData;
  isAccecptDetail?: boolean;
}

//고객 기본정보
export const CusMemberInfo: React.FC<CusMemberInfoProps> = ({
  detailUserInfo,
  isAccecptDetail,
}) => {
  return (
    <FlexBox $isFullWidth>
      <FlexBox
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $flexDirection="row"
        $ai="center"
        $isFullWidth
      >
        <FlexBox $flex={1} $flexDirection="row" $ai="center">
          <TableTitle title={"고객명"} />
          <TableContent isColumnCenter>
            <Typograpy
              fontStyle={fontStyle.label1_reading}
              color={colors.SALLER_DETAIL_CONTENT}
            >
              {isAccecptDetail
                ? detailUserInfo.name
                : maskAllButFirst(detailUserInfo.name)}
            </Typograpy>
          </TableContent>
        </FlexBox>
        <FlexBox $flex={1.5} $flexDirection="row" $ai="center">
          <TableTitle title={"연령"} />
          <TableContent isColumnCenter>
            <Typograpy
              fontStyle={fontStyle.label1_reading}
              color={colors.SALLER_DETAIL_CONTENT}
            >
              만 {detailUserInfo.age}세
            </Typograpy>
          </TableContent>
          <TableTitle title={"구분"} />
          <TableContent isColumnCenter>
            <Box
              $py={0.2}
              $px={1.5}
              $bw={1}
              $br={2}
              $bc={
                detailUserInfo.isFinancialInfo && detailUserInfo.isInsuranceInfo
                  ? "#B85AEA"
                  : detailUserInfo.isInsuranceInfo
                  ? "#2699FB"
                  : detailUserInfo.isFinancialInfo
                  ? "#5B5FC7"
                  : ""
              }
            >
              <Typograpy
                fontStyle={fontStyle.caption1}
                color={
                  detailUserInfo.isFinancialInfo &&
                  detailUserInfo.isInsuranceInfo
                    ? "#B85AEA"
                    : detailUserInfo.isInsuranceInfo
                    ? "#2699FB"
                    : detailUserInfo.isFinancialInfo
                    ? "#5B5FC7"
                    : ""
                }
              >
                {detailUserInfo.isFinancialInfo &&
                detailUserInfo.isInsuranceInfo
                  ? "보험/금융"
                  : detailUserInfo.isInsuranceInfo
                  ? "보험"
                  : detailUserInfo.isFinancialInfo
                  ? "금융"
                  : ""}
              </Typograpy>
            </Box>

            {/* <Typograpy
              fontStyle={fontStyle.label1_reading}
              color={colors.SALLER_DETAIL_CONTENT}
            >
              만49세
            </Typograpy> */}
          </TableContent>
        </FlexBox>
      </FlexBox>

      <FlexBox
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $flexDirection="row"
        $ai="center"
        $isFullWidth
      >
        <FlexBox $flex={1} $flexDirection="row" $ai="center">
          <TableTitle title={"직장명"} />
          <TableContent isColumnCenter>
            <Typograpy
              fontStyle={fontStyle.label1_reading}
              color={colors.SALLER_DETAIL_CONTENT}
            >
              {detailUserInfo.businessName}
            </Typograpy>
          </TableContent>
        </FlexBox>
        <FlexBox $flex={1.5} $flexDirection="row" $ai="center">
          <TableTitle title={"지역"} />
          <TableContent isColumnCenter>
            <Typograpy
              fontStyle={fontStyle.label1_reading}
              color={colors.SALLER_DETAIL_CONTENT}
            >
              {detailUserInfo.address}
            </Typograpy>
          </TableContent>
        </FlexBox>
      </FlexBox>

      <FlexBox
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $flexDirection="row"
        $ai="center"
        $isFullWidth
      >
        <FlexBox $flex={1} $flexDirection="row" $ai="center">
          <TableTitle title={"종합소득"} />
          <TableContent isColumnCenter>
            <Typograpy
              fontStyle={fontStyle.label1_reading}
              color={colors.SALLER_DETAIL_CONTENT}
            >
              {detailUserInfo.totalIncome}원
            </Typograpy>
          </TableContent>
        </FlexBox>
        {isAccecptDetail && (
          <FlexBox $flex={1.5} $flexDirection="row" $ai="center">
            <TableTitle title={"휴대폰번호"} />
            <TableContent isColumnCenter>
              <Typograpy
                fontStyle={fontStyle.label1_reading}
                color={colors.SALLER_DETAIL_CONTENT}
              >
                {phoneAddHipone(detailUserInfo.phoneNumber)}
              </Typograpy>
            </TableContent>
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
};
