import { useMutation } from "@tanstack/react-query";
import { CheckTokenResponse, useCheckToken } from "./CheckToken";
import { baseAxios } from "api/baseAxios";
import { AdminInfoType, useAdminInfoStore } from "store/useAdminInfoStore";
import { UserInfoType, useUserInfoStore } from "store/userInfoStore";
import { useTokenStore } from "store/useTokenStore";
import { useGetSallerInfo } from "api/api.salesmanager";

//토큰 리프레쉬
export const useRefreshToken = () => {
  const { updateUserInfo } = useUserInfoStore((state) => state);
  const { updateAdminInfo } = useAdminInfoStore((state) => state);
  const { mutate: getSallerInfo } = useGetSallerInfo();
  const { mutate: checkTokenMutate } = useCheckToken({
    onSuccess: (data: CheckTokenResponse) => {
      if (data.data.type === "ADMIN") {
        updateAdminInfo(data.data as AdminInfoType);
      } else {
        const userInfo = data.data as UserInfoType;
        getSallerInfo(
          { salesmanager_id: userInfo.salesmanager_id },
          {
            onSuccess: (res) => {
              updateUserInfo({
                ...(data.data as UserInfoType),
                type: "SALESMANAGER",
              });
            },
          }
        );
      }
    },
    onError: () => {},
  });

  return useMutation({
    mutationKey: ["refreshToken"],
    mutationFn: () => {
      return baseAxios
        .post("/auth/renewal/token")
        .then((res) => res.data)
        .then((data) => {
          useTokenStore.getState().setToken(data.data.token);
          checkTokenMutate(data.data.token);
        });
    },
  });
};
