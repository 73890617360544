export interface DescriptionListType {
  title: string;
  subTitle1: string;
  subTitle2: string;
  description: string;
  contentsImg: string;
  isRight?: boolean;
}

//메인페이지 - 소개내용
export const descriptionList: DescriptionListType[] = [
  {
    title: "블루세일즈",
    subTitle1: `오직 계약성사를 위한 정확한 `,
    subTitle2: `고객 데이터 분석`,
    description: "블루버튼의 고객정보를 기반으로 필요한 고객을 매칭합니다.",
    contentsImg: require("assets/img/aisales_img.png"),
  },
  {
    title: "진짜 고객추천",
    subTitle1: "진짜 고객을 만나는",
    subTitle2: "이상적인 방법",
    description:
      "블루세일즈는 정확한 검증된 DB만을 제공합니다.\n블루세일즈에서 압도적 계약성사율의 고객 DB를 만나보세요",
    isRight: true,
    contentsImg: require("assets/img/template_img.png"),
  },
  {
    title: "고객데이터 제공",
    subTitle1: "고객상담의 퀄리티는 데이터가",
    subTitle2: "좌우합니다.",
    description:
      "블루세일즈에서만 만날 수 있는 정확한 고객 데이터로 정보를 무장하세요.\n계약체결에 날개를 달아드립니다.",
    contentsImg: require("assets/img/crm_img.png"),
  },
];

//메인페이지 롤링 배너 리스트
export const bannerList = [
  require("assets/img/banner1.png"),
  require("assets/img/banner2.png"),
  require("assets/img/banner3.png"),
  require("assets/img/banner4.png"),
  require("assets/img/banner5.png"),
  require("assets/img/banner6.png"),
  require("assets/img/banner7.png"),
];

export const cityList = [
  "서울특별시",
  "부산광역시",
  "대구광역시",
  "인천광역시",
  "광주광역시",
  "대전광역시",
  "울산광역시",
  "세종특별자치시",
  "경기도",
  "강원특별자치도",
  "충청북도",
  "충청남도",
  "전북특별자치도",
  "전라남도",
  "경상북도",
  "경상남도",
  "제주특별자치도",
];

//회원가입 구독플랜 카드 리스트
export interface SubscribeType {
  sub_product_id: number;
  sub_product_engname: string;
  sub_product_name: string;
  subName: string;
  benefits: string[];
  sub_product_price: number;
  sub_opt_name: string;
}

//구독 리스트
export const subscribeList = [
  {
    sub_product_id: 1, //id
    product_type: "insurances",
    sub_product_engname: "Insurance", //영문제목
    sub_product_name: "보험(일반)", //제목
    subName: "고객의 보험상품가입정보를 보고 제안을 할 수 있는 상품", //부제목
    benefits: ["영업지역 1개 선택", "영업지역 추가 1개당 99,000원"], //내용
    sub_product_price: 99000, //연간 구독가격
    sub_opt_name: "보험(일반)(+99,000)", //옵션명
  },
  {
    sub_product_id: 2,
    product_type: "finance",
    sub_product_engname: "Finace",
    sub_product_name: "금융(일반)",
    subName: "고객의 금융상품가입정보를 보고 제안을 할 수 있는 상품",
    benefits: ["영업지역 1개 선택", "영업지역 추가 1개당 99,000원"],
    sub_product_price: 99000,
    sub_opt_name: "금융(일반)(+99,000)",
  },
  {
    sub_product_id: 3,
    product_type: "premium",
    sub_product_engname: "premium",
    sub_product_name: "프리미엄",
    subName: "고객의 보험 및 금융 상품가입정보를 보고 제안을 할 수 있는 상품",
    benefits: ["영업지역 1개 선택", "영업지역 추가 1개당 198,000원"],
    sub_product_price: 198000,
    sub_opt_name: "프리미엄(+198,000)",
  },
];

export interface AdminSubMenuListType {
  [key: string]: { label: string; path: string }[];
}

//설계사 admin 서브메뉴 리스트
export const adminSubMenuList: AdminSubMenuListType = {
  home: [],
  customer: [
    // { label: "신규고객 리스트", path: "new" },
    { label: "나의 고객 리스트", path: "my" },
  ],
  proposal: [
    { label: "발송 고객 리스트", path: "wait" },
    { label: "수락 고객 리스트", path: "accept" },
    { label: "거절 고객 리스트", path: "reject" },
  ],
  myInfo: [
    { label: "기본정보", path: "info" },
    { label: "쿠폰관리", path: "coupon" },
    { label: "구독&결제 정보", path: "subscribe" },
    { label: "결제내역 관리", path: "paymentList" },
    { label: "패스워드 변경", path: "changePassword" },
    { label: "문의하기", path: "qna" },
  ],
};

//설계사 admin 서브메뉴 리스트
export const companyAdminSubMenuList: AdminSubMenuListType = {
  home: [],
  customer: [
    { label: "신규고객 리스트", path: "new" },
    { label: "나의 고객 리스트", path: "my" },
  ],
  proposal: [
    { label: "발송 고객 리스트", path: "send" },
    { label: "수락 고객 리스트", path: "accept" },
    { label: "거절 고객 리스트", path: "refuse" },
  ],
  myInfo: [
    { label: "기본정보", path: "info" },
    { label: "구독&결제 정보", path: "subscribe" },
    { label: "패스워드 변경", path: "changePassword" },
  ],
};

//약관 리스트
export const agreeMenuList = [
  {
    label: "개인정보 처리방침",
    path: "privacy",
  },
  {
    label: "서비스 이용약관",
    path: "service",
  },
  {
    label: "취소 및 환불약관",
    path: "refund",
  },
  {
    label: "마케팅 이용약관",
    path: "marketing",
  },
];

export const joinAgreeList = [
  {
    label: "서비스 이용약관 동의",
    type: "service",
    require: true,
  },
  {
    label: "개인정보 수집 및 이용 동의",
    type: "privacy",
    require: true,
  },
  {
    label: "취소 및 환불, 청약철회 규정",
    type: "refund",
    require: true,
  },
  {
    label: "만 14세 이상입니다",
    type: "age",
    require: true,
  },
  {
    label: "마케팅 목적의 개인정보 수집 및 이용 동의",
    type: "marketing",
    require: false,
  },
  {
    label: "해택 및 광고성 정보 수신 동의",
    type: "benefits",
    require: false,
  },
];

export const cardInstallmentPlanList = [
  { label: "일시불", value: 0 },
  { label: "2개월", value: 2 },
  { label: "3개월", value: 3 },
  { label: "4개월", value: 4 },
  { label: "5개월", value: 5 },
  { label: "6개월", value: 6 },
  { label: "7개월", value: 7 },
  { label: "8개월", value: 8 },
  { label: "9개월", value: 9 },
  { label: "10개월", value: 10 },
  { label: "11개월", value: 11 },
  { label: "12개월", value: 12 },
];
