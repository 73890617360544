import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError, AxiosResponse } from "axios";

interface EditSubproductParams {
  sub_product_id: number;
}

export interface CreateSubProductParamsWithType {
  sub_product_name: string;
  sub_product_subname: string;
  sub_product_content: string;
  sub_product_price: number;
  sub_product_optname: string;
  product_type: string;
  validity_term: number;
  is_show: string;
  created_at?: number;
  updated_at?: number;
  sub_product_id?: number;
}

export interface UpdateSubProductParamsWithType {
  sub_product_name?: string;
  sub_product_subname?: string;
  sub_product_content?: string;
  sub_product_price?: number;
  sub_product_optname?: string;
  product_type?: string;
  validity_term?: number;
  is_show?: string;
  created_at?: number;
  updated_at?: number;
}

export interface CreateSubProductResponse {
  status: number; //응답
  message: string; //메세지
  data: CreateSubProductParamsWithType;
}

export interface GetSubProductListResponse {
  status: number; //응답
  message: string; //메세지
  data: CreateSubProductParamsWithType[];
}

//구독상품 생성
export const useCreateSubProduct = () =>
  useMutation<
    CreateSubProductResponse,
    AxiosError,
    CreateSubProductParamsWithType
  >({
    mutationKey: ["createSubproduct"],
    mutationFn: (params: CreateSubProductParamsWithType) =>
      baseAxios
        .post("/sub-product", params)
        .then((res) => res.data)
        .catch((err) => console.log(err)),
  });

//구독상품 리스트 불러오기
export const useGetSubProductList = () =>
  useQuery<GetSubProductListResponse, AxiosError>({
    queryKey: ["subProductList"],
    queryFn: () => baseAxios.get("sub-product").then((res) => res.data),
  } as UseQueryOptions<GetSubProductListResponse, AxiosError>);

//공지사항 수정
export const useEditSubproduct = ({ sub_product_id }: EditSubproductParams) =>
  useMutation<
    AxiosResponse<CreateSubProductResponse>,
    AxiosError,
    UpdateSubProductParamsWithType
  >({
    mutationKey: ["editSubproduct"],
    mutationFn: (params: UpdateSubProductParamsWithType) =>
      baseAxios
        .put(`/sub-product/${sub_product_id}`, { ...params })
        .then((res) => {
          return res.data;
        }),
  });
