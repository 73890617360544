import { IcPlus } from "components/atoms/icon/icon";
import Typograpy from "components/molecules/Typograpy";
import { ToggleSubSideBarType } from "page/static/SallerStaticArea";
import React from "react";
import { useLocation } from "react-router";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import {
  adminSubMenuList,
  AdminSubMenuListType,
  companyAdminSubMenuList,
} from "utils/list";

interface SubSideBarPropsType {
  toggleSubSideBar: ToggleSubSideBarType; //메인사이드 정보
  setToggleSubSideBar: ({ visible, label }: ToggleSubSideBarType) => void;
  selSubPage: string; //현재 서브페이지
  setSelSubPage: (subPage: string) => void; //현재 서브페이지 설정
  adminType?: "admin" | "sales";
}

export const SubSideBar: React.FC<SubSideBarPropsType> = ({
  toggleSubSideBar,
  setToggleSubSideBar,
  selSubPage,
  setSelSubPage,
  adminType,
}) => {
  const location = useLocation();
  const currentPath = location.pathname.replace(
    adminType === "admin" ? /^\/admin\/?/ : /^\/saller\/?/,
    ""
  );

  const menuList = adminSubMenuList[currentPath];

  return (
    <FlexBox
      $bgcolor={colors.SALLER_SUBSIDE_BG}
      height={"100%"}
      $flex={2}
      $isFullWidth
      $flexDirection="row"
      $maxWidth={32}
      width={"100%"}
      className={`sub-side-wrapper`}
    >
      <FlexBox $flex={1}>
        <FlexBox
          height={6}
          $flexDirection="row"
          $ai="center"
          $jc="space-between"
          $px={2}
          $bbw={1}
          $bbc={colors.DEFAULT_BORDER_COLOR}
          $isFullWidth
        >
          <Typograpy
            fontStyle={fontStyle.headline2}
            fontWeight="Bold"
            color={colors.SALLER_SUBSIDE_TITLE}
          >
            {toggleSubSideBar.label}
          </Typograpy>
        </FlexBox>

        <FlexBox $minWidth={25} $px={2} $gap={0.4} $mt={2}>
          {menuList.map((item, index) => {
            if (selSubPage === item.path) {
              return (
                <Box
                  key={"subSideMenu" + index}
                  $br={4}
                  $px={1.6}
                  $py={0.8}
                  $bgcolor={colors.WHITE}
                  $shadowType="normal"
                  $isFullWidth
                  $isPointer
                >
                  <Typograpy
                    fontStyle={fontStyle.label1_normal}
                    fontWeight="Bold"
                    color={colors.SALLER_SUBSIDE_TITLE}
                  >
                    {item.label}
                  </Typograpy>
                </Box>
              );
            } else {
              return (
                <Box
                  $isPointer
                  $px={1.6}
                  $py={0.8}
                  key={"subMenuList" + index}
                  onClick={() => {
                    setSelSubPage(item.path);
                  }}
                >
                  <Typograpy
                    fontStyle={fontStyle.label1_normal}
                    color={colors.SALLER_SUBSIDE_TITLE}
                  >
                    {item.label}
                  </Typograpy>
                </Box>
              );
            }
          })}
        </FlexBox>
      </FlexBox>
      <Box
        className={"sub-side-gradient"}
        width={0.8}
        height={"100%"}
        $position={"absolute"}
        $right={0}
      />
    </FlexBox>
  );
};
