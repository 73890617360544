import { useGetCouponList } from "api/api.coupon";
import { useRefreshToken } from "api/auth/RefreshToken";
import { useGetLocation } from "api/blueButton/getLocationList";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { MasterRouter } from "router/MasterRouter";
import { useCouponStore } from "store/useCouponStore";
import { useLocationStore } from "store/useLocationStore";
import { useTokenStore } from "store/useTokenStore";

export const Router = () => {
  const { mutate: refreshToken, data } = useRefreshToken();
  const { token } = useTokenStore((state) => state);

  const { data: localData, refetch: localRefetch } = useGetLocation();
  useGetCouponList();
  const { data: couponData } = useGetCouponList();

  const { updateLocationList } = useLocationStore((state) => state);
  const { updateCouponList } = useCouponStore((state) => state);

  //지역데이터 설정
  React.useEffect(() => {
    if (localData) {
      if (localData.success) {
        updateLocationList([...localData.response]);
      }
    } else {
      localRefetch();
    }
  }, [localData]);

  //쿠폰리스트 설정
  React.useEffect(() => {
    if (couponData) {
      if (couponData.status === 200) {
        updateCouponList([...couponData.data]);
      }
    }
  }, [couponData]);

  return <RouterProvider router={MasterRouter} />;
};
