import { ReactComponent as SolidLoadingIcon } from "assets/animated/solid_loading.svg";
import { ReactComponent as OutlineLoadingIcon } from "assets/animated/outline_loading.svg";
import styled from "styled-components";

interface IconPropsType {
  width?: number;
  height?: number;
}

//border 있는 x 아이콘
const IconSolidLoading = styled(SolidLoadingIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2.4rem")};
  height: ${({ height }) => (height ? height + "rem" : "2.4rem")};
`;

//border 있는 x 아이콘
const IconOutlineLoading = styled(OutlineLoadingIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2.4rem")};
  height: ${({ height }) => (height ? height + "rem" : "2.4rem")};
`;

export { IconSolidLoading, IconOutlineLoading };
