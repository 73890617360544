import { IcWhiteArrow } from "components/atoms/icon/icon";
import Typograpy from "components/molecules/Typograpy";
import { color } from "framer-motion";
import { ToggleSubSideBarType } from "page/static/SallerStaticArea";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";

interface AdminMainSideBarPropsType {
  toggleSubSideBar: ToggleSubSideBarType;
  setToggleSubSideBar: ({ visible, label }: ToggleSubSideBarType) => void;
  adminType?: "admin" | "sales";
}

const SideIconWrapper = styled(Box)<{ $isSel?: boolean }>`
  ${({ $isSel }) =>
    $isSel &&
    `
    transform : rotate(-90deg);
  `}
  transition : all 0.3s;
`;

export const AdminMainSideBar: React.FC<AdminMainSideBarPropsType> = ({
  toggleSubSideBar,
  setToggleSubSideBar,
  adminType = "admin",
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.replace(/^\/admin\/?/, "");

  const menuList = [
    {
      label: "대시보드",
      path: "home",
      // subMenu: [
      //   { label: "통계", path: "statistics" },
      //   { label: "현황", path: "situation" },
      // ],
    },
    {
      label: "상담사관리",
      path: "sallerList",
    },

    {
      label: "설정",
      path: "env",
    },
    {
      label: "공지사항 관리",
      path: "notice",
    },
    {
      label: "쿠폰관리",
      path: "coupon",
    },
    {
      label: "문의사항",
      path: "qna",
    },
  ];
  return (
    <FlexBox $minWidth={25} $bgcolor={colors.ADMIN_SIDEBAR}>
      <FlexBox
        $isFullWidth
        height={6.8}
        $flexDirection="row"
        $ai={"center"}
        $jc={"center"}
      >
        <Box
          $isPointer
          onClick={() => {
            navigate("/admin/home");
          }}
        >
          <Typograpy
            color={colors.WHITE}
            fontWeight="Bold"
            fontStyle={fontStyle.heading1}
          >
            Bluesales{" "}
            <Typograpy color={colors.WHITE} fontStyle={fontStyle.headline1}>
              Admin
            </Typograpy>
          </Typograpy>
        </Box>
      </FlexBox>
      <FlexBox $flex={1} height={"100%"} $ai="center" $isFullWidth>
        {menuList.map((item, index) => {
          return (
            <FlexBox $isFullWidth key={"adminMenu" + index}>
              <FlexBox
                $px={2}
                $py={1.6}
                $isPointer
                $isFullWidth
                $bgcolor={
                  currentPath === item.path ? colors.ADMIN_SIDEBAR_SEL : "none"
                }
                $jc={"space-between"}
                $ai={"center"}
                $flexDirection="row"
                onClick={() => {
                  navigate(item.path);
                }}
              >
                <Typograpy
                  fontStyle={fontStyle.label1_normal}
                  color={colors.WHITE}
                >
                  {item.label}
                </Typograpy>

                <SideIconWrapper $isSel={currentPath === item.path}>
                  <IcWhiteArrow width={1.5} height={1} />
                </SideIconWrapper>
              </FlexBox>
              {/* {item.subMenu && (
                <FlexBox $isFullWidth $px={2} $gap={1.2} $mt={1.2}>
                  {item.subMenu.map((menu, index) => {
                    return (
                      <FlexBox key={"submenu" + index} $isFullWidth $px={2}>
                        <Typograpy color={colors.WHITE}>{menu.label}</Typograpy>
                      </FlexBox>
                    );
                  })}
                </FlexBox>
              )} */}
            </FlexBox>
          );
        })}
      </FlexBox>
    </FlexBox>
  );
};
