import { SallerHeader } from "components/organisms/header/saller/SallerHeader";
import { MainSideBar } from "components/organisms/sidebar/saller/MainSideBar";
import { SubSideBar } from "components/organisms/sidebar/saller/SubSideBar";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FlexBox } from "theme/globalStyle";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import { AdminSubMenuListType } from "utils/list";
import { FooterTab } from "components/organisms/footer/FooterTab";
import { NoticeBar } from "components/organisms/footer/NoticeBar";
import { CompanyInfo } from "components/organisms/footer/CompanyInfo";
import { useTokenStore } from "store/useTokenStore";
import { useUserInfoStore } from "store/userInfoStore";
import ModalPortal from "utils/Portal";
import { AlertModal } from "components/organisms/modal/AlertModal";

export interface ToggleSubSideBarType {
  visible: boolean;
  label: keyof AdminSubMenuListType;
}

export const SallerStaticArea = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname.replace(/^\/saller\/?/, "");

  const { token } = useTokenStore((state) => state);
  const { salesmanager_id, payment } = useUserInfoStore((state) => state);

  const [toggleSubSideBar, setToggleSubSideBar] =
    React.useState<ToggleSubSideBarType>({
      visible: false,
      label: "홈",
    });

  const [selSubPage, setSelSubPage] = React.useState("");

  const variants = {
    //subside 토글
    hidden: { width: 0, opacity: 0 },
    visible: { width: "auto", opacity: 1 },
  };

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  const alertModalOn = (msg: string, type?: string) => {
    setAlertModal({
      visible: true,
      msg,
      type: type || "",
    });
  };

  const alertAction = () => {
    if (alertModal.type === "go_payment") {
      navigate("/payment", { replace: true });
    }
  };

  React.useEffect(() => {
    if (!token || !salesmanager_id) {
      navigate("/");
    } else if (token && salesmanager_id && payment.length === 0) {
      navigate("/saller/myInfo");
    } else {
      if (currentPath === "") {
        navigate("/saller/customer");
      }
    }
  }, []);

  React.useEffect(() => {
    if (currentPath === "" || currentPath === "home") {
      setSelSubPage("");
    } else if (currentPath === "customer") {
      setSelSubPage("my");
    } else if (currentPath === "proposal") {
      setSelSubPage("wait");
    } else if (currentPath === "myInfo") {
      setSelSubPage("info");
    }
  }, [currentPath]);

  return (
    <LayoutGroup>
      <FlexBox $isFullWidth height={"100%"} $minHeight={"100vh"}>
        <ModalPortal>
          <AlertModal
            visible={alertModal.visible}
            msg={alertModal.msg}
            type={alertModal.type}
            action={alertAction}
            modalOff={() => {
              setAlertModal({ visible: false, msg: "", type: "" });
            }}
          />
        </ModalPortal>
        <SallerHeader />
        <FlexBox
          $flexDirection="row"
          $ai={"stretch"}
          $flex={1}
          $isFullWidth
          height={"100%"}
        >
          <MainSideBar
            toggleSubSideBar={toggleSubSideBar}
            setToggleSubSideBar={setToggleSubSideBar}
            adminType="sales"
          />
          <AnimatePresence>
            {toggleSubSideBar.visible &&
              currentPath !== "home" &&
              currentPath !== "customer" && (
                <motion.div
                  variants={variants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  style={{
                    width: "100%",
                    maxWidth: "32rem",
                  }}
                  layout
                >
                  <SubSideBar
                    toggleSubSideBar={toggleSubSideBar}
                    setToggleSubSideBar={setToggleSubSideBar}
                    setSelSubPage={setSelSubPage}
                    selSubPage={selSubPage}
                  />
                </motion.div>
              )}
          </AnimatePresence>
          <FlexBox
            $isFullWidth
            $flexDirection="row"
            height={"100%"}
            $minHeight={"100%"}
          >
            {/* {payment.length > 0 && ( */}
            <Outlet
              context={{
                selSubPage: selSubPage,
              }}
            />
            {/* )} */}
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <NoticeBar />
      {/* <FooterTab /> */}
      <CompanyInfo />
    </LayoutGroup>
  );
};
