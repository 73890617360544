import { GetLocationResponseData } from "api/blueButton/getLocationList";
import { create } from "zustand";

interface LocationStoreType {
  locationList: GetLocationResponseData[];
  updateLocationList: (locationList: GetLocationResponseData[]) => void;
}

export const useLocationStore = create<LocationStoreType>()((set) => ({
  locationList: [],
  updateLocationList: (locationList: GetLocationResponseData[]) =>
    set({ locationList: [...locationList] }),
}));
