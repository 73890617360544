import { IcClose } from "components/atoms/icon/icon";
import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import { NoticeCreate } from "components/templates/admin/AdminNoticeTemplate/NoticeCreate";
import { NoticeDetail } from "components/templates/admin/AdminNoticeTemplate/NoticeDetail";
import React from "react";
import Modal from "react-modal";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { CommonModalType } from "./commonModalType";
import useScrollLock from "hooks/useScrollLock";
import { CommonModal } from "./CommonModal";

interface NoticeModalPropsType extends CommonModalType {
  type: "" | "create" | "detail";
  noticeId?: number;
}

export const NoticeModal: React.FC<NoticeModalPropsType> = ({
  visible,
  modalOff,
  type,
  noticeId,
}) => {
  //modal 활성화시 body 스크롤 lock
  useScrollLock(visible);

  return (
    <CommonModal visible={visible} modalOff={modalOff}>
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc={"space-between"}
        $ai={"center"}
        $px={3}
      >
        <Typograpy fontStyle={fontStyle.heading2}>
          {type === "create"
            ? "새 공지사항"
            : type === "detail"
            ? "상세보기"
            : "편집"}
        </Typograpy>
        <Box $isPointer onClick={modalOff}>
          <IcClose />
        </Box>
      </FlexBox>
      <Box $isFullWidth $my={2}>
        <Divider variant="normal" width={"100%"} />
      </Box>
      {type === "create" && <NoticeCreate modalOff={modalOff} />}
      {type === "detail" && noticeId && (
        <NoticeDetail modalOff={modalOff} notice_id={noticeId} />
      )}
    </CommonModal>
  );
};
