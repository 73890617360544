import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { customLegend } from "./CustomLegend";
import { NumberComma } from "utils/func";

interface BarChartComponentPorpsType {
  dataList: { [key: string]: number | string }[];
  dataKeyList: { dataKey: string; fill: string }[];
}

export const LineChartComponent: React.FC<BarChartComponentPorpsType> =
  React.memo(({ dataList, dataKeyList }) => {
    return (
      // <ResponsiveContainer width="100%" height="100%">
      //   <BarChart
      //     style={{ width: "100%", height: "100%" }}
      //     data={dataList}
      //     margin={{
      //       top: 5,
      //       right: 30,
      //       left: 20,
      //       bottom: 5,
      //     }}
      //   >
      //     <CartesianGrid strokeDasharray="3 3" />
      //     <XAxis dataKey="name" />
      //     <YAxis />
      //     <Tooltip />
      //     <Legend />
      //     {dataKeyList.map((item, index) => {
      //       <Bar
      //         dataKey={item.dataKey}
      //         fill={item.fill}
      //         key={"barchart-" + item.dataKey + index}
      //       />;
      //     })}
      //   </BarChart>
      // </ResponsiveContainer>

      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={dataList}
          margin={{
            top: 5,
            right: 30,
            bottom: 5,
            left: 30,
          }}
        >
          <CartesianGrid strokeDasharray="0" vertical={false} />
          <XAxis
            dataKey="name"
            tick={{ fill: "#616161", fontWeight: "Regular", fontSize: "1rem" }}
          />
          <YAxis
            onError={() => {}}
            tickFormatter={(value) => NumberComma(Number(value))}
          />
          <Tooltip
            formatter={(value) => {
              return NumberComma(Number(value)) + "원";
            }}
          />
          <Legend align="center" content={customLegend} />
          {dataKeyList.map((item, index) => {
            return (
              // <Bar
              //   key={"lineChart" + item.dataKey + index}
              //   dataKey={item.dataKey}
              //   fill={item.fill}
              //   // shape={<CustomBar />}
              // />
              <Line
                key={"lineChart" + item.dataKey + index}
                type="monotone"
                dataKey={item.dataKey}
                stroke={item.fill}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    );
  });
