import React from "react";
import { IcEyeOff, IcEyeOn } from "components/atoms/icon/icon";
import { SolidButton } from "components/molecules/button/SolidButton";
import { Divider } from "components/molecules/divider/Divider";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { Label } from "components/molecules/label/Label";
import { Radio } from "components/molecules/radio/Radio";
import { InputHeader } from "components/organisms/user/InputHeader";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import { colors } from "theme/colors";
import { Box, FlexBox } from "theme/globalStyle";
import { SelectBox } from "components/molecules/selectbox/SelectBox";
import { joinAgreeList } from "utils/list";
import { SalesInfoType, JoinInfoType } from "page/SignUpPage";
import {
  convertUnixDate,
  generateRandomSixDigitNumber,
  getOtpNumber,
  getUnixDate,
  idCheck,
  phoneAddHipone,
  renderDayOptions,
  renderMonthOptions,
  renderYearOptions,
} from "utils/func";
import { PostCodeModal } from "components/organisms/modal/PostCodeModal";
import ModalPortal from "utils/Portal";
import { useSnackbarStore } from "store/snackbarStore";
import Typograpy from "components/molecules/Typograpy";
import { fontStyle } from "theme/fontStyle";
import { useDuplicateCheck } from "api/auth/DuplicateCheck";
import { SolidCheckbox } from "components/molecules/checkbox/SolidCheckkbox";
import { AlertModal } from "components/organisms/modal/AlertModal";
import { AxiosError, AxiosResponse } from "axios";
import { AgreeModal } from "components/organisms/modal/AgreeModal";
import { useLocationStore } from "store/useLocationStore";
import { useSendEmail } from "api/api.email";
import { CreateSallerResponse, useCreateSaller } from "api/api.salesmanager";
import { useTokenStore } from "store/useTokenStore";
import { verifySms } from "api/auth/nice/verifySms";
import { getEmailAuthTemplate } from "utils/emailtemplate";

interface ContextJoinType {
  joinInfo: JoinInfoType; //기본정보
  setJoinInfo: (joinInfo: JoinInfoType) => void;
  salesInfo: SalesInfoType; //영업정보
  setSalesInfo: (salesInfo: SalesInfoType) => void;
}

interface InputErrorType {
  id: boolean;
  password: boolean;
  password_re: boolean;
  name: boolean;
  // email: boolean;
  // email_code: boolean;
  phone: boolean;
  phone_code: boolean;
  birth: boolean;
  address: boolean;
  sales_group: boolean;
  sales_email: boolean;
  sales_email_code: boolean;
  sales_tel: boolean;
  sales_tel_code: boolean;
  sales_address: boolean;
  sales_location: boolean;
  sales_item: boolean;
}

interface AgreeModalType {
  visible: boolean;
  type: "service" | "privacy" | "refund" | "marketing";
}

//유저정보 입력 및 회원가입
export const WirteUserInfo = () => {
  const navigate = useNavigate();
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);
  const { niceToken } = useTokenStore((state) => state);

  const location = useLocation();

  //sns로그인시 회원가입이 되어있지 않은 상태일경우 필요한 데이터 state
  const { state: socialState } = location;

  const { joinInfo, setJoinInfo, salesInfo, setSalesInfo } =
    useOutletContext<ContextJoinType>();

  //중복검사
  const { mutate: duplicateMutate, isPending: duplicatePending } =
    useDuplicateCheck();

  //이메일 전송
  const { mutate: sendEmailMutate, isPending: sendEmailPending } =
    useSendEmail();

  const { locationList } = useLocationStore((state) => state);

  //회원가입
  const {
    mutate: joinMutate,
    isPending: joinLoading,
    isError: joinError,
  } = useCreateSaller({
    onSuccess: createSallerSuccess,
    onError: createSallerFail,
  });

  const [inputError, setInputError] = React.useState<InputErrorType>({
    id: false,
    password: false,
    password_re: false,
    name: false,
    phone: false,
    phone_code: false,
    birth: false,
    address: false,

    sales_group: false,
    sales_email: false,
    sales_email_code: false,
    sales_tel: false,
    sales_tel_code: false,
    sales_address: false,
    sales_location: false,
    sales_item: false,
  });

  const [isInputShow, setIsInputShow] = React.useState({
    password: false,
    password_re: false,
  });
  const [isCapslock, setIsCapslock] = React.useState(false);

  const [agreeList, setAgreeList] = React.useState<string[]>([]);

  const [agreeModal, setAgreeModal] = React.useState<AgreeModalType>({
    visible: false,
    type: "service",
  });
  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  //주소검색 모달
  const [postCodeModal, setPostCodeModal] = React.useState(false);

  //영업자 주소검색 모달
  const [salesPostCodeModal, setSalesPostCodeModal] = React.useState(false);

  //이메일 인증번호 저장
  const [emailAuthCode, setEmailAuthCode] = React.useState("");
  const [companyEmailAuthCode, setCompanyEmailAuthCode] = React.useState("");

  //휴대폰번호 인증번호 저장
  const [salesPhoneAuthCode, setSalesPhoneAuthCode] = React.useState("");

  //기본정보 입력
  function inputJoinInfoHandler(value: string, key?: string) {
    if (key) {
      setJoinInfo({
        ...joinInfo,
        [key]: value,
      });
    }
  }

  //영업정보 입력
  function inputSalesInfoHandler(value: string, key?: string) {
    if (key) {
      setSalesInfo({
        ...salesInfo,
        [key]: value,
      });
    }
  }

  //아이디 중복확인
  function idDuplicateCheck() {
    if (inputError.id) {
    } else {
      if (joinInfo.id === "") {
        setInputError((prev) => ({ ...prev, id: true }));
      } else {
        duplicateMutate(
          {
            salesmanager_account: joinInfo.id,
            type: "SALESMANAGER",
          },
          {
            onSuccess: (res) => {
              const { data, status } = res;
              if (data.data) {
                setJoinInfo({ ...joinInfo, id_check: true });
              } else {
                showSnackbar("중복된 아이디입니다.");
              }
            },
          }
        );
      }
    }
  }

  //이메일 중복확인
  function eamilDuplicateCheck() {
    if (inputError.sales_email) {
    } else {
      if (salesInfo.email === "") {
        setInputError((prev) => ({ ...prev, sales_email: true }));
      } else {
        duplicateMutate(
          {
            email_address: salesInfo.email,
            type: "SALESMANAGER",
          },
          {
            onSuccess: (res) => {
              const { data, status } = res;
              if (data.data) {
                setSalesInfo({ ...salesInfo, email_check: true });
              } else {
                showSnackbar("중복된 이메일입니다.");
              }
            },
          }
        );
      }
    }
  }

  //이메일 인증번호 발송
  function sendEamilAuthCode() {
    const sendHandler = () => {
      const randomCode = generateRandomSixDigitNumber();
      sendEmailMutate(
        {
          to_email: salesInfo.email,
          subject: "[블루라이언스] 이메일 인증을 완료해주세요,",
          html: getEmailAuthTemplate(randomCode),
        },
        {
          onSuccess: (res) => {
            const { data, status } = res;

            if (data.data) {
              showSnackbar("인증번호 발송이 완료되었습니다.");
              setCompanyEmailAuthCode(randomCode);
            }
          },
        }
      );
    };

    if (salesInfo.email === "" || !idCheck(salesInfo.email).result) {
      setInputError((prev) => ({ ...prev, sales_email: true }));
    } else {
      sendHandler();
    }
  }

  //이메일 인증번호 확인
  function checkEamilAuthCode() {
    if (
      salesInfo.email_code === "" ||
      salesInfo.email_code !== companyEmailAuthCode
    ) {
      setInputError((prev) => ({ ...prev, sales_email_code: true }));
    } else {
      eamilDuplicateCheck();
      // setSalesInfo({ ...salesInfo, email_check: true });
    }
  }

  //휴대폰 인증
  function verifySmsHandler() {
    console.log(salesInfo.tel);
    if (salesInfo.tel === "") {
      setInputError({
        ...inputError,
        sales_tel: true,
      });
    } else {
      const smsCode = String(getOtpNumber());

      const params = {
        access_token: niceToken,
        mobile_num: salesInfo.tel,
        sms_msg: `[블루세일즈] 휴대폰인증번호는 [${smsCode}]입니다.`,
        callback_num: smsCode,
      };
      verifySms(params).then((res) => {
        setSalesPhoneAuthCode(smsCode);
      });
    }
  }

  function authSmsCode() {
    if (salesInfo.tel === "") {
      setInputError({
        ...inputError,
        sales_tel: true,
      });
    } else {
      if (salesInfo.tel_code !== salesPhoneAuthCode) {
        setInputError({
          ...inputError,
          sales_tel_code: true,
        });
      } else {
        setSalesInfo({
          ...salesInfo,
          tel_check: true,
        });
      }
    }
  }

  //지역 선택
  function locationHandler(location: string, index: number) {
    let isOverwrap = false;
    salesInfo.location_list.map((item, index) => {
      if (item.location_name === location && !isOverwrap) {
        showSnackbar("이미 추가되어있는 지역입니다.");
        isOverwrap = true;
      }
    });
    if (!isOverwrap) {
      let baseLocationList = [...salesInfo.location_list];
      baseLocationList[index].location_name = location;

      setSalesInfo({
        ...salesInfo,
        location_list: [...baseLocationList],
      });
      setInputError((prev) => ({ ...prev, sales_location: false }));
    }
  }

  //지역 추가
  function addLocation() {
    setSalesInfo({
      ...salesInfo,
      location_list: [
        ...salesInfo.location_list,
        {
          location_name: "",
        },
      ],
    });
  }

  //약관체크
  function checkAgree(value: string) {
    let beforeAgreeList = [...agreeList];

    if (beforeAgreeList.includes(value)) {
      setAgreeList([...beforeAgreeList.filter((el) => el !== value)]);
    } else {
      setAgreeList([...beforeAgreeList, value]);
    }
  }

  //모두동의 handler
  const allCheckAgree = React.useCallback(() => {
    let isAllCheck = true;

    joinAgreeList.map((item) => {
      if (!agreeList.includes(item.type)) {
        isAllCheck = false;
      }
    });

    if (isAllCheck) {
      setAgreeList([]);
    } else {
      setAgreeList(joinAgreeList.map((item) => item.type));
    }
  }, [agreeList]);

  //영업사원 생성 완료
  function createSallerSuccess(data: AxiosResponse<CreateSallerResponse>) {
    showSnackbar("회원가입이 완료되었습니다.");
    navigate("/login");
  }

  //영업사원 생성 실패
  function createSallerFail(error: AxiosError) {
    console.log(error);
    setAlertModal({
      visible: true,
      msg: "회원가입중 오류가 발생하였습니다.",
      type: "",
    });
  }

  //입력 검증
  function validateInput() {
    const requireAgreeList = joinAgreeList.filter((el) => el.require);

    let agreeFlag = true;

    requireAgreeList.forEach((item) => {
      if (!agreeList.includes(item.type)) {
        agreeFlag = false;
      }
    });

    if (joinInfo.id.trim() === "") {
      setInputError((prev) => ({ ...prev, id: true }));
      return false;
    } else if (!joinInfo.id_check && !socialState) {
      showSnackbar("아아디 중복확인을 해주세요.");
      return false;
    } else if (joinInfo.password.trim() === "" && !socialState) {
      setInputError((prev) => ({ ...prev, password: true }));
      return false;
    } else if (joinInfo.password_re !== joinInfo.password && !socialState) {
      setInputError((prev) => ({ ...prev, password_re: true }));
      return false;
    } else if (joinInfo.name.trim() === "") {
      setInputError((prev) => ({ ...prev, name: true }));
      return false;
    } else if (
      joinInfo.year === "" ||
      joinInfo.month === "" ||
      joinInfo.day === ""
    ) {
      setInputError((prev) => ({ ...prev, birth: true }));
    } else if (joinInfo.zip === 0 || joinInfo.address === "") {
      setInputError((prev) => ({ ...prev, address: true }));
    } else if (salesInfo.group.trim() === "") {
      setInputError((prev) => ({ ...prev, sales_group: true }));
    } else if (
      salesInfo.email.trim() === "" ||
      !idCheck(salesInfo.email).result
    ) {
      setInputError((prev) => ({ ...prev, sales_email: true }));
    } else if (!salesInfo.email_check) {
      showSnackbar("이메일 인증을 진행해주세요.");
    } else if (salesInfo.address.trim() === "" || salesInfo.zip === 0) {
      setInputError((prev) => ({ ...prev, sales_address: true }));
    } else if (salesInfo.tel === "") {
      setInputError((prev) => ({ ...prev, sales_tel: true }));
    }
    // else if (!salesInfo.tel_check) {
    //   showSnackbar("휴대폰 인증을 진행해주세요.");
    // }
    else if (
      salesInfo.location_list.filter((el) => el.location_name !== "").length ===
      0
    ) {
      setInputError((prev) => ({ ...prev, sales_location: true }));
    } else if (!agreeFlag) {
      setAlertModal({
        visible: true,
        msg: "필수 이용약관을 동의하셔야 서비스 이용이 가능합니다.",
        type: "",
      });
    } else {
      const locIdList = salesInfo.location_list.map((item, index) => {
        const filterData = locationList.filter(
          (el) => el.regionName === item.location_name
        )[0];
        return { local_id: filterData.regionId };
      });

      const params = {
        salesmanager_name: joinInfo.name,
        kakao_id:
          socialState && socialState.type === "kakao"
            ? socialState.socialId
            : "",
        naver_id:
          socialState && socialState.type === "naver"
            ? socialState.socialId
            : "",
        salesmanager_account: joinInfo.id,
        salesmanager_password: joinInfo.password,
        zip_code: Number(joinInfo.zip),
        salesmanager_address: joinInfo.address,
        salesmanager_address_detail: joinInfo.address_detail,
        salesmanager_birthday: getUnixDate(
          joinInfo.year,
          joinInfo.month.padStart(2, "0"),
          joinInfo.day.padStart(2, "0")
        ),
        salesmanager_gender: joinInfo.gender,
        sales_phone: phoneAddHipone(salesInfo.tel),
        email_address: salesInfo.email,
        sales_area: locIdList,
        company_name: salesInfo.group,
        company_zip_code: Number(salesInfo.zip),
        company_address: salesInfo.address,
        company_address_detail: salesInfo.address_detail,
        sales_item: salesInfo.sales_item,
      };
      joinMutate(params);
    }
  }

  return (
    <FlexBox $ai="center" $isFullWidth>
      <ModalPortal>
        <PostCodeModal
          visible={postCodeModal}
          modalOff={() => {
            setPostCodeModal(false);
          }}
          onChange={(data) => {
            setPostCodeModal(false);
            setJoinInfo({
              ...joinInfo,
              zip: data.zonecode,
              address: data.roadAddress,
            });
            setInputError((prev) => ({ ...prev, address: false }));
          }}
        />
      </ModalPortal>
      <ModalPortal>
        <AgreeModal
          visible={agreeModal.visible}
          type={agreeModal.type}
          agreeAction={(type: string) => {
            setAgreeList((prev) => [...prev, type]);
          }}
          modalOff={() => {
            setAgreeModal({ visible: false, type: "service" });
          }}
        />
      </ModalPortal>
      <ModalPortal>
        <PostCodeModal
          visible={salesPostCodeModal}
          modalOff={() => {
            setSalesPostCodeModal(false);
          }}
          onChange={(data) => {
            setSalesPostCodeModal(false);
            setSalesInfo({
              ...salesInfo,
              zip: data.zonecode,
              address: data.roadAddress,
            });
            setInputError((prev) => ({ ...prev, sales_address: false }));
          }}
        />
      </ModalPortal>

      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
          msg={alertModal.msg}
          type={alertModal.type}
        />
      </ModalPortal>
      <Box $mb={4} $isFullWidth>
        <InputHeader title={"가입정보"} />
        <Divider variant="normal" width={"100%"} $bgcolor={colors.MAIN} />
        <Box $isFullWidth $mt={4}>
          <PrimaryInput
            value={joinInfo.id}
            placeholder="아이디를 입력해주세요."
            onChangeValue={(value: string, key?: string) => {
              inputJoinInfoHandler(value, key);
              setInputError((prev) => ({ ...prev, id: false }));
            }}
            label={"아이디"}
            isRequire
            wrapperStyle={{ width: "100%" }}
            stateKey="id"
            readonly={joinInfo.id_check}
            rightButton={
              <Box $isFullWidth height={"100%"}>
                {!socialState && !joinInfo.id_check && (
                  <SolidButton
                    buttonLabel="중복확인"
                    style={{ width: "100%", height: "100%" }}
                    onClick={idDuplicateCheck}
                  />
                )}
              </Box>
            }
            errorMsg="아이디를 입력해주세요."
            $isError={inputError.id}
          />
          {!socialState && (
            <Box $mt={2}>
              <PrimaryInput
                value={joinInfo.password}
                placeholder="비밀번호를 입력해주세요."
                onChangeValue={(value: string, key?: string) => {
                  inputJoinInfoHandler(value, key);
                  setInputError((prev) => ({ ...prev, password: false }));
                }}
                label={"비밀번호"}
                isRequire
                wrapperStyle={{ width: "100%" }}
                rightButton={<Box />}
                rightIcon={isInputShow.password ? <IcEyeOn /> : <IcEyeOff />}
                iconAction={() => {
                  setIsInputShow((prev) => ({
                    ...prev,
                    password: !prev.password,
                  }));
                }}
                inputType={isInputShow.password ? "text" : "password"}
                stateKey="password"
                errorMsg="비밀번호를 입력해주세요."
                $isError={inputError.password}
                capsLockCheck={(capslock) => setIsCapslock(capslock)}
              />
              <Box $mt={1.2}>
                <PrimaryInput
                  value={joinInfo.password_re}
                  placeholder="비밀번호를 한번 더 입력해주세요."
                  onChangeValue={(value: string, key?: string) => {
                    inputJoinInfoHandler(value, key);
                    setInputError((prev) => ({ ...prev, password_re: false }));
                  }}
                  wrapperStyle={{ width: "100%" }}
                  rightButton={<Box />}
                  rightIcon={
                    isInputShow.password_re ? <IcEyeOn /> : <IcEyeOff />
                  }
                  iconAction={() => {
                    setIsInputShow((prev) => ({
                      ...prev,
                      password: !prev.password,
                    }));
                  }}
                  inputType={isInputShow.password_re ? "text" : "password"}
                  stateKey="password_re"
                  errorMsg={"비밀번호가 일치하지 않습니다."}
                  $isError={inputError.password_re}
                  capsLockCheck={(capslock) => setIsCapslock(capslock)}
                />
              </Box>
            </Box>
          )}
          {isCapslock && (
            <Box>
              <Typograpy fontStyle={fontStyle.caption1} color={colors.RED}>
                Capslock이 켜져 있습니다.
              </Typograpy>
            </Box>
          )}
          <Box $mt={2}>
            <PrimaryInput
              value={joinInfo.name}
              placeholder="이름을 입력해주세요"
              onChangeValue={(value: string, key?: string) => {
                inputJoinInfoHandler(value, key);
                setInputError((prev) => ({ ...prev, name: false }));
              }}
              label={"이름"}
              isRequire
              wrapperStyle={{ width: "100%" }}
              rightButton={<Box />}
              stateKey="name"
              errorMsg="이름을 입력해주세요."
              $isError={inputError.name}
            />
          </Box>
          <Box $mt={2}>
            <Label label={"성별"} />
            <FlexBox $flexDirection="row" $gap={0.8}>
              <Radio
                name={"gender"}
                label={"남자"}
                value="M"
                onChange={(e) => {
                  inputJoinInfoHandler(e.target.value, "gender");
                }}
                checked={joinInfo.gender === "M"}
              />
              <Radio
                name={"gender"}
                label={"여자"}
                value="F"
                onChange={(e) => {
                  inputJoinInfoHandler(e.target.value, "gender");
                }}
                checked={joinInfo.gender === "F"}
              />
              <Radio
                name={"gender"}
                label={"선택안함"}
                value="N"
                onChange={(e) => {
                  inputJoinInfoHandler(e.target.value, "gender");
                }}
                checked={joinInfo.gender === "N"}
              />
            </FlexBox>
          </Box>
          <FlexBox $flexDirection="row" $gap={1.2} $mt={2} $ai="flex-end">
            <Box $flex={3}>
              <SelectBox
                selOpt={joinInfo.year}
                optList={renderYearOptions()}
                onChangeValue={(value: string, key?: string) => {
                  inputJoinInfoHandler(value, key);
                  setInputError((prev) => ({
                    ...prev,
                    birth: false,
                  }));
                }}
                placeholder="년도"
                label="생년월일"
                footerLabel="년"
                stateKey="year"
              />
            </Box>
            <Box $flex={2}>
              <SelectBox
                selOpt={joinInfo.month}
                optList={renderMonthOptions()}
                onChangeValue={(value: string, key?: string) => {
                  inputJoinInfoHandler(value, key);
                  setInputError((prev) => ({
                    ...prev,
                    birth: false,
                  }));
                }}
                placeholder="월"
                footerLabel="월"
                stateKey="month"
              />
            </Box>
            <Box $flex={2}>
              <SelectBox
                selOpt={joinInfo.day}
                optList={renderDayOptions()}
                onChangeValue={(value: string, key?: string) => {
                  inputJoinInfoHandler(value, key);
                  setInputError((prev) => ({
                    ...prev,
                    birth: false,
                  }));
                }}
                placeholder="일"
                footerLabel="일"
                stateKey="day"
              />
            </Box>
          </FlexBox>
          {inputError.birth && (
            <Box $mt={0.4}>
              <Typograpy fontStyle={fontStyle.caption1} color={colors.RED}>
                생년월일을 선택해주세요.
              </Typograpy>
            </Box>
          )}
          <FlexBox $mt={2} $isFullWidth>
            <Label label={"주소"} isRequire />
            <FlexBox $isFullWidth $flexDirection="row" $ai="stretch" $gap={1.2}>
              <Box $flex={3}>
                <PrimaryInput
                  readonly
                  value={String(joinInfo.zip === 0 ? "" : joinInfo.zip)}
                  maxInput={5}
                  placeholder="우편번호"
                  onChangeValue={() => {}}
                  wrapperStyle={{ width: "100%" }}
                  $isError={inputError.address}
                />
              </Box>
              <Box $flex={2}>
                <SolidButton
                  buttonLabel="주소찾기"
                  style={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    setPostCodeModal(true);
                  }}
                />
              </Box>
              <Box $flex={2}></Box>
            </FlexBox>
            <Box $mt={1.2} $isFullWidth>
              <PrimaryInput
                readonly
                value={joinInfo.address}
                placeholder="기본 주소"
                onChangeValue={() => {}}
                wrapperStyle={{ width: "100%" }}
                $isError={inputError.address}
                errorMsg="주소를 입력해주세요."
              />
            </Box>
            <Box $mt={1.2} $isFullWidth>
              <PrimaryInput
                value={joinInfo.address_detail}
                placeholder="상세 주소"
                onChangeValue={(value: string, key?: string) => {
                  inputJoinInfoHandler(value, key);
                }}
                wrapperStyle={{ width: "100%" }}
                stateKey="address_detail"
              />
            </Box>
          </FlexBox>
        </Box>

        <InputHeader title={"영업정보"} />
        <Box $isFullWidth $mt={4}>
          <PrimaryInput
            value={salesInfo.group}
            placeholder="소속을 입력해주세요."
            onChangeValue={(value: string, key?: string) => {
              inputSalesInfoHandler(value, key);
              setInputError((prev) => ({ ...prev, sales_group: false }));
            }}
            label={"소속"}
            isRequire
            wrapperStyle={{ width: "100%" }}
            rightButton={<Box />}
            stateKey="group"
            $isError={inputError.sales_group}
            errorMsg={"소속을 입력해주세요."}
          />
          <Box $mt={2}>
            <PrimaryInput
              value={salesInfo.email}
              placeholder="예:bluelions@bluelions.kr"
              onChangeValue={(value: string, key?: string) => {
                inputSalesInfoHandler(value, key);
                setInputError((prev) => ({
                  ...prev,
                  sales_email: !idCheck(value).result,
                }));
              }}
              label={"영업 이메일"}
              isRequire
              wrapperStyle={{ width: "100%" }}
              rightButton={
                <Box $isFullWidth height={"100%"}>
                  {!salesInfo.email_check && (
                    <SolidButton
                      buttonLabel="인증번호 발송"
                      style={{ width: "100%", height: "100%" }}
                      onClick={() => {
                        sendEamilAuthCode();
                      }}
                    />
                  )}
                </Box>
              }
              stateKey="email"
              $isError={inputError.sales_email}
              errorMsg={
                salesInfo.email === ""
                  ? "이메일을 입력해주세요."
                  : "올바르지 않은 이메일 형식입니다."
              }
              readonly={salesInfo.email_check}
            />
            {companyEmailAuthCode !== "" && !salesInfo.email_check && (
              <Box $mt={1.2}>
                <PrimaryInput
                  value={salesInfo.email_code}
                  maxInput={6}
                  placeholder="인증번호 6자리를 입력해주세요"
                  onChangeValue={(value: string, key?: string) => {
                    inputSalesInfoHandler(value, key);
                  }}
                  wrapperStyle={{ width: "100%" }}
                  rightButton={
                    <SolidButton
                      buttonLabel="확인"
                      style={{ width: "100%", height: "100%" }}
                      onClick={() => {
                        checkEamilAuthCode();
                      }}
                    />
                  }
                  rightIcon={<IcEyeOff />}
                  iconAction={() => {}}
                  stateKey={"email_code"}
                  $isError={inputError.sales_email_code}
                  errorMsg={
                    salesInfo.email_code === ""
                      ? "인증번호를 입력해주세요."
                      : "올바르지 않은 인증번호입니다."
                  }
                />
              </Box>
            )}
          </Box>
          <Box $mt={2}>
            <PrimaryInput
              value={salesInfo.tel}
              placeholder="영업 전화번호를 입력해주세요"
              onChangeValue={(value: string, key?: string) => {
                inputSalesInfoHandler(value, key);
                setInputError((prev) => ({ ...prev, sales_tel: false }));
              }}
              label={"영업 전화번호"}
              wrapperStyle={{ width: "100%" }}
              stateKey="tel"
              $isError={inputError.sales_tel}
              errorMsg={"휴대폰번호를 입력해주세요."}
              // rightButton={
              //   <Box $isFullWidth height={"100%"}>
              //     {!salesInfo.tel_check && (
              //       <SolidButton
              //         buttonLabel="인증번호 발송"
              //         style={{ width: "100%", height: "100%" }}
              //         onClick={() => {
              //           verifySmsHandler();
              //         }}
              //       />
              //     )}
              //   </Box>
              // }
            />
          </Box>
          {salesPhoneAuthCode !== "" && !salesInfo.tel_check && (
            <Box $mt={1.2}>
              <PrimaryInput
                value={salesInfo.email_code}
                maxInput={6}
                placeholder="인증번호 6자리를 입력해주세요"
                onChangeValue={(value: string, key?: string) => {
                  inputSalesInfoHandler(value, key);
                }}
                wrapperStyle={{ width: "100%" }}
                rightButton={
                  <SolidButton
                    buttonLabel="확인"
                    style={{ width: "100%", height: "100%" }}
                    onClick={() => {
                      authSmsCode();
                    }}
                  />
                }
                rightIcon={<IcEyeOff />}
                iconAction={() => {}}
                stateKey={"tel_code"}
                $isError={inputError.sales_tel_code}
                errorMsg={
                  salesInfo.tel_code === ""
                    ? "인증번호를 입력해주세요."
                    : "올바르지 않은 인증번호입니다."
                }
              />
            </Box>
          )}
          <FlexBox $mt={2} $isFullWidth>
            <Label label={"회사주소"} isRequire />
            <FlexBox $isFullWidth $flexDirection="row" $ai="stretch" $gap={1.2}>
              <Box $flex={3}>
                <PrimaryInput
                  readonly
                  value={String(salesInfo.zip === 0 ? "" : salesInfo.zip)}
                  placeholder="우편번호"
                  onChangeValue={() => {}}
                  wrapperStyle={{ width: "100%" }}
                  $isError={inputError.sales_address}
                />
              </Box>
              <Box $flex={2}>
                <SolidButton
                  buttonLabel="주소찾기"
                  style={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    setSalesPostCodeModal(true);
                  }}
                />
              </Box>
              <Box $flex={2}></Box>
            </FlexBox>
            <Box $mt={1.2} $isFullWidth>
              <PrimaryInput
                readonly
                value={salesInfo.address}
                placeholder="기본 주소"
                onChangeValue={() => {}}
                wrapperStyle={{ width: "100%" }}
                $isError={inputError.sales_address}
                errorMsg={"주소를 입력해주세요."}
              />
            </Box>
            <Box $mt={1.2} $isFullWidth>
              <PrimaryInput
                value={salesInfo.address_detail}
                placeholder="상세 주소"
                onChangeValue={(value: string, key?: string) => {
                  inputSalesInfoHandler(value, key);
                }}
                wrapperStyle={{ width: "100%" }}
                stateKey="address_detail"
              />
            </Box>
          </FlexBox>

          <FlexBox $mt={2} $isFullWidth>
            <Label label={"주 영업지역"} isRequire />
            <FlexBox $isFullWidth $gap={1.2}>
              {salesInfo.location_list.map((item, index) => {
                return (
                  <FlexBox
                    key={"location_list" + index}
                    $isFullWidth
                    $flexDirection="row"
                    $ai="stretch"
                    $gap={1.2}
                  >
                    <Box $flex={3}>
                      <SelectBox
                        optList={locationList.map((el) => el.regionName)}
                        onChangeValue={(location: string) => {
                          locationHandler(location, index);
                        }}
                        placeholder="주 영업지역 선택"
                        selOpt={item.location_name}
                        // label="주 영업지역"
                      />
                    </Box>
                    <Box $flex={2}>
                      {salesInfo.location_list.length - 1 === index && (
                        <SolidButton
                          buttonLabel="+ 지역추가"
                          style={{ width: "100%", height: "100%" }}
                          onClick={addLocation}
                        />
                      )}
                    </Box>
                    <Box $flex={2}></Box>
                  </FlexBox>
                );
              })}
            </FlexBox>
            {inputError.sales_location && (
              <Box $mt={0.4}>
                <Typograpy fontStyle={fontStyle.caption1} color={colors.RED}>
                  최소 1개이상의 영업지역을 선택해주세요.
                </Typograpy>
              </Box>
            )}
          </FlexBox>
          <Box $my={2} $isFullWidth>
            <PrimaryInput
              value={salesInfo.sales_item}
              placeholder="영업품목을 입력해주세요"
              label={"주 영업품목"}
              onChangeValue={(value: string, key?: string) => {
                inputSalesInfoHandler(value, key);
              }}
              wrapperStyle={{ width: "100%" }}
              rightButton={<Box />}
              stateKey="sales_item"
            />
          </Box>
          <Divider
            variant="thick"
            $bgcolor={colors.MAIN}
            height={0.2}
            width={"100%"}
          />
          <Box $mt={2}>
            <Label label={"이용약관동의"} isRequire />
          </Box>
          <FlexBox $isFullWidth $gap={1.6} $mt={2}>
            <FlexBox $isFullWidth $flexDirection="row" $jc={"space-between"}>
              <SolidCheckbox
                name="agree"
                value="all"
                label="모두동의 (선택 정보 포함)"
                fontStyle={fontStyle.headline1}
                fontWeight="Bold"
                fontColor={colors.MAIN}
                onChange={allCheckAgree}
                $checked={agreeList.length === joinAgreeList.length}
              />
            </FlexBox>
            {joinAgreeList.map((item, index) => {
              return (
                <FlexBox
                  key={"agree" + index}
                  $isFullWidth
                  $flexDirection="row"
                  $jc={"space-between"}
                  $ai={"center"}
                >
                  <SolidCheckbox
                    name="agree"
                    value={item.type}
                    label={`${item.label} (${item.require ? "필수" : "선택"})`}
                    fontStyle={fontStyle.headline1}
                    onChange={(e) => {
                      checkAgree(e.target.value);
                    }}
                    $checked={agreeList.includes(item.type)}
                  />
                  {item.type !== "age" && (
                    <Box
                      $isPointer
                      onClick={() => {
                        if (
                          item.type === "service" ||
                          item.type === "privacy" ||
                          item.type === "refund" ||
                          item.type === "marketing"
                        ) {
                          setAgreeModal({ visible: true, type: item.type });
                        }
                      }}
                    >
                      <Typograpy
                        fontStyle={fontStyle.headline1}
                        color={colors.DEFAULT_FONT_COLOR}
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        보기
                      </Typograpy>
                    </Box>
                  )}
                </FlexBox>
              );
            })}
          </FlexBox>

          <Box $isFullWidth $mt={4}>
            <Divider
              variant="thick"
              width={"100%"}
              height={0.2}
              $bgcolor={colors.MAIN}
            />
          </Box>
          <Box $mt={2}>
            <SolidButton
              size={"large"}
              buttonLabel="회원가입"
              style={{
                width: "100%",
                paddingTop: "2rem",
                paddingBottom: "2rem",
              }}
              onClick={() => {
                // navigate("/join/payment");
                validateInput();
              }}
            />
          </Box>
        </Box>
      </Box>
    </FlexBox>
  );
};
