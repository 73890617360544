import {
  SecondaryBtnWrapper,
  SecondaryWrapper,
} from "components/atoms/tab/tabStyles";
import React from "react";
import Typograpy from "../Typograpy";
import { colors } from "theme/colors";

interface SecondaryTabProps {
  items: string[];
  activeTab: number;
  onTabChange: (index: number) => void;
  fontStyle?: string;
}

export const SecondaryTab: React.FC<SecondaryTabProps> = ({
  items,
  activeTab,
  onTabChange,
  fontStyle,
}) => {
  return (
    <SecondaryWrapper>
      {items.map((item, index) => (
        <SecondaryBtnWrapper
          key={index}
          $isSel={index === activeTab}
          onClick={() => onTabChange(index)}
          style={{
            borderRight:
              items.length - 1 === index
                ? "none"
                : `1px solid ${colors.SEC_TAB_BORDER}`,
          }}
        >
          <Typograpy
            fontStyle={fontStyle}
            fontSize={14}
            color={
              index === activeTab
                ? colors.SEC_TAB_ON_FONT
                : colors.SEC_TAB_OFF_FONT
            }
            style={{ whiteSpace: "nowrap" }}
          >
            {item}
          </Typograpy>
        </SecondaryBtnWrapper>
      ))}
    </SecondaryWrapper>
  );
};
