import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { PageType } from "api/commonType";
import axios, { AxiosError, AxiosResponse } from "axios";
import { base64Encode } from "utils/func";

export interface GetUsersParams {
  userIds?: string;
  regionId?: string | number; //지역
  type1?: string; // 필터링 (보험,금융,보험+금융)
  type2?: string; //필터링 (이름,이메일,직장명)
  searchKeyword?: string; //필터링 (type2에 따라 검색어)
  totalIncomeOperatorType?: string; //GTE(이상) GT(초과) EQUAL(같음) LTE(이하) LT(미만)
  totalIncome?: number | ""; //소득값
  ageOperatorType?: string; //GTE(이상) GT(초과) EQUAL(같음) LTE(이하) LT(미만)
  age?: number | ""; //나이검색
  page?: number; //페이지 번호
  size?: number; //페이지당 항목갯수
}

export interface GetUsersResponseData {
  id: number; //유저 id
  email: string; //이메일
  name: string; //이름
  address: string; //주소
  year: number; //데이터 연도
  businessName: string; //회사이름
  age: number; //나이
  totalIncome: number; //총소득
  phoneNumber: string;
  isInsuranceInfo: boolean; //보험 데이터가 있는지 여부
  isFinancialInfo: boolean; // 금융 데이터 있는지 여부
  createdDate: number;
}

export interface GetUsersResponse {
  status: number; //응답
  message: string; //메세지
  data: {
    success: boolean;
    response: GetUsersResponseData[];
    error: null | string;
    page: PageType;
  };
}

// 영업자 - 고객리스트 조회
export const useGetUsersList = (params: GetUsersParams) =>
  useQuery<GetUsersResponse, AxiosError>({
    queryKey: ["getUsers"],
    queryFn: () => {
      if (params.regionId === "" || params.regionId || params.userIds) {
        return baseAxios
          .get(
            `/blue-button-api/fetch/users?filter=${JSON.stringify(params)}`,
            {}
          )
          .then((res) => {
            return res.data;
          })
          .catch();
      }
    },
  } as UseQueryOptions<GetUsersResponse, AxiosError>);
