import React from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Legend } from "recharts";
import { customLegend } from "./CustomLegend";

interface PieChartPropsType {
  dataList: { name: string; value: number; fill: string }[];
}

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g width={"100%"} height={"100%"}>
      <text
        x={cx}
        y={cy}
        dy={0}
        textAnchor="middle"
        fill={fill}
        fontSize={18}
        fontFamily="Regular"
      >
        {payload.name}
      </text>
      <text
        x={cx}
        y={cy}
        dy={25}
        textAnchor="middle"
        fill={fill}
        fontSize={16}
        fontFamily="Bold"
      >
        {(percent * 100).toFixed(2)}%
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

export const PieChartComponent: React.FC<PieChartPropsType> = React.memo(
  ({ dataList }) => {
    const [activeIndex, setActiveIndex] = React.useState(0);

    return (
      <ResponsiveContainer>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={dataList}
            cx="50%"
            cy="50%"
            innerRadius={100}
            outerRadius={120}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={(_, index) => {
              setActiveIndex(index);
            }}
          />
          <Legend content={customLegend} />
        </PieChart>
      </ResponsiveContainer>
    );
  }
);
