import { useRefreshToken } from "api/auth/RefreshToken";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { SolidButton } from "components/molecules/button/SolidButton";
import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import { PaymentResultTable } from "components/organisms/saller/payment/PaymentResultTable";
import React from "react";
import { useNavigate } from "react-router";
import { useLoadingStore } from "store/useLoadingStore";
import { useSubProductPaymentStore } from "store/useSubProductPaymentStore";
import { colors } from "theme/colors";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";

export const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { mutate: refreshMutate, isPending: refreshPending } =
    useRefreshToken();
  const { updateLoading } = useLoadingStore((state) => state);
  const { paymentSubProductList, clearPaymentSubProductList } =
    useSubProductPaymentStore((state) => state);

  React.useEffect(() => {
    refreshMutate();

    return () => {
      clearPaymentSubProductList();
    };
  }, []);

  React.useEffect(() => {
    updateLoading(refreshPending);
  }, [refreshPending]);

  return (
    <FlexBox $isFullWidth $ai={"center"} $minHeight={70}>
      <FlexBox
        $isFullWidth
        $py={4}
        $ai="center"
        $jc="center"
        $bgcolor={colors.USER_HEADER_BG}
      >
        <Typograpy
          fontSize={45}
          fontWeight="Bold"
          color={colors.WHITE}
          style={{ lineHeight: "4.8rem" }}
        >
          결제성공
        </Typograpy>
      </FlexBox>
      <FlexBox $mt={3} $isFullWidth $ai={"center"}>
        <Typograpy fontSize={24} fontWeight="Bold">
          결제가{" "}
          <Typograpy fontSize={24} fontWeight="Bold" color={colors.MAIN}>
            완료
          </Typograpy>
          되었습니다.
        </Typograpy>

        <Box $mt={1.6}>
          <Typograpy fontSize={16} color={colors.NONE_LOGIN_GRAY}>
            아래는 고객님께서 결제한 내역입니다.
          </Typograpy>
        </Box>
        <Box $mt={1.2} $isFullWidth $maxWidth={MainMaxWidth}>
          {/* <Divider variant="normal" width={"100%"} /> */}
          <PaymentResultTable newPaymentInfo={paymentSubProductList} />

          <FlexBox
            $flexDirection="row"
            $jc={"center"}
            $isFullWidth
            $gap={1.2}
            $mt={3.2}
          >
            <SolidButton
              buttonLabel="내정보"
              size={"large"}
              style={{ border: `1px solid ${colors.MAIN}` }}
              onClick={() => {
                navigate("/saller/myInfo", { replace: true });
              }}
            />
            <OutlineButton
              size={"large"}
              buttonLabel="홈으로"
              onClick={() => {
                navigate("/", { replace: true });
              }}
            />
          </FlexBox>
        </Box>
      </FlexBox>
    </FlexBox>
  );
};
