import Typograpy from "components/molecules/Typograpy";
import AgreeTemplate from "components/templates/user/AgreeTemplate/Index";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";

import { Box, FlexBox, MainMaxWidth, UserMaxWidth } from "theme/globalStyle";
import { agreeMenuList } from "utils/list";

const AgreePage = () => {
  const location = useLocation();
  const currentPath = location.pathname.replace(/^\/agree\/?/, "");

  const navigate = useNavigate();

  React.useEffect(() => {
    if (currentPath === "" || !currentPath) {
      navigate("/agree/privacy");
    }
  }, []);

  return (
    <FlexBox $isFullWidth $ai="center" $minHeight={70}>
      <FlexBox
        $isFullWidth
        $py={4}
        $ai="center"
        $jc="center"
        $bgcolor={colors.USER_HEADER_BG}
      >
        <Typograpy
          fontSize={45}
          fontWeight="Bold"
          color={colors.WHITE}
          style={{ lineHeight: "4.8rem" }}
        >
          이용약관
        </Typograpy>
      </FlexBox>
      <FlexBox
        $flexDirection="row"
        $ai={"stretch"}
        $isFullWidth
        $maxWidth={MainMaxWidth}
      >
        <FlexBox
          $px={2}
          $pt={2}
          $brw={1}
          $minHeight={"100vh"}
          $minWidth={23}
          $bc={colors.DEFAULT_BORDER_COLOR}
        >
          <Typograpy
            fontWeight="Bold"
            fontStyle={fontStyle.label1_normal}
            color={colors.SALLER_SUBSIDE_TITLE}
          >
            이용약관
          </Typograpy>
          <FlexBox $isFullWidth $mt={0.8}>
            {agreeMenuList.map((menuItem, index) => {
              return (
                <Box
                  key={"agreeMenu" + index}
                  $py={0.6}
                  $px={1.6}
                  $bw={1}
                  $bc={
                    currentPath === menuItem.path ? colors.MAIN : colors.WHITE
                  }
                  $br={5}
                  $isFullWidth
                  $isPointer
                  onClick={() => {
                    navigate(menuItem.path);
                  }}
                >
                  <Typograpy
                    fontStyle={fontStyle.label1_normal}
                    color={
                      currentPath === menuItem.path
                        ? colors.MAIN
                        : colors.SALLER_SUBSIDE_TITLE
                    }
                    hoverColor={colors.MAIN}
                  >
                    {menuItem.label}
                  </Typograpy>
                </Box>
              );
            })}
          </FlexBox>
        </FlexBox>

        <AgreeTemplate />
      </FlexBox>
    </FlexBox>
  );
};

export default AgreePage;
