import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { FlexBox } from "theme/globalStyle";
import { NumberComma } from "utils/func";

interface FinanceCardListPropsType {
  data: { companyName: string; amount: number };
}

export const FinanceCardList: React.FC<FinanceCardListPropsType> = ({
  data,
}) => {
  return (
    <FlexBox
      $flexDirection="row"
      $ai="center"
      $isFullWidth
      $bw={1}
      $bc={colors.DEFAULT_BORDER_COLOR}
      $br={10}
      $py={1}
      $px={2}
    >
      <FlexBox $flex={3}>
        <Typograpy
          color={colors.INSURANCE_TITLE}
          fontStyle={fontStyle.caption1}
          fontWeight="SemiBold"
        >
          카드회사
        </Typograpy>
        <Typograpy
          color={colors.INSURANCE_CONTENT}
          fontStyle={fontStyle.label1_normal}
        >
          {data.companyName
            .replaceAll("）", ")")
            .replaceAll("（", "(")
            .replaceAll(" ", "")}
        </Typograpy>
      </FlexBox>
      {/* <Divider height={"100%"} variant="normal" isVertical /> */}
      <Divider height={"100%"} variant="normal" isVertical />
      <FlexBox $pl={1.6} $minWidth={12}>
        <Typograpy
          color={colors.INSURANCE_TITLE}
          fontStyle={fontStyle.caption1}
          fontWeight="SemiBold"
        >
          작년 총 사용금액
        </Typograpy>
        <Typograpy
          color={colors.INSURANCE_CONTENT}
          fontStyle={fontStyle.label1_normal}
        >
          {NumberComma(data.amount)}원
        </Typograpy>
      </FlexBox>
    </FlexBox>
  );
};
