import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { PageType } from "api/commonType";
import axios, { AxiosError, AxiosResponse } from "axios";
import { base64Encode } from "utils/func";

interface GetCountByRegionParams {
  startDate?: string;
  endDate?: string;
}

export interface GetCountByRegionDataType {
  regionName: string;
  regionId: number;
  count: number;
}

export interface CountByRegionDataProccessType {
  locale: string;
  count: number;
}

interface GetCountByRegionResponse {
  success: boolean;
  response: GetCountByRegionDataType[];
}

//영업자 - 지역별 고객 가입수
export const useGetCountByRegion = (params: GetCountByRegionParams) =>
  useQuery<GetCountByRegionResponse, AxiosError>({
    queryKey: ["getCountByRegion"],
    queryFn: () => {
      return axios
        .get(
          `${process.env.REACT_APP_BLUETAX_URI}/api/v1/statistics/users/signup/count-by-region
`,
          {
            params,
            headers: {
              clientID: process.env.REACT_APP_BLUTBUTTON_CLIENTID,
              clientSecrete: process.env.REACT_APP_BLUTBUTTON_SECRET,
              Authorization: `Basic ${base64Encode(
                process.env.REACT_APP_BLUTBUTTON_CLIENTID || "",
                process.env.REACT_APP_BLUTBUTTON_SECRET || ""
              )}`,
            },
          }
        )
        .then((res) => res.data);
    },
  } as UseQueryOptions<GetCountByRegionResponse, AxiosError>);
