import { SolidButton } from "components/molecules/button/SolidButton";
import { TableContent } from "components/molecules/table/TableContent";
import { TableTitle } from "components/molecules/table/TableTitle";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import UploadIcon from "assets/img/ic_upload.svg";
import { InsuranceListEl } from "components/organisms/saller/customerDetail/InsuranceListEl";
import { CusMemberInfo } from "components/organisms/saller/customerDetail/CusMemberInfo";
import { GetUsersResponseData } from "api/blueButton/GetUsers";
import {
  GetInsurancesResponseType,
  useGetInsurancesInfo,
} from "api/blueButton/getInsurancesInfo";
import { FinanceCardList } from "./finance/FinanceCardList";
import { HousingFundCardList } from "./finance/HousingFundCardList";
import { LongTermCollectiveList } from "./finance/LongTermCollectiveList";
import { IconOutlineLoading } from "components/atoms/icon/animated";
import { useAdminInfoStore } from "store/useAdminInfoStore";
import { useGetFinancesInfo } from "api/blueButton/getFinancesInfo";
import useScrollLock from "hooks/useScrollLock";
import { maskAllButFirst } from "utils/func";

interface CusDetailInfoProps {
  detailUserInfo: GetUsersResponseData; //고객 상세정보
  productType: "insurances" | "finance"; //선택한 상품 타입 (보험, 금융)
  setSelTab: (type: "detail" | "proposal") => void; //보험제안하기 탭이동
  proposal_id?: number; //고객제안정보인지 확인
  isAccecptDetail?: boolean;
}

interface FinanceSectionType<T> {
  title: string;
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  keyPrefix: string;
}

const FinanaceSection = <T,>({
  title,
  items,
  renderItem,
  keyPrefix,
}: FinanceSectionType<T>) => {
  if (items) {
    if (items.length > 0) {
      return (
        <FlexBox $gap={0.8} $isFullWidth $mt={1.2}>
          <Typograpy fontStyle={fontStyle.label1_normal} fontWeight="Bold">
            {title}
          </Typograpy>
          {items.map((item, index) => renderItem(item, index))}
        </FlexBox>
      );
    } else {
      return (
        <Typograpy fontStyle={fontStyle.label1_normal}>
          보유한{" "}
          <Typograpy fontStyle={fontStyle.label1_normal} fontWeight="Bold">
            {title}정보
          </Typograpy>
          가 존재하지 않습니다.
        </Typograpy>
      );
    }
  } else {
    return null;
  }
};

//고객 기본상세정보
export const CusDetailInfo: React.FC<CusDetailInfoProps> = ({
  detailUserInfo,
  productType,
  setSelTab,
  proposal_id,
  isAccecptDetail,
}) => {
  const { admin_id } = useAdminInfoStore((state) => state);

  const {
    data: insurancesData,
    isLoading: insurancesLoading,
    refetch: insurancesRefetch,
  } = useGetInsurancesInfo({
    id: detailUserInfo.id,
  });
  const {
    data: financeInfo,
    isLoading: financeLoading,
    refetch: financesRefetch,
  } = useGetFinancesInfo({
    id: detailUserInfo.id,
  });

  // React.useEffect(() => {
  //   if (productType === "insurances") {
  //     insurancesRefetch();
  //   } else {
  //     financesRefetch();
  //   }
  // }, [detailUserInfo.id]);

  React.useEffect(() => {
    if (!insurancesData && detailUserInfo.id && productType === "insurances") {
      insurancesRefetch();
    }
  }, [detailUserInfo.id, insurancesData]);

  React.useEffect(() => {
    if (!insurancesData && detailUserInfo.id && productType === "finance") {
      financesRefetch();
    }
  }, [detailUserInfo.id, insurancesData]);

  useScrollLock(true);
  return (
    <Box $isFullWidth>
      <FlexBox
        $btw={1}
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $btc={colors.DEFAULT_BORDER_COLOR}
        $isFullWidth
      >
        <CusMemberInfo
          isAccecptDetail={isAccecptDetail}
          detailUserInfo={detailUserInfo}
        />

        {insurancesLoading || financeLoading ? (
          <FlexBox
            $flex={1}
            $isFullWidth
            height={"100%"}
            $jc={"center"}
            $ai={"center"}
          >
            <IconOutlineLoading width={5} height={5} />
          </FlexBox>
        ) : (
          <FlexBox $isFullWidth>
            {insurancesData && (admin_id || productType === "insurances") && (
              <FlexBox
                $flexDirection="row"
                $ai="stretch"
                $isFullWidth
                $bbw={financeInfo ? 1 : 0}
                $bbc={colors.DEFAULT_BORDER_COLOR}
              >
                <TableTitle title={"보유보험"} />
                <TableContent isColumnCenter isColumn>
                  {!proposal_id && !admin_id && (
                    <FlexBox
                      $isFullWidth
                      $ai="center"
                      $px={2}
                      $flexDirection="row"
                      $jc="space-between"
                    >
                      <Box>
                        <Typograpy
                          fontStyle={fontStyle.label1_normal}
                          color={colors.MAIN}
                          fontWeight="Bold"
                        >
                          [{maskAllButFirst(detailUserInfo.name)}]
                        </Typograpy>
                        <Typograpy
                          fontStyle={fontStyle.label1_normal}
                          color={"#585858"}
                        >
                          고객님께 100% 적합한{" "}
                        </Typograpy>
                        <Typograpy
                          fontStyle={fontStyle.label1_normal}
                          fontWeight="Bold"
                          color={colors.MAIN}
                        >
                          맞춤형 상품
                        </Typograpy>
                        <Typograpy
                          fontStyle={fontStyle.label1_normal}
                          color={"#585858"}
                        >
                          을 직접 만들어주세요
                        </Typograpy>
                      </Box>

                      <SolidButton
                        buttonLabel="새로운 추천 상품 제안하기"
                        size={"large"}
                        leftIcon={UploadIcon}
                        $br={100}
                        onClick={() => {
                          setSelTab("proposal");
                        }}
                      />
                    </FlexBox>
                  )}
                  <FlexBox
                    $gap={0.8}
                    $isFullWidth
                    $mt={proposal_id || admin_id ? 0 : 2}
                  >
                    {insurancesData &&
                      (
                        insurancesData.data
                          .response as GetInsurancesResponseType[]
                      ).length > 0 &&
                      (
                        insurancesData.data
                          .response as GetInsurancesResponseType[]
                      ).map((item, index) => {
                        return (
                          <InsuranceListEl
                            data={item as GetInsurancesResponseType}
                            key={"insurance" + index}
                          />
                        );
                      })}
                  </FlexBox>
                </TableContent>
              </FlexBox>
            )}
            {financeInfo && (admin_id || productType === "finance") && (
              <FlexBox $flexDirection="row" $ai="stretch" $isFullWidth>
                <TableTitle title={"보유금융"} />
                <TableContent isColumnCenter isColumn>
                  {!proposal_id && !admin_id && (
                    <FlexBox
                      $isFullWidth
                      $ai="center"
                      $px={2}
                      $flexDirection="row"
                      $jc="space-between"
                    >
                      <Box>
                        <Typograpy
                          fontStyle={fontStyle.label1_normal}
                          color={colors.MAIN}
                          fontWeight="Bold"
                        >
                          [{maskAllButFirst(detailUserInfo.name)}]
                        </Typograpy>
                        <Typograpy
                          fontStyle={fontStyle.label1_normal}
                          color={"#585858"}
                        >
                          고객님께 100% 적합한{" "}
                        </Typograpy>
                        <Typograpy
                          fontStyle={fontStyle.label1_normal}
                          fontWeight="Bold"
                          color={colors.MAIN}
                        >
                          맞춤형 상품
                        </Typograpy>
                        <Typograpy
                          fontStyle={fontStyle.label1_normal}
                          color={"#585858"}
                        >
                          을 직접 만들어주세요
                        </Typograpy>
                      </Box>

                      <SolidButton
                        buttonLabel="새로운 추천 상품 제안하기"
                        size={"large"}
                        leftIcon={UploadIcon}
                        $br={100}
                        onClick={() => {
                          setSelTab("proposal");
                        }}
                      />
                    </FlexBox>
                  )}
                  <FlexBox
                    $gap={1.2}
                    $isFullWidth
                    $mt={
                      financeInfo && (admin_id || productType === "finance")
                        ? 0
                        : 2
                    }
                  >
                    <FinanaceSection
                      title={"개인소유카드"}
                      items={financeInfo.data.response.creditCards}
                      keyPrefix="creditCard"
                      renderItem={(item, index) => (
                        <FinanceCardList
                          data={item}
                          key={"creditCard" + index}
                        />
                      )}
                    />
                    <FinanaceSection
                      title={"직불카드"}
                      items={financeInfo.data.response.debitCards}
                      keyPrefix="creditCard"
                      renderItem={(item, index) => (
                        <FinanceCardList
                          data={item}
                          key={"creditCard" + index}
                        />
                      )}
                    />
                    <FinanaceSection
                      title={"주택자금대출"}
                      items={financeInfo.data.response.housingFundLoans}
                      keyPrefix="HousingCard"
                      renderItem={(item, index) => (
                        <HousingFundCardList
                          data={item}
                          key={"HousingCard" + index}
                        />
                      )}
                    />

                    <FinanaceSection
                      title={"증권"}
                      items={
                        financeInfo.data.response.longTermCollectiveInvestments
                      }
                      keyPrefix="LongTermCollective"
                      renderItem={(item, index) => (
                        <LongTermCollectiveList
                          data={item}
                          key={"LongTermCollective" + index}
                        />
                      )}
                    />
                  </FlexBox>
                </TableContent>
              </FlexBox>
            )}
          </FlexBox>
        )}
      </FlexBox>
    </Box>
  );
};
