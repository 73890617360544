import React from "react";
import Modal from "react-modal";
import { customModalStyles } from "./customModalStyle";
import { CommonModalType } from "./commonModalType";
import { ServiceAgree } from "components/templates/user/AgreeTemplate/ServiceAgree";
import { PrivacyAgree } from "components/templates/user/AgreeTemplate/PrivacyAgree";
import { MarketingAgree } from "components/templates/user/AgreeTemplate/MarketingAgree";
import Typograpy from "components/molecules/Typograpy";
import { fontStyle } from "theme/fontStyle";
import { Divider } from "components/molecules/divider/Divider";
import { Box, FlexBox } from "theme/globalStyle";
import { SolidButton } from "components/molecules/button/SolidButton";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { colors } from "theme/colors";
import { RefundAgree } from "components/templates/user/AgreeTemplate/RefundAgree";
import useScrollLock from "hooks/useScrollLock";
import { CommonModal } from "./CommonModal";

interface AgreeModalProps extends CommonModalType {
  type: "privacy" | "service" | "refund" | "marketing";
  agreeAction: (type: string) => void;
}

export const AgreeModal: React.FC<AgreeModalProps> = ({
  visible,
  modalOff,
  type,
  agreeAction,
}) => {
  const agreeList = {
    service: {
      title: "서비스 이용약관 동의",
      component: <ServiceAgree />,
    },
    privacy: {
      title: "개인정보 수집 및 이용 동의",
      component: <PrivacyAgree />,
    },
    refund: {
      title: "취소 및 환불, 청약철회 규정 동의",
      component: <RefundAgree />,
    },
    marketing: {
      title: "마케팅 목적의 개인정보 수집 및 이용 동의",
      component: <MarketingAgree />,
    },
  };

  //modal 활성화시 body 스크롤 lock
  useScrollLock(visible);

  return (
    <CommonModal
      modalOff={modalOff}
      visible={visible}
      customStyle={{
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.4)",
          zIndex: 999,
        },
        content: {
          width: "70%",
          height: "70%",
          position: "relative",
          padding: 0,
          paddingTop: "2rem",
          paddingBottom: "2rem",
          inset: 0,
        },
      }}
    >
      <Box $pb={2} $px={2}>
        <Typograpy fontStyle={fontStyle.headline1} fontWeight="Bold">
          {agreeList[type].title}
        </Typograpy>
      </Box>
      <Divider variant="normal" width={"100%"} />

      <Box $pa={2} $isFullWidth height={"80%"}>
        <Box
          $isFullWidth
          $bgcolor="#F0F0F0"
          $maxHeight={"100%"}
          style={{ overflowY: "scroll" }}
        >
          {agreeList[type].component}
        </Box>
      </Box>

      <Divider variant="normal" width={"100%"} />

      <FlexBox
        $flexDirection="row"
        $jc={"center"}
        $ai={"center"}
        $gap={1.2}
        $mt={2}
      >
        <OutlineButton
          buttonLabel="닫기"
          style={{ paddingLeft: "4rem", paddingRight: "4rem" }}
          onClick={modalOff}
        />
        <OutlineButton
          bgcolor={colors.MAIN}
          fontStyle="color:#fff"
          buttonLabel="동의"
          style={{ paddingLeft: "4rem", paddingRight: "4rem" }}
          onClick={() => {
            agreeAction(type);
            modalOff();
          }}
        />
      </FlexBox>
    </CommonModal>
  );
};
