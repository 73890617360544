import Typograpy from "components/molecules/Typograpy";
import { Divider } from "components/molecules/divider/Divider";
import React from "react";
import { useNavigate } from "react-router";
import { useTokenStore } from "store/useTokenStore";
import { useUserInfoStore } from "store/userInfoStore";
// import { useUserInfoStore } from "store/userInfoStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import ModalPortal from "utils/Portal";
import { AlertModal } from "../modal/AlertModal";
import { useSnackbarStore } from "store/snackbarStore";
import { useAdminInfoStore } from "store/useAdminInfoStore";

interface HeaderPropsType {}

export const Header: React.FC<HeaderPropsType> = React.memo(() => {
  // const { id, token, name, deleteUserInfo } = useUserInfoStore(
  //   (state) => state
  // );

  const navigate = useNavigate();

  //snakbar store
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);

  //토큰 관리
  const { token, setToken } = useTokenStore((state) => state);

  //영업자 계정정보
  const { salesmanager_id, salesmanager_name, deleteUserInfo, type, payment } =
    useUserInfoStore((state) => state);

  //admin 계정 정보
  const {
    admin_id,
    admin_name,
    deleteAdminInfo,
    type: adminType,
  } = useAdminInfoStore((state) => state);

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  function alertAction() {
    if (alertModal.type === "logout_confirm") {
      logoutHandler();
    } else if (alertModal.type === "none_subproduct") {
      navigate("/payment");
    }
  }

  function logoutHandler() {
    setToken("");
    deleteUserInfo();
    deleteAdminInfo();
    showSnackbar("로그아웃이 완료되었습니다.");
    navigate("/", { replace: true });
  }

  return (
    <>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          msg={alertModal.msg}
          type={alertModal.type}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
          action={alertAction}
        />
      </ModalPortal>
      <FlexBox $flexDirection="row" $jc="space-between" $ai="center" $py={1.2}>
        <Box
          $isPointer
          onClick={() => {
            navigate("/");
          }}
        >
          <Typograpy fontStyle={fontStyle.title3} fontWeight="ExtraBold">
            Blue
            <Typograpy fontStyle={fontStyle.title3} fontWeight="Regular">
              sales
            </Typograpy>
          </Typograpy>
        </Box>
        <FlexBox $flexDirection="row" $ai="center" $gap={0.8}>
          {token !== "" && (admin_id !== 0 || salesmanager_id !== 0) ? (
            <Box $isPointer>
              <Typograpy fontStyle={fontStyle.caption1} fontWeight="SemiBold">
                {salesmanager_id === 0 ? admin_name : salesmanager_name}
              </Typograpy>
              <Typograpy fontStyle={fontStyle.caption1}>
                님 환영합니다.
              </Typograpy>
            </Box>
          ) : (
            <Box
              $isPointer
              onClick={() => {
                navigate("/login");
              }}
            >
              <Typograpy fontStyle={fontStyle.caption1} fontWeight="SemiBold">
                로그인
              </Typograpy>
            </Box>
          )}
          {/* <Divider variant="normal" isVertical height={1.6} />

          {token === "" && (
            <Box
              $isPointer
              onClick={() => {
                navigate("/adminLogin");
              }}
            >
              <Typograpy fontStyle={fontStyle.caption1} fontWeight="SemiBold">
                관리자로그인
              </Typograpy>
            </Box>
          )} */}
          {token === "" && <Divider variant="normal" isVertical height={1.6} />}

          {token === "" && salesmanager_id === 0 ? (
            <Box
              $isPointer
              onClick={() => {
                navigate("/join/writeUserInfo");
              }}
            >
              <Typograpy
                fontStyle={fontStyle.caption1}
                fontWeight="Medium"
                color={colors.NONE_LOGIN_GRAY}
              >
                회원가입
              </Typograpy>
            </Box>
          ) : (
            <Box
              $isPointer
              onClick={() => {
                setAlertModal({
                  visible: true,
                  msg: "로그아웃 하시겠습니까?",
                  type: "logout_confirm",
                });
              }}
            >
              <Typograpy
                fontStyle={fontStyle.caption1}
                fontWeight="Medium"
                color={colors.NONE_LOGIN_GRAY}
              >
                로그아웃
              </Typograpy>
            </Box>
          )}
          {/* <Divider variant="normal" isVertical height={1.6} />
          <Box $isPointer>
            <Typograpy
              fontStyle={fontStyle.caption1}
              fontWeight="Medium"
              color={colors.NONE_LOGIN_GRAY}
            >
              고객센터
            </Typograpy>
          </Box> */}
          {token !== "" && <Divider variant="normal" isVertical height={1.6} />}
          {token !== "" && (
            <Box
              $isPointer
              onClick={() => {
                if (adminType === "ADMIN") {
                  navigate("/admin");
                } else if (type === "SALESMANAGER") {
                  if (payment.length === 0) {
                    navigate("/saller/myInfo");
                    // setAlertModal({
                    //   visible: true,
                    //   msg: "결제한 구독상품이 존재하지 않습니다. 구독 후 이용해주세요.",
                    //   type: "none_subproduct",
                    // });
                  } else {
                    navigate("/saller");
                  }
                }
              }}
            >
              <Typograpy
                fontStyle={fontStyle.caption1}
                fontWeight="Medium"
                color={colors.NONE_LOGIN_GRAY}
              >
                고객관리
              </Typograpy>
            </Box>
          )}
        </FlexBox>
      </FlexBox>
    </>
  );
});
