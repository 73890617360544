import styled from "styled-components";
import { colors } from "theme/colors";

interface StylePaginationPropsType {
  $isNowPage?: boolean;
}

const StyleNumberPagination = styled.button<StylePaginationPropsType>`
  margin: 0 0.5rem;
  /* padding: 1rem 1.6rem;
   */
  width: 3.8rem;
  height: 3.8rem;
  /* backgroundColor: $isNowPage
          ? colors.PAGINATION_BG_ON
          : colors.PAGINATION_BG_OFF, */
  border: none;
  background-color: ${({ $isNowPage }) =>
    $isNowPage ? colors.PAGINATION_NOW_BG : colors.PAGINATION_BG};
  border-radius: 4px;
  cursor: pointer;
`;

const StyleDotPagination = styled.div<StylePaginationPropsType>`
  cursor: pointer;
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: ${({ $isNowPage }) =>
    $isNowPage
      ? `1px solid ${colors.PAGINATION_NOW_DOT}`
      : `1px solid ${colors.PAGINATION_DOT}`};
  border-radius: 100px;
  background-color: ${({ $isNowPage }) =>
    $isNowPage ? colors.PAGINATION_NOW_DOT : "#fff"};
`;

export { StyleNumberPagination, StyleDotPagination };
