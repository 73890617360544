import React from "react";
import styled from "styled-components";
import { colors } from "theme/colors";

export interface RadioProps {
  size?: "normal" | "small";
  checked?: boolean;
  disabled?: boolean;
  interaction?: "normal" | "hovered" | "focused" | "pressed";
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string;
  label?: string;
}

const RadioWrapper = styled.label<RadioProps>`
  display: inline-flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const RadioButton = styled.input.attrs({ type: "radio" })<RadioProps>`
  appearance: none;
  width: ${(props) => (props.size === "small" ? "1.6rem" : "2rem")};
  height: ${(props) => (props.size === "small" ? "1.6rem" : "2rem")};
  border: 1px solid ${colors.MAIN};
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  background-color: white;
  transition: all 0.2s ease-in-out;
  margin-top: 0;
  &:checked {
    background-color: ${colors.MAIN};
    border-color: ${colors.MAIN};
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) => (props.size === "small" ? "0.6rem" : "0.8rem")};
    height: ${(props) => (props.size === "small" ? "0.6rem" : "0.8rem")};
    background-color: white;
    border-radius: 50%;
  }

  &:hover {
    box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.1);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.2);
  }

  &:active {
    box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.3);
  }
`;

const RadioLabel = styled.span`
  font-size: 14px;
`;

export { RadioWrapper, RadioButton, RadioLabel };
